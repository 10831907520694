import React from 'react';

interface CustomLoaderProps {
    size: number;
    colors: string;
    styling?: any;
}

const CustomLoader: React.FC<CustomLoaderProps> = ({ size, colors, styling }) => {
    const baseStyle: React.CSSProperties = {
        width: size,
        height: size,
        borderRadius: '50%',
        borderTop: `2px solid ${colors}`,
        borderBottom: `2px solid ${colors}`,
        animation: 'spin 1.5s linear infinite',
        zIndex: 900, // Ensure the loader is on top
        position: 'fixed', // Fix the position to the center of the screen
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)', // Center the loader
    };

    const overlayStyle: React.CSSProperties = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
        backdropFilter: 'blur(5px)', // Apply blur effect
        zIndex: 899, // Just below the loader
    };

    const keyframes = `
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
    `;

    return (
        <div className="custom-loader-wrapper">
            <style>{keyframes}</style>
            <div style={overlayStyle} />
            <div className={`flex items-center justify-center ${styling}`}>
                <div style={baseStyle} />
            </div>
        </div>
    );
};

export default CustomLoader;

export const ThreeCircleLoader: React.FC<CustomLoaderProps> = ({
    size,
    colors,
}) => {
    return (
        <div className="flex justify-center items-center">
            <div
                style={{
                    marginRight: '8px',
                    width: size,
                    height: size,
                    borderRadius: '50%',
                    background: colors,
                    animation: 'bounce 1s infinite alternate',
                    animationDelay: '0.2s',
                }}
            />
            <div
                style={{
                    marginRight: '8px',
                    width: size,
                    height: size,
                    borderRadius: '50%',
                    background: colors,
                    animation: 'bounce 1s infinite alternate',
                    animationDelay: '0.4s',
                }}
            />
            <div
                style={{
                    width: size,
                    height: size,
                    borderRadius: '50%',
                    background: colors,
                    animation: 'bounce 1s infinite alternate',
                    animationDelay: '0.6s',
                }}
            />
            <style>
                {`
                    @keyframes bounce {
                        0% {
                            transform: translateY(0) scale(1);
                        }
                        100% {
                            transform: translateY(-8px) scale(1.6);
                        }
                    }
                `}
            </style>
        </div>
    );
};

export const TripleDotLoader: React.FC<CustomLoaderProps> = ({
    size,
    colors,
}) => {
    return (
        <div className="relative mx-auto">
            <div
                className="loader--text"
                style={{ color: colors, fontSize: size }}
            >
                <style>
                    {`
                        @keyframes loading-text {
                            0% {
                                content: 'Loading';
                            }
                            25% {
                                content: 'Loading.';
                            }
                            50% {
                                content: 'Loading..';
                            }
                            75% {
                                content: 'Loading...';
                            }
                        }
                        .loader--text:after {
                            content: 'Loading';
                            font-weight: bold;
                            animation-name: loading-text;
                            animation-duration: 3s;
                            animation-iteration-count: infinite;
                        }
                    `}
                </style>
            </div>
        </div>
    );
};

export const CSTableLoader: React.FC<CustomLoaderProps> = () => {
    return (
        <div className="flex items-center justify-center w-full h-56 border border-gray-200 rounded-lg bg-gray-50  dark:border-gray-700">
            <div className="px-6 py-3 text-md font-medium leading-none text-center text-blue-800 bg-blue-200 rounded animate-pulse dark:bg-blue-900 dark:text-blue-200">
                "Please wait Almost there!"
            </div>
        </div>
    );
};
