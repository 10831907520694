
import React, { ReactNode, MouseEvent } from "react";

interface customeButton {
  children?: ReactNode
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean
}

const Button: React.FC<customeButton> = ({ children, onClick, disabled }) => {
  return (
    <div className="p-4">
      <div className="grid self-center bg-green-600 hover:bg-green-800 text-white font-bold lg:w-48 lg:h-40 md:w-32 p-10 px-4 rounded">
        <button onClick={onClick} disabled={disabled}>
          {children}
        </button>
      </div>
    </div>
  );



}
export default Button;