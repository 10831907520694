import React, { useEffect, useState } from "react";
import { Fullpage, Page } from "../../components/Sidebar";
import { PayableSalaryApi } from "../../services/payableSalary";
import { wordsToNumbers } from 'words-to-numbers';

interface MatrixDataItem {
  [key: string]: any;
}

export default function AttendanceReportPage({ sidebar }: any) {
  const { getMatrixData } = PayableSalaryApi();
  const [matrixAllData, setMatrixData] = useState<MatrixDataItem[]>([]);

  useEffect(() => {
    const fetchMatrixData = async () => {
      try {
        const response = await getMatrixData();
        setMatrixData(response.data.data);
      } catch (error) {
        console.error("Error fetching matrix data:", error);
        // Handle error here, such as displaying an error message to the user
      }
    };

    fetchMatrixData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convertColumnName = (name: string) => {
    if (name === 'experienceYear') return "Experience In Year";
    if (name === 'levelThirteenk') return "Level 13k";
    const match = name.match(/level(\w+)/);
    if (match) {
        const numericPart = wordsToNumbers(match[1]);
        console.log("numericPart == ", numericPart);
      return `Level ${numericPart}`;
    }

    return name;
  };

  const columnsToSkip = ['id', 'updatedAt', 'createdAt'];
  const filteredKeys = Object.keys(matrixAllData[0] || {}).filter(
    (key) => !columnsToSkip.includes(key)
  );

  return (
    <Page>
      <Fullpage close={sidebar}>
        <div className="w-4/5 min-h-svh mt-5 mb-5 bg-white rounded-xl shadow-lg">
          <div className="w-full text-center p-6 m-6">
            <p className="text-center font-bold text-2xl">7th Pay Commission Matrix</p>
          </div>

          <div className="mx-5 overflow-auto rounded-md shadow-lg">
            <table className="w-full rounded-md shadow-lg bg-gray-200 overflow-hidden">
              <thead className="sticky top-0 bg-green-800 ">
                <tr>
                  {filteredKeys.map((headItem, index) => (
                    <th
                      key={`header-${index}`}
                      className="text-center min-w-32 px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-100 uppercase tracking-wider"
                    >
                      {convertColumnName(headItem)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {matrixAllData.map((item, rowIndex) => (
                  <tr
                    key={`row-${rowIndex}`}
                    className={rowIndex !== matrixAllData.length - 1 ? 'border-b border-gray-400' : ''}
                  >
                    {filteredKeys.map((key, colIndex) => (
                      <td key={`cell-${rowIndex}-${colIndex}`} className="p-2">{item[key]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Fullpage>
    </Page>
  );
}
