import { SearchLaonCriteria } from "../pages/Reports/Components/LoanSummary";
import { SearchActivityCriteria } from "../pages/Reports/Components/auditReport";
import { useAxios } from "../utils/hooks";

interface SearchCriteria {
    type : string;
    fromDate : string;
    toDate : string;
}

interface SearchReimCriteria {
    type : string;
    fromDate : string;
    toDate : string;
    status : string
}

interface VarianceSearchCriteria {
    attribute : string;
    timeline : string;
 }

interface SearchCriteria2 {
    firstName: string,
    docName:string
}


export const ReportsApi = () => {

    const AxiosInstance = useAxios();


    const getAttendanceAndLeaveData = async (filterData: SearchCriteria) => {
        try {
            const res: any = await AxiosInstance.current?.get(`/route/leaveAttendance/getAttendanceAndLeaveData?type=${filterData.type}&fromDate=${filterData.fromDate}&toDate=${filterData.toDate}`);
            return res;
        } catch (error) {
            console.error("error >> ", error);
        }
    }

    const downloadExcelReport = async (leaveAttendanceData : any, fileName: string) => {
        try {
        const res: any = await AxiosInstance.current?.post(`/route/leaveAttendance/downloadExcelReport?fileName=${fileName}`, { reportData : leaveAttendanceData},{ responseType: 'blob', });
            return res;
        } catch (error) {
            console.error("error >> ", error);
        }
    }

    const getReportdocument = async (filterData: SearchCriteria2) =>{
        try{
        const res:any = await AxiosInstance.current?.get(`/route/users/getAllReportdocument?firstName=${filterData.firstName}&docName=${filterData.docName}`)
        return res
        }catch (error) {
            console.error(error)
        }
    }

    const getMissingInformation = async () =>{
        try{
            const res:any = await AxiosInstance.current?.get(`/route/users/getAllMissingInformation`)
            return res
        }catch (error) {
            console.error(error)
        }
    }

    const getReimbursementData = async (filterData: SearchReimCriteria) => {
        try {
            const res: any = await AxiosInstance.current?.get(`/route/reimbursement/getReimbursementReportData?type=${filterData.type}&fromDate=${filterData.fromDate}&toDate=${filterData.toDate}&status=${filterData.status}`);
            return res;
        } catch (error) {
            console.error(error);
        }
    }
    
    const ExportExcelSheet = async (ReimbursementData : any, fileName: string) => {
        try {
        const res: any = await AxiosInstance.current?.post(`/route/reimbursement/ExportExcelSheet?fileName=${fileName}`, { reportData : ReimbursementData},{ responseType: 'blob', });
            return res;
        } catch (error) {
            console.error(error);
        }
    }
    
    const getVarianceData = async (filterData: VarianceSearchCriteria) => {
        try {
            const res: any = await AxiosInstance.current?.get(`/route/payableSalary/getVarianceData?attribute=${filterData.attribute}&timeline=${filterData.timeline}`);
            return res;
        } catch (error) {
            console.error("error >> ", error);
        }
    }

    const MultiRemider = async (userId: number[]) => {
        try {
          const res: any = await AxiosInstance.current?.post(`/route/users/sendMultiRemider`, { userId });
          return res.data
        } catch (error: any) {
            console.error("error >> ", error);
        }
    }
    const getLoanSummaryData = async (filterData: SearchLaonCriteria) => {        
        try {
            const res: any = await AxiosInstance.current?.get(`/route/loanDetails/getLoanSummaryData?type=${filterData.type}&fromDate=${filterData.fromDate}&toDate=${filterData.toDate}`);
            return res;
        } catch (error) {
            console.error(error);
        }
    }

    const getActivityData = async()=>{
        try{
            const res:any = await AxiosInstance.current?.get(`/route/loanDetails/getActivityData`);
            return res;
        }
        catch(error){
            console.error(error);
        }
    }
    const getAllActivityData = async(filterData:SearchActivityCriteria)=>
    {
        try{
            const res:any = await AxiosInstance.current?.get(`/route/loanDetails/getAllActivityData?type=${filterData.type}&&employeeEmail=${filterData.employeeEmail}`);
            return res;
        }
        catch(error){
            console.error(error);
        }
    }
    return { getAttendanceAndLeaveData, getMissingInformation, getReportdocument, downloadExcelReport, getReimbursementData, ExportExcelSheet, getVarianceData ,MultiRemider, getLoanSummaryData, getActivityData, getAllActivityData}
  
}