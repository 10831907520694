
import Button from "../../../components/Button";
import { UsersApi } from "../../../services/users";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Main, Page, RightBar } from '../../../components/Sidebar';
import PeopProfPhoto from "./PeopProfPhoto";
import PeopGeneDoc from "./PeopGeneDoc";
// import PaymentSlipPage from "./DocumentPage";
import { toast } from "react-toastify";
import moment from "moment";
import * as Yup from 'yup';
import { ProfileApi } from "../../../services/Profile";
import { BsCameraFill } from "react-icons/bs";
import ResignationEmp from "./ResignationEmp";
import Modal from "../../../components/modal";
import DocumentsPage from "./DocumentPage";
import CreateDocTemplate from "../../Profile/CreateDocTemplate";
import CustomLoader from '../../../components/loader';

export interface usersUpdate {
    id: number,
    natureOfEmployment: string,
    firstName: string,
    lastName: string,
    email: string,
    mobile: number,
    joiningDate: string,
    designation: string,
    department: string,
    isTeacher: boolean,
    monthlySalary: number,
    location: string,
    residentIndia: string,
    picked: string,
    keycloakId: string,
    role: string,
    gender: string,
    dateOfBirth: string
    age: number,
    bloodGroup: string,
    currentAddress?: string,
    permanentAddress?: string,
    emergencyContactPerson?: string,
    personalContactNumber?: number,
    personalContactEmailId?: string,
    profilePhoto: string,
    managerId: string,
    managerFName: string,
    managerLName: string,
    employeeCode: string,
    typeOfEmployee: string,
    maritalStatus: string
}
interface RoleAccess {
    access: string;
}
interface PeopleDetailsProps {
    userRole: string;
    sidebar: boolean;
    sidebarAccess: RoleAccess[];
}
interface BankData {
    accountNo: number;
    id: number;
    ifscCode: string;
    panCardNo: string;
    beneficiaryName: string;
    userId: number;
    status?: string;
}
interface userErr {
    firstName: string,
    lastName: string,
    panCardNo: string,
    email: string,
    accountNo: string,
    designation: string,
    // location: string,
    mobile: string,
    ifscCode: string,
    bloodGroup: string,
    currentAddress: string,
    permanentAddress: string,
    emergencyContactPerson: string,
    personalContactNumber: string,
    personalContactEmailId: string,
    beneficiaryName: string,
    monthlySalary: string,
    gender: string,
    dateOfBirth: string,
    age: string,
    // isTeacher: boolean,
    maritalStatus: string,
    typeOfEmployee: string
}

export enum IMaritalStatus {
    MARRIED = "Married",
    UNMARRIED = "Unmarried",
    DIVORCEd = "Divorced",
    SEPARATED = "Separated",
    WIDOWORWIDOWER = "Widow/Widower"
}

export enum ITypeOfEmployee {
    TEACHING = "Teaching",
    NONTEACHING = "Non-Teaching",
    ACADEMIC = "Academic (Other)"
}
// enum IResignationStatus {
//     Pending = "Pending",
//     Approved = "Approved",
//     Rejected = "Rejected"
// }
// interface FormData {
//     id: string,
//     name: string,
//     email: string,
//     isTeacher: string,
//     dateOfResignation: string,
//     reasonForResignation: string,
//     comment: string,
//     userId: number,
//     status: IResignationStatus

// }
const validationSchema = Yup.object().shape({
    firstName: Yup.string().matches(/^[A-Za-z\s]+$/, 'First name must contain only letters'),
    lastName: Yup.string().matches(/^[A-Za-z\s]+$/, 'Last name must contain only letters'),
    // email: Yup.string().matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'Invalid email format').required('Email is required'),
    email: Yup.string().matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'Invalid email format'),

    designation: Yup.string().matches(/^[A-Za-z\s]+$/, 'Designation must contain only letters'),
    monthlySalary: Yup.string().matches(/^\d+$/, 'Annual Salary must be numeric'),
    // eslint-disable-next-line no-useless-escape
    // location: Yup.string().matches(/^[A-Za-z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\-]+$/, 'Location is required'),
    mobile: Yup.string().matches(/^\d{10}$/, 'Phone Number must be 10 digits'),
    // dateOfBirth: Yup.date().required("Date Of Birth Required"),
    dateOfBirth: Yup.string().required("Date Of Birth Required"),
    age: Yup.string().matches(/^\d+$/, "Age must be numeric").max(3).required("Age is a required field"),
    // eslint-disable-next-line no-useless-escape
    bloodGroup: Yup.string().max(3, "Max length for blood group is 3").notRequired().matches(/^[A-Z+\-]*$/, "only blood Type"),
    // isTeacher: Yup.string().matches(/^[A-Za-z0-9\s.,()-]+$/, 'Manager name is Required')
});

const BankValidation = Yup.object().shape({
    panCardNo: Yup.string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, 'Invalid PAN format'),
    accountNo: Yup.string().matches(/^\d+$/, 'Invalid account number format (must be numeric)'),
    beneficiaryName: Yup.string().matches(/^[A-Za-z\s]+$/, 'Beneficiary Name must contain only letters'),
    ifscCode: Yup.string().matches(/^[A-Za-z]{4}[0][A-Za-z0-9]{6}$/, 'Invalid IFSC Code'),

});
function PeopleDetails({ sidebar, userRole, sidebarAccess }: PeopleDetailsProps) {

    const [isFirstFormOpen, setFirstFormOpen] = useState(true);
    const [isEditing, setisEditing] = useState({ BasicInfoEdit: false, CompensationEdit: false, OtherInfoEdit: false, UserRoles: false, PaymentInfoEdit: false });
    const [isProfilePhoto, setisProfilePhoto] = useState(false);
    const [isopenDocuments, setisopenDocument] = useState(false);
    const [isrightBar, setisRightBar] = useState(true)
    const [isModal, setModal] = useState(false);
    const [ResignationOpen, setResignationOpen] = useState(false);
    const [isGenerateDoc, setGenerateDoc] = useState(false);
    const [isCreateTamplate, setCreateTamplate] = useState(false);
    const { userId } = useParams<{ userId: string }>()
    const user = parseInt(userId);
    const [allRole, setAllRole] = useState<any>([]);
    const { getUserId, updateUserData, updateBankDetails, Reminder, geteBankDetails, AssignAllRole } = UsersApi();
    const { AprroveReject } = ProfileApi();
    const [BankData, setBankData] = useState<BankData[]>([{ beneficiaryName: "", accountNo: NaN, id: NaN, ifscCode: "", panCardNo: "", userId: NaN }]);
    // const [resignation, setResignation] = useState<any>({ id: "", name: "", email: "", isTeacher: "", userId: NaN, status: null, dateOfResignation: "", reasonForResignation: "", comment: "" });
    const [data, setData] = useState<usersUpdate>({ id: NaN, natureOfEmployment: '', age: NaN, firstName: '', lastName: '', email: '', location: '', residentIndia: '', keycloakId: '', picked: '', mobile: NaN, joiningDate: '', designation: '', department: '', isTeacher: false, monthlySalary: NaN, role: '', gender: '', dateOfBirth: '', bloodGroup: '', currentAddress: '', permanentAddress: '', emergencyContactPerson: '', personalContactNumber: NaN, personalContactEmailId: '', profilePhoto: '', managerId: '', managerFName: '', managerLName: '', employeeCode: '', typeOfEmployee: '', maritalStatus: '' });
    const initialErr = { panCardNo: '', email: '', accountNo: '', firstName: '', lastName: '', designation: '', mobile: '', ifscCode: '', bloodGroup: '', currentAddress: '', permanentAddress: '', emergencyContactPerson: '', personalContactNumber: '', personalContactEmailId: '', beneficiaryName: '', monthlySalary: '', gender: '', dateOfBirth: '', age: '', typeOfEmployee: '', maritalStatus: '' }
    const [err, setErr] = useState<userErr>(initialErr);
    const [isSubmit, setIsSubmit] = useState<any>({ PaymentInformation: false });
    const [loading, setLoading] = useState(false);
    // const [managers, setManager] = useState([]);
    // const run = useRef(false);
    useEffect(() => {
        funt();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ResignationOpen]);
    const funt = async () => {
        const data = await getUserId(user);
        const role = await AssignAllRole();
        // const managers = await getAllManager(null);
        // setManager(managers.data)
        setAllRole(role.data);
        setData(data);
        // const resign = await getResignation(user);
        // setResignation(resign);
        const BankData = await geteBankDetails(user);
        if (BankData.length === 0 && userId !== undefined) {
            setBankData([{ ...BankData, userId: user }]);
        } else if (BankData.length !== 0) {
            setBankData(BankData);
        }

    }


    // const updateResignationData = async (status: any) => {
    //     // var updateUser;
    //     const updated = { ...resignation, status: status }
    //     const data = await updateResignation(updated);
    //     setResignation(data.data);
    //     // if (err.email === "" && err.firstName === "" && err.lastName === "" && err.Designation === "" && err.location === '') {
    //     //     isEditing.BasicInfoEdit && err ? (updateUser = await updateUserData(data)) : setisEditing({ ...isEditing, BasicInfoEdit: !isEditing.BasicInfoEdit });
    //     //     if (updateUser?.data.status === 200) {
    //     //         setisEditing({ ...isEditing, BasicInfoEdit: !isEditing.BasicInfoEdit });;
    //     //         toast.success(updateUser.data.message)
    //     //     }
    //     // }
    // };

    const BasicInformationEdit = async () => {
        var updateUser;
        // if (err.email === "" && err.firstName === "" && err.lastName === "" && err.designation === "" && err.location === '') {
        // isEditing.BasicInfoEdit && err ? (updateUser = await updateUserData(data)) : setisEditing({ ...isEditing, BasicInfoEdit: !isEditing.BasicInfoEdit });
        try {
            if (isEditing.BasicInfoEdit) {
                setIsSubmit({ BasicInformation: true });
                await validationSchema.validate(data, { abortEarly: false });
                setErr(initialErr);
                setLoading(true);
                updateUser = await updateUserData(data);
                setData(updateUser.data.data)
            } else {
                setIsSubmit({});
                setisEditing({ ...isEditing, BasicInfoEdit: !isEditing.BasicInfoEdit });
            }
            if (updateUser?.data.status === 200) {
                setisEditing({ ...isEditing, BasicInfoEdit: !isEditing.BasicInfoEdit });;
                toast.success(updateUser.data.message)
            }
        } catch (error) {
            errorFunction(error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        }
    };

    const CompensationPerquisitesEdit = async () => {
        var updateUser;
        try {
            if (isEditing.CompensationEdit) {
                setIsSubmit({ CompensationEdit: true });
                await validationSchema.validate(data, { abortEarly: false });
                updateUser = await updateUserData(data);
            } else {
                setIsSubmit({});
                setisEditing({ ...isEditing, CompensationEdit: !isEditing.CompensationEdit });
            }
            if (updateUser?.data.status === 200) {
                setisEditing({ ...isEditing, CompensationEdit: !isEditing.CompensationEdit });;
                toast.success(updateUser.data.message)
            }
        } catch (error) {
            errorFunction(error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        }
    };

    const OtherInformationEdit = async () => {
        var updateUser;
        // if (err.mobile === "") {
        // isEditing.OtherInfoEdit ? (updateUser = await updateUserData(data)) : setisEditing({ ...isEditing, OtherInfoEdit: !isEditing.OtherInfoEdit });

        // }
        try {
            if (isEditing.OtherInfoEdit) {
                setIsSubmit({ OtherInformation: true });
                await validationSchema.validate(data, { abortEarly: false });
                setErr(initialErr);
                setLoading(true);
                updateUser = await updateUserData(data);
            } else {
                setIsSubmit({});
                setisEditing({ ...isEditing, OtherInfoEdit: !isEditing.OtherInfoEdit });
            }
            if (updateUser?.data.status === 200) {
                setisEditing({ ...isEditing, OtherInfoEdit: !isEditing.OtherInfoEdit });
                toast.success(updateUser.data.message)
            }
        } catch (error) {
            errorFunction(error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        }

    };

    // const UserRoles = async () => {
    //     var updateUser;
    //     isEditing.UserRoles ? (updateUser = await updateUserData(data)) : setisEditing({ ...isEditing, UserRoles: !isEditing.UserRoles });

    //     if (updateUser?.data.status === 200) {
    //         setisEditing({ ...isEditing, UserRoles: !isEditing.UserRoles });
    //         toast.success(updateUser.data.message)
    //     }
    // };

    const PaymentInformationEdit = async () => {
        var BankDetails;
        try {
            if (isEditing.PaymentInfoEdit) {
                setIsSubmit({ PaymentInformation: true });
                await BankValidation.validate(BankData[BankData.length - 1], { abortEarly: false });
                // await validationSchema.validate(data, { abortEarly: false });
                setErr(initialErr);
                BankDetails = await updateBankDetails({ ...BankData[BankData.length - 1] });
            } else {
                setisEditing({ ...isEditing, PaymentInfoEdit: !isEditing.PaymentInfoEdit });
            }
            if (BankDetails?.data.status === 200) {
                setBankData(BankDetails.data.data);
                setisEditing({ ...isEditing, PaymentInfoEdit: !isEditing.PaymentInfoEdit });
                toast.success(BankDetails.data.message);
            }
            if (BankDetails?.data.status === 409) {
                toast.error(BankDetails.data.message);
            }
        } catch (error) {
            errorFunction(error);

        }

    };

    const PaymentInfoApproveReject = async (status: string) => {
        const updated = { ...BankData[BankData.length - 1] }
        const BankDetails = await AprroveReject(status, updated);
        if (BankDetails?.data.status === 200) {
            setBankData(BankDetails.data.data);
            toast.success(BankDetails.data.message);
        }

    };

    const handleChange = async (field: string, value: string) => {
        try {
            setErr((prevErrors) => ({
                ...prevErrors,
                [field]: '',
            }));

            setData((data) => ({
                ...data,
                [field]: value,
            }));
            setBankData((prevBankData) => {
                const updatedBankData = [...prevBankData];
                updatedBankData[prevBankData.length - 1] = {
                    ...updatedBankData[prevBankData.length - 1],
                    [field]: value,
                };
                return updatedBankData;
            });
            if (isSubmit.BasicInformation === true || isSubmit.OtherInformation === true || isSubmit.CompensationEdit === true) {
                await validationSchema.validate((data: any) => data, { abortEarly: false });
                // const updatedBankData = [...BankData];
                // await BankValidation.validate({ ...BankData[BankData.length - 1], [field]: value }, { abortEarly: false });
            } else if (isSubmit.PaymentInformation === true) {
                await BankValidation.validate({ ...BankData[BankData.length - 1], [field]: value }, { abortEarly: false });
            }

        } catch (error) {
            errorFunction(error);
        }
    };

    const errorFunction = async (error: unknown) => {
        if (error instanceof Yup.ValidationError) {
            const errors = error.inner.reduce((acc, { path, message }) => ({
                ...acc,
                [path as keyof userErr]: message,
            }), {} as Partial<userErr>);

            setErr(prevErrors => ({ ...prevErrors, ...errors }));
        }
        // setErr((prevErrors) => ({
        //     ...prevErrors,
        //     [field]: error.message,
        //   }));
    }

    const openProfilePhoto = () => {
        setisProfilePhoto(!isProfilePhoto)
        // setFirstFormOpen(!isFirstFormOpen)
        // setisRightBar(!isrightBar)
    }

    const openDocuments = () => {
        setisopenDocument(!isopenDocuments)
        setFirstFormOpen(!isFirstFormOpen)
        setisRightBar(!isrightBar)
    }

    const openModal = () => {
        setModal(true)
    }
    const closeModel = () => {
        setModal(false)
    }
    const sendReminder = async () => {
        const responce = await Reminder(user);
        if (responce.status === 200) {
            toast.success(responce.message);
            setTimeout(() => {
                setModal(false);
            }, 500)
        }

    }
    const generateDoc = () => {
        setGenerateDoc(!isGenerateDoc)
        setFirstFormOpen(!isFirstFormOpen)
        setisRightBar(!isrightBar)
    }
    const createTamplate = () => {
        setGenerateDoc(!isGenerateDoc)
        setCreateTamplate(!isCreateTamplate)
    }

    return (
        <Page>
            <Main close={sidebar}>
                {isFirstFormOpen && (
                    <div  >
                        <div className="w-full bg-white p-5 mt-5 mb-5 shadow-lg rounded-lg">
                            <div >
                                <div className="flex items-center bg-green-800 rounded-md shadow-lg text-xl p-1 text-left">
                                    <div className="relative">
                                        <img className="m-1 w-8 sm:h-8 ring-2 text-sm rounded-full md:m-3 md:w-20 md:h-20 md:ring-4 ring-green-300 " src={data.profilePhoto} alt="profile pic" />
                                        <button onClick={openProfilePhoto} className="bottom-3 left-16 absolute bg-gray-300 p-1 rounded-full"><BsCameraFill /></button>
                                    </div>
                                    <p className="text-3xl">{data?.firstName} {data?.lastName}</p>
                                </div>
                                <div className="bg-gray-100 p-5 mt-5 rounded-md shadow-md" >
                                    {loading && (
                                        <CustomLoader size={150} colors={"green"} />
                                    )}
                                    <div className="flex justify-between h-12 items-center ">
                                        {/* <div className=""> */}
                                        <p className="text-slate-800 font-bold text-2xl"> Basic Information </p>
                                        {/* </div> */}
                                        {sidebarAccess.some(menu => menu.access === 'profile') && (
                                            isEditing.BasicInfoEdit ? (
                                                <div className="text-white bg-blue-500 hover:bg-sky-700   font-medium rounded-md text-sm px-3 py-2 text-center mt-2  mb-2 me-2">
                                                    <button onClick={BasicInformationEdit} >Save</button>
                                                </div>
                                            ) : (
                                                <div className="text-white bg-blue-500 hover:bg-sky-700   font-medium rounded-md text-sm px-3 py-2 text-center mt-2  mb-2 me-2">
                                                    <button onClick={BasicInformationEdit} >Edit</button>
                                                </div>
                                            )
                                        )}
                                    </div>

                                    <table className="w-full table-fixed text-sm text-center rtl:text-center text-black-800 rounded-lg shadow-lg">
                                        <thead className="text-sm text-white uppercase bg-gray-800 dark:bg-gray-900 dark:text-white">

                                        </thead>
                                        <tbody className="divide-y ">
                                            <tr className="bg-white divide-x">
                                                {isEditing.BasicInfoEdit && sidebarAccess.some(menu => menu.access === 'profile') ? (<>
                                                    <td className="h-10 bg-white font-semibold pl-32 text-left">Nature Of Employee</td>
                                                    <td className="h-10 pl-32 text-left">
                                                        <div >
                                                            <input className={`w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block  p-1.5 ${err.firstName ? 'border-red-500 placeholder-red-700 mt-1 focus:ring-red-500  focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500'} `}
                                                                type='text'
                                                                value={data?.natureOfEmployment}
                                                                placeholder='natureOfEmployment'
                                                                onChange={(e) => {
                                                                    handleChange('natureOfEmployment', e.target.value)
                                                                }}
                                                            />
                                                            {/* <select
                                                                className={`w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block p-1.5 `}
                                                                value={data?.natureOfEmployment}
                                                                onChange={(e) => {
                                                                    handleChange('natureOfEmployment', e.target.value)
                                                                }}
                                                            >
                                                                <option disabled value="">Select</option>
                                                                <option value="Employee">Employee</option>
                                                                <option value="Contractor">Contractor</option>
                                                            </select> */}
                                                        </div>
                                                    </td></>
                                                ) : (<><td className="h-10 bg-white font-semibold pl-32 text-left">Nature Of Employee</td>
                                                    <td className="h-10 bg-white pl-32 text-left">{data?.natureOfEmployment || '-'}</td></>
                                                )}
                                            </tr>
                                            <tr className="bg-white divide-x ">
                                                {isEditing.BasicInfoEdit ? (<>
                                                    <td className="h-10 bg-white font-semibold pl-32 text-left">First Name</td>
                                                    <td className="h-10 pl-32 text-left">
                                                        <div>
                                                            <input className={`w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block  p-1.5 ${err.firstName ? 'border-red-500 placeholder-red-700 mt-1 focus:ring-red-500  focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500'} `}
                                                                type='text'
                                                                value={data?.firstName}
                                                                placeholder='firstName'
                                                                onChange={(e) => {
                                                                    handleChange('firstName', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                        <div>{err.firstName && <b className='text-red-500 text-xs mt-1'>{err.firstName}</b>}</div>
                                                    </td></>
                                                ) : (<><td className="h-10 bg-white font-semibold pl-32 text-left">First Name</td>
                                                    <td className="h-10 bg-white pl-32 text-left">{data?.firstName || '-'}</td></>
                                                )}
                                            </tr>
                                            <tr className="bg-white divide-x">
                                                {isEditing.BasicInfoEdit ? (<>
                                                    <td className="h-10 bg-white font-semibold pl-32 text-left">Last Name</td>
                                                    <td className="h-10 pl-32 text-left">
                                                        <div>
                                                            <input className={`w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block p-1.5 ${err.lastName ? 'border-red-500 placeholder-red-700 mt-1 focus:ring-red-500  focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500'} `}
                                                                type='text'
                                                                value={data?.lastName}
                                                                placeholder='lastName'
                                                                onChange={(e) => {
                                                                    handleChange('lastName', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                        <div>{err.lastName && <b className='text-red-500 text-xs mt-1'>{err.lastName}</b>}</div>
                                                    </td></>
                                                ) : (<><td className="h-10 bg-white font-semibold pl-32 text-left">Last Name</td>
                                                    <td className="h-10 bg-white pl-32 text-left">{data?.lastName || '-'}</td></>
                                                )}
                                            </tr>
                                            <tr className="bg-white divide-x">
                                                {/* {isEditing.BasicInfoEdit ? (<> */}
                                                {/* <td className="h-10 bg-white font-semibold pl-32 text-left">Email</td>
                                                    <td className="h-10 pl-32 text-left">
                                                        <div>
                                                            <input className={`w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block p-1.5 ${err.email ? 'border-red-500 placeholder-red-700 mt-1 focus:ring-red-500  focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500'} `}
                                                                type='text'
                                                                value={data?.email}
                                                                placeholder='Email'
                                                                onChange={(e) => {
                                                                    handleChange('email', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                        <div>{err.email && <b className='text-red-500 text-xs mt-1'>{err.email}</b>}</div>
                                                    </td></>
                                                ) : (<> */}
                                                <td className="h-10 bg-white font-semibold pl-32 text-left">Email</td>
                                                <td className="h-10 bg-white pl-32 text-left">{data?.email || '-'}</td>
                                                {/* </> */}
                                                {/* )} */}

                                            </tr>
                                            <tr className="bg-white divide-x">
                                                {isEditing.BasicInfoEdit && sidebarAccess.some(menu => menu.access === 'profile') ? (<>
                                                    <td className="h-10 bg-white font-semibold pl-32 text-left">Date of Hiring</td>
                                                    <td className="h-10 pl-32 text-left"><div>
                                                        <input className={`w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block p-1.5 `}
                                                            type='date'
                                                            value={data?.joiningDate}
                                                            placeholder='Date of Hiring'
                                                            onChange={(e) => {
                                                                handleChange('joiningDate', e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                    </td></>
                                                ) : (<><td className="h-10 bg-white font-semibold pl-32 text-left">Date of Joining</td>
                                                    <td className="h-10 bg-white pl-32 text-left">{data?.joiningDate ? moment(data.joiningDate).format('DD-MM-YYYY') : '-'}</td></>
                                                )}

                                            </tr>
                                            <tr className="bg-white divide-x">
                                                {isEditing.BasicInfoEdit && sidebarAccess.some(menu => menu.access === 'profile') ? (<>
                                                    <td className="h-10 bg-white font-semibold pl-32 text-left">Designation</td>
                                                    <td className="h-10 pl-32 text-left"><div>
                                                        {/* <input className={`w-48 bg-white text-center p-1 rounded-sm border ${err.designation ? 'border-red-500 placeholder-red-700 mt-1 focus:ring-red-500  focus:border-red-500' : ''} `}
                                                            type='text'
                                                            value={data?.designation}
                                                            placeholder='Designation'
                                                            onChange={(e) => {
                                                                handleChange('Designation', e.target.value)
                                                            }}
                                                        /> */}
                                                        <select className={`w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block p-1.5  ${err?.designation ? 'border-red-500 placeholder-red-700 focus:ring-red-500  focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500'} `}
                                                            value={data?.designation}
                                                            onChange={(e) => {
                                                                handleChange('designation', e.target.value)
                                                            }}
                                                        >
                                                            {allRole.map((role: { designation: string }) => (
                                                                <option value={role.designation}>{role.designation}</option>
                                                            )
                                                            )}
                                                        </select>
                                                    </div>
                                                        <div>{err.designation && <b className='text-red-500 text-xs mt-1'>{err.designation}</b>}</div>
                                                    </td></>
                                                ) : (<><td className="h-10 bg-white font-semibold pl-32 text-left">Designation</td>
                                                    <td className="h-10 bg-white pl-32 text-left">{data?.designation || '-'}</td></>
                                                )}
                                            </tr>
                                            <tr className="bg-white divide-x">
                                                <td className="h-10 bg-white font-semibold pl-32 text-left">Employee ID</td>
                                                <td className="h-10 bg-white pl-32 text-left">{data?.employeeCode || '-'}</td>
                                            </tr>
                                            <tr className="bg-white divide-x">
                                                {isEditing.BasicInfoEdit ? (
                                                    <>
                                                        <td className="h-10 bg-white font-semibold pl-32 text-left">Type Of Employee</td>
                                                        <td className="h-10 pl-32 text-left">
                                                            <div>
                                                                <select
                                                                    // className={`w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block p-1.5 ${err.salutation ? 'border-red-500 placeholder-red-700 mt-1 focus:ring-red-500 focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500'}`}
                                                                    value={data?.typeOfEmployee}
                                                                    onChange={(e) => {
                                                                        handleChange('typeOfEmployee', e.target.value as ITypeOfEmployee);
                                                                    }}
                                                                >
                                                                    <option disabled value="">Select</option>
                                                                    {Object.entries(ITypeOfEmployee).map(([key, val]) => (
                                                                        <option key={key} value={val}>
                                                                            {val}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div>{err.typeOfEmployee && <b className='text-red-500 text-xs mt-1'>{err.typeOfEmployee}</b>}</div>
                                                        </td>
                                                    </>
                                                ) : (
                                                    <>
                                                        <td className="h-10 bg-white font-semibold pl-32 text-left">Type Of Employee</td>
                                                        <td className="h-10 bg-white pl-32 text-left">{data?.typeOfEmployee || '-'}</td>
                                                    </>
                                                )}
                                            </tr>
                                            <tr className="bg-white divide-x">
                                                {isEditing.BasicInfoEdit ? (
                                                    <>
                                                        <td className="h-10 bg-white font-semibold pl-32 text-left">Marital Status</td>
                                                        <td className="h-10 pl-32 text-left">
                                                            <div>
                                                                <select
                                                                    // className={`w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block p-1.5 ${err.salutation ? 'border-red-500 placeholder-red-700 mt-1 focus:ring-red-500 focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500'}`}
                                                                    value={data?.maritalStatus}
                                                                    onChange={(e) => {
                                                                        handleChange('maritalStatus', e.target.value as IMaritalStatus);
                                                                    }}
                                                                >
                                                                    <option disabled value="">Select</option>
                                                                    {Object.entries(IMaritalStatus).map(([key, val]) => (
                                                                        <option key={key} value={val}>
                                                                            {val}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div>{err.maritalStatus && <b className='text-red-500 text-xs mt-1'>{err.maritalStatus}</b>}</div>
                                                        </td>
                                                    </>
                                                ) : (
                                                    <>
                                                        <td className="h-10 bg-white font-semibold pl-32 text-left">Marital Status</td>
                                                        <td className="h-10 bg-white pl-32 text-left">{data?.maritalStatus || '-'}</td>
                                                    </>
                                                )}
                                            </tr>
                                            {/* <tr className="bg-white divide-x">
                                                {isEditing.BasicInfoEdit ? (<>
                                                    <td className="h-10 bg-white font-semibold pl-32 text-left">Location</td>
                                                    <td className="h-10 pl-32 text-left"><div>
                                                        <textarea className={`w-48 my-1 block p-1.5 text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300  ${err.location ? 'border-red-500 placeholder-red-700 focus:ring-red-500  focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500'} `}
                                                            value={data?.location}
                                                            placeholder='Location'
                                                            onChange={(e) => {
                                                                handleChange('location', e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                        <div>{err.location && <b className='text-red-500 text-xs mt-1'>{err.location}</b>}</div>
                                                    </td></>
                                                ) : (<><td className="h-10 bg-white font-semibold pl-32 text-left">Location</td>
                                                    <td className="h-10 bg-white pl-32 text-left">{data?.location || '-'}</td></>
                                                )}
                                            </tr> */}
                                            {/* <tr className="bg-white divide-x">
                                                {isEditing.BasicInfoEdit && sidebarAccess.some(menu => menu.access === 'profile') ? (<>
                                                    <td className="h-10 bg-white font-semibold pl-32 text-left">Manager</td>
                                                    <td className="h-10 pl-32 text-left"><div>
                                                        <select className={`w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block p-1.5  ${err?.designation ? 'border-red-500 placeholder-red-700 focus:ring-red-500  focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500'} `}
                                                            value={data?.managerId}
                                                            onChange={(e) => {
                                                                handleChange('managerId', e.target.value)
                                                            }}
                                                        >
                                                            {managers.map((isTeacher: any) => (
                                                                <option value={isTeacher?.employeeCode}>{isTeacher?.firstName}{` (${isTeacher?.employeeCode})`}</option>
                                                            )
                                                            )}
                                                        </select>
                                                    </div>
                                                        <div>{err.isTeacher && <b className='text-red-500 text-xs mt-1'>{err.isTeacher}</b>}</div>
                                                    </td></>
                                                ) : (<><td className="h-10 bg-white font-semibold pl-32 text-left">Manager</td>
                                                    <td className="h-10 bg-white pl-32 text-left">{(data?.managerFName || 'N/A') + (data?.managerFName && data?.managerLName ? ` ${data.managerLName}` : '')}</td></>
                                                )}
                                            </tr> */}
                                            {/* <tr className="bg-white divide-x">
                                                <td className="h-10 bg-white font-semibold pl-32 text-left">Last login</td>
                                                <td className="h-10 bg-white pl-32 text-left">{'-'}</td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="bg-gray-100 p-5 mt-5 rounded-md shadow-md">
                                <div className="flex justify-between h-12 items-center ">
                                    {/* <div className=""> */}
                                    <p className="text-slate-800 font-bold text-2xl"> Compensation & Perquisites </p>
                                    {/* </div> */}
                                    {sidebarAccess.some(menu => menu.access === 'profile') && (
                                        isEditing.CompensationEdit ? (
                                            <div className="text-white bg-blue-500 hover:bg-sky-700   font-medium rounded-md text-sm px-3 py-2 text-center mt-2  mb-2 me-2">
                                                <button onClick={CompensationPerquisitesEdit} >Save</button>
                                            </div>
                                        ) : (
                                            <div className="text-white bg-blue-500 hover:bg-sky-700   font-medium rounded-md text-sm px-3 py-2 text-center mt-2  mb-2 me-2">
                                                <button onClick={CompensationPerquisitesEdit} >Edit</button>
                                            </div>
                                        )
                                    )}
                                </div>

                                <table className="w-full table-fixed text-sm text-center rtl:text-center text-black-800  rounded-lg shadow-lg overflow-hidden">
                                    <thead className="text-xs text-white uppercase bg-white   dark:text-white">

                                    </thead>
                                    <tbody className="divide-y">
                                        <tr className="bg-white divide-x ">
                                            {isEditing.CompensationEdit ? (<>
                                                <td className="h-10 bg-white font-semibold pl-32 text-left">Monthly Salary</td>
                                                <td className="h-10 pl-32 text-left">
                                                    <div>
                                                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block p-1.5 ${err.monthlySalary ? 'border-red-500 placeholder-red-700 focus:ring-red-500  focus:border-red-500' : ''} `}
                                                            type='text'
                                                            value={data?.monthlySalary}
                                                            placeholder='Monthly Salary'
                                                            onChange={(e) => {
                                                                handleChange('monthlySalary', e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                    <div>{err.monthlySalary && <b className='text-red-500 text-xs mt-1'>{err.monthlySalary}</b>}</div>
                                                </td></>
                                            ) : (<><td className="h-10 bg-white font-semibold pl-32 text-left">Annual Salary</td>
                                                <td className="h-10 pl-32 text-left">{data?.monthlySalary || '-'}</td></>
                                            )}
                                        </tr>
                                        {/* <tr className="bg-white divide-x ">
                                            <td className=" py-3 bg-white font-semibold pl-32 text-left">Number of Bonuses</td>
                                            <td className=" py-3 pl-32 text-left">-</td>
                                        </tr> */}

                                    </tbody>
                                </table>
                            </div>

                            {(sidebarAccess.some(menu => menu.access === "finance") || userId === undefined) && (<div className="bg-gray-100 p-5 mt-5 rounded-md shadow-lg">
                                <div className="flex justify-between h-12 items-center ">
                                    <div className="flex">
                                        <p className="text-slate-800 font-bold text-2xl"> Bank Information  </p>
                                        {/* {BankData.status === "Pending" && <span className="relative inline-block px-3 py-1 ml-3 font-semibold text-orange-900 leading-tight">
                                            <span aria-hidden className="absolute inset-0 bg-orange-200 rounded-full"></span>
                                            <span className="relative">current</span>
                                        </span>} */}
                                    </div>
                                    {userId === undefined && (
                                        (isEditing.PaymentInfoEdit) ? (
                                            <div className="text-white bg-blue-500 hover:bg-sky-700   font-medium rounded-md text-sm px-3 py-2 text-center mt-2 mb-2 me-2">
                                                <button onClick={PaymentInformationEdit} >Save</button>
                                            </div>
                                        ) : (
                                            <div className="text-white bg-blue-500 hover:bg-sky-700   font-medium rounded-md text-sm px-3 py-2 text-center mt-2 mb-2 me-2">
                                                <button onClick={PaymentInformationEdit} >Edit</button>
                                            </div>
                                        )
                                    )}

                                </div>
                                {BankData.map((BankData: any, index: number, BankDataArray: BankData[]) => (
                                    <>
                                        <div className="flex justify-between">
                                            <div className="flex m-1 py-2">
                                                {BankData.status !== "Old" && <span className={`relative inline-block px-3 py-1 ml-3 font-semibold ${BankData.status === 'Pending' ? 'text-orange-800' : BankData.status === 'Current' ? 'text-green-800' : 'text-red-800'} leading-tight`}>
                                                    <span aria-hidden className={`absolute inset-0 ${BankData.status === 'Pending' ? 'bg-orange-200' : BankData.status === 'Current' && (BankDataArray.length - 1) === 1 ? 'bg-green-200' : BankData.status === 'Rejected' ? 'bg-red-200' : ''} rounded-full`}></span>
                                                    {(BankDataArray.length - 1) === 1 && <span className="relative">{BankData.status}</span>}
                                                </span>}
                                            </div>
                                            {sidebarAccess.some(menu => menu.access === "finance" && userId !== undefined && BankData.status === "Pending") && <div>
                                                <button type="button" onClick={(e) => { PaymentInfoApproveReject("Approved") }} className="text-white bg-blue-500 hover:bg-sky-700   font-medium rounded-md text-sm px-3 py-2 text-center mt-2  mb-2 me-2">Approve</button>
                                                <button type="button" onClick={(e) => { PaymentInfoApproveReject("Rejected") }} className="text-white bg-red-500 hover:bg-red-700   font-medium rounded-md text-sm px-3 py-2 text-center mt-2  mb-2 me-2">Rejecte</button>
                                            </div>}
                                        </div>
                                        {/* {BankData.status !== "Old" && */}
                                        <table className="w-full table-fixed text-sm text-center rtl:text-center text-slate-800  rounded-lg shadow-lg overflow-hidden">

                                            <tbody className="divide-y">
                                                <tr className="bg-white divide-x">
                                                    {(isEditing.PaymentInfoEdit && ((BankDataArray.length - 1) === index)) ? (<>
                                                        <td className="h-10 bg-white font-semibold pl-32 text-left">PAN</td>
                                                        <td className="h-10 pl-32 text-left"><div>
                                                            <input className={`w-48 text-left bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block p-1.5 ${err.panCardNo ? 'border-red-500 placeholder-red-700 focus:ring-red-500 mt-1 focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500'} `}
                                                                type='text'
                                                                value={BankData?.panCardNo}
                                                                placeholder='PAN'
                                                                onChange={(e) => {
                                                                    handleChange('panCardNo', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                            <div>{err.panCardNo && <b className='text-red-500 text-xs mt-1'>{err.panCardNo}</b>}</div>
                                                        </td></>
                                                    ) : (<><td className="h-10 bg-white font-semibold pl-32 text-left">PAN</td>
                                                        <td className="h-10 pl-32 text-left">{BankData?.panCardNo ? ((userId && !sidebarAccess.some(menu => menu.access === "finance")) ? `${'X'.repeat(String(BankData.panCardNo).length - 4)}${String(BankData.panCardNo).substring(String(BankData.panCardNo).length - 4)}` : BankData?.panCardNo) : '-'}</td></>
                                                    )}
                                                </tr>
                                                <tr className="bg-white divide-x ">
                                                    {(isEditing.PaymentInfoEdit && ((BankDataArray.length - 1) === index)) ? (<>
                                                        <td className="h-10 bg-white font-semibold pl-32 text-left">IFSC Code</td>
                                                        <td className="h-10 pl-32 text-left"><div>
                                                            <input className={`w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block p-1.5 ${err.ifscCode ? 'border-red-500 placeholder-red-700 mt-1 focus:ring-red-500  focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500'} `}
                                                                type='text'
                                                                value={BankData?.ifscCode}
                                                                placeholder='IFSC Code'
                                                                onChange={(e) => {
                                                                    handleChange('ifscCode', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                            <div>{err.ifscCode && <b className='text-red-500 text-xs mt-1'>{err.ifscCode}</b>}</div>
                                                        </td></>
                                                    ) : (<><td className="h-10 bg-white font-semibold pl-32 text-left">IFSC Code</td>
                                                        <td className="h-10 pl-32 text-left">{BankData?.ifscCode || '-'}</td></>
                                                    )}

                                                </tr>
                                                <tr className="bg-white divide-x ">
                                                    {(isEditing.PaymentInfoEdit && ((BankDataArray.length - 1) === index)) ? (<>
                                                        <td className="h-10 bg-white font-semibold pl-32 text-left">Account Number</td>
                                                        <td className="h-10 pl-32 text-left"><div>
                                                            <input className={`w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block p-1.5 ${err.accountNo ? 'border-red-500 placeholder-red-700 mt-1 focus:ring-red-500  focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500'} `}
                                                                type='text'
                                                                value={(Number.isNaN(BankData?.accountNo)) ? "" : BankData?.accountNo}
                                                                placeholder='Account Number'
                                                                onChange={(e) => {
                                                                    handleChange('accountNo', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                            <div>{err.accountNo && <b className='text-red-500 text-xs mt-1'>{err.accountNo}</b>}</div>
                                                        </td></>
                                                    ) : (<><td className="h-10 bg-white font-semibold pl-32 text-left">Account Number</td>
                                                        <td className="h-10 pl-32 text-left">{BankData?.accountNo ? ((userId && !sidebarAccess.some(menu => menu.access === "finance")) ? `${'X'.repeat(String(BankData.accountNo).length - 4)}${String(BankData.accountNo).substring(String(BankData.accountNo).length - 4)}` : BankData?.accountNo) : '-'}</td></>
                                                    )}

                                                </tr>
                                                <tr className="bg-white divide-x ">
                                                    {(isEditing.PaymentInfoEdit && ((BankDataArray.length - 1) === index)) ? (<>
                                                        <td className="h-10 bg-white font-semibold pl-32 text-left">Beneficiary Name</td>
                                                        <td className="h-10 pl-32 text-left"><div>
                                                            <input className={`w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block p-1.5 ${err.beneficiaryName ? 'border-red-500 placeholder-red-700 mt-1 focus:ring-red-500  focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500'} `}
                                                                type='text'
                                                                value={BankData?.beneficiaryName}
                                                                placeholder='Beneficiary Name'
                                                                onChange={(e) => {
                                                                    handleChange('beneficiaryName', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                            <div>{err.beneficiaryName && <b className='text-red-500 text-xs mt-1'>{err.beneficiaryName}</b>}</div>
                                                        </td></>
                                                    ) : (<><td className="h-10 bg-white font-semibold pl-32 text-left">Beneficiary Name</td>
                                                        <td className="h-10 pl-32 text-left">{BankData?.beneficiaryName || '-'}</td></>
                                                    )}
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* } */}
                                    </>
                                ))}
                            </div>)}

                            <div className="bg-gray-100 p-5 mt-5 rounded-md shadow-lg">
                                {loading && (
                                    <CustomLoader size={150} colors={"green"} />
                                )}
                                <div className="flex justify-between h-12 items-center ">
                                    {/* <div className=""> */}
                                    <p className="text-slate-800 font-bold text-2xl"> Other Information </p>
                                    {/* </div> */}
                                    {/* {userId === undefined && ( */}
                                    {sidebarAccess.some(menu => menu.access === 'profile') && (

                                        isEditing.OtherInfoEdit ? (
                                            <div className="text-white bg-blue-500 hover:bg-sky-700   font-medium rounded-md text-sm px-3 py-2 text-center mt-2  mb-2 me-2">
                                                <button onClick={OtherInformationEdit} >Save</button>
                                            </div>
                                        ) : (
                                            <div className="text-white bg-blue-500 hover:bg-sky-700   font-medium rounded-md text-sm px-3 py-2 text-center mt-2  mb-2 me-2">
                                                <button onClick={OtherInformationEdit} >Edit</button>
                                            </div>
                                        ))
                                    }
                                </div>


                                <table className="w-full table-fixed text-sm text-center rtl:text-center text-slate-800  rounded-lg shadow-lg overflow-hidden">

                                    <tbody className="divide-y">
                                        <tr className="bg-white divide-x ">

                                            {isEditing.OtherInfoEdit ? (<>
                                                <td className="h-10 bg-white font-semibold pl-32 text-left">Phone Number</td>
                                                <td className="h-10 pl-32 text-left">
                                                    <div>
                                                        <input className={`w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block p-1.5 ${err.mobile ? 'border-red-500 placeholder-red-700 mt-1 focus:ring-red-500  focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500'} `}
                                                            type='text'
                                                            value={data?.mobile}
                                                            placeholder='Phone Number'
                                                            onChange={(e) => {
                                                                handleChange('mobile', e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                    <div>{err.mobile && <b className='text-red-500 text-xs mt-1'>{err.mobile}</b>}</div>
                                                </td></>
                                            ) : (<><td className="h-10 bg-white font-semibold pl-32 text-left">Phone Number</td>
                                                <td className="h-10 pl-32 text-left">{data?.mobile || '-'}</td></>
                                            )}
                                        </tr>
                                        <tr className="bg-white divide-x ">
                                            {isEditing.OtherInfoEdit ? (<>
                                                <td className="h-10 bg-white font-semibold pl-32 text-left">Gender</td>
                                                <td className="h-10 pl-32 text-left">
                                                    <div>
                                                        <select
                                                            className={`w-24 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block p-1.5 `}
                                                            value={data?.gender}
                                                            onChange={(e) => {
                                                                handleChange('gender', e.target.value)
                                                            }}
                                                        >
                                                            <option disabled value="">select</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                    </div>
                                                    <div>{err.gender && <b className='text-red-500 text-xs mt-1'>{err.gender}</b>}</div>
                                                </td></>
                                            ) : (<><td className="h-10 bg-white font-semibold pl-32 text-left">Gender</td>
                                                <td className="h-10 pl-32 text-left">{data?.gender || '-'}</td></>
                                            )}
                                        </tr>
                                        <tr className="bg-white divide-x ">
                                            {(!userId || sidebarAccess.some(menu => menu.access === 'profile')) && (
                                                isEditing.OtherInfoEdit ? (<>
                                                    <td className="h-10 bg-white font-semibold pl-32 text-left">Date of Birth</td>
                                                    <td className="h-10 pl-32 text-left">
                                                        <div>
                                                            <input className={`w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block p-1.5`}
                                                                type='date'
                                                                value={data?.dateOfBirth}
                                                                placeholder='Date of Birth'
                                                                onChange={(e) => {
                                                                    handleChange('dateOfBirth', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                        <div>{err.dateOfBirth && <b className='text-red-500 text-xs mt-1'>{err.dateOfBirth}</b>}</div>
                                                    </td></>
                                                ) : (<>
                                                    <td className="h-10 bg-white font-semibold pl-32 text-left">Date of Birth</td>
                                                    <td className="h-10 pl-32 text-left">
                                                        {data?.dateOfBirth ? moment(data.dateOfBirth).format('DD-MM-YYYY') : '-'}
                                                    </td>
                                                </>

                                                ))
                                            }

                                        </tr>
                                        <tr className="bg-white divide-x ">
                                            {(!userId || sidebarAccess.some(menu => menu.access === 'profile')) && (
                                                isEditing.OtherInfoEdit ? (<>
                                                    <td className="h-10 bg-white font-semibold pl-32 text-left">Age</td>
                                                    <td className="h-10 pl-32 text-left">
                                                        <div>
                                                            <input className={`w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block p-1.5`}
                                                                type='text'
                                                                inputMode='numeric'
                                                                value={data?.age}
                                                                name="Age"
                                                                placeholder='Age'
                                                                onChange={(e) => {
                                                                    handleChange('age', e.target.value)
                                                                }}

                                                            />
                                                        </div>
                                                        <div>{err.age && <b className='text-red-500 text-xs mt-1'>{err.age}</b>}</div>
                                                        <div>{err.age}</div>
                                                    </td></>
                                                ) : (<><td className="h-10 bg-white font-semibold pl-32 text-left">Age</td>
                                                    <td className="h-10 pl-32 text-left">{data.age || '-'}</td></>
                                                ))}
                                        </tr>
                                        <tr className="bg-white divide-x ">
                                            {(!userId || sidebarAccess.some(menu => menu.access === 'profile')) && (
                                                isEditing.OtherInfoEdit ? (<>
                                                    <td className="h-10 bg-white font-semibold pl-32 text-left">Blood Group</td>
                                                    <td className="h-10 pl-32 text-left">
                                                        <div>
                                                            <input className={`w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block p-1.5 ${err.mobile ? 'border-red-500 placeholder-red-700 mt-1 focus:ring-red-500  focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500'} `}
                                                                type='text'
                                                                value={data?.bloodGroup}
                                                                placeholder='Blood Group'
                                                                onChange={(e) => {
                                                                    handleChange('bloodGroup', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                        <div>{err.bloodGroup && <b className='text-red-500 text-xs mt-1'>{err.bloodGroup}</b>}</div>
                                                    </td></>
                                                ) : (<><td className="h-10 bg-white font-semibold pl-32 text-left">Blood Group</td>
                                                    <td className="h-10 pl-32 text-left">{data?.bloodGroup || '-'}</td></>
                                                ))
                                            }
                                        </tr>
                                        {(!userId || sidebarAccess.some(menu => menu.access === 'profile')) && (
                                            <tr className="bg-white divide-x ">

                                                {isEditing.OtherInfoEdit ? (<>
                                                    <td className="h-10 bg-white font-semibold pl-32 text-left">Current Address</td>
                                                    <td className="h-10 pl-32 text-left">
                                                        <div>
                                                            <textarea className={`w-48 block my-1 p-1.5 text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300  ${err.currentAddress ? 'border-red-500 placeholder-red-700 mt-1 focus:ring-red-500  focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500'} `}
                                                                value={data?.currentAddress}
                                                                placeholder='Current Address'
                                                                onChange={(e) => {
                                                                    handleChange('currentAddress', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                        <div>{err.currentAddress && <b className='text-red-500 text-xs mt-1'>{err.currentAddress}</b>}</div>
                                                    </td></>
                                                ) : (<><td className="h-10 bg-white font-semibold pl-32 text-left">Current Address</td>
                                                    <td className="h-10 pl-32 text-left">{data?.currentAddress || '-'}</td></>
                                                )}
                                            </tr>
                                        )}
                                        {(!userId || sidebarAccess.some(menu => menu.access === 'profile')) && (
                                            <tr className="bg-white divide-x ">
                                                {isEditing.OtherInfoEdit ? (<>
                                                    <td className="h-10 bg-white font-semibold pl-32 text-left">Permanent Address</td>
                                                    <td className="h-10 pl-32 text-left">
                                                        <div>
                                                            <textarea className={`w-48 block my-1 p-1.5 text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300 ${err.permanentAddress ? 'border-red-500 placeholder-red-700 mt-1 focus:ring-red-500  focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500'} `}
                                                                value={data?.permanentAddress}
                                                                placeholder='Permanent Address'
                                                                onChange={(e) => {
                                                                    handleChange('permanentAddress', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                        <div>{err.permanentAddress && <b className='text-red-500 text-xs mt-1'>{err.permanentAddress}</b>}</div>
                                                    </td></>
                                                ) : (<><td className="h-10 bg-white font-semibold pl-32 text-left">Permanent Address</td>
                                                    <td className="h-10 pl-32 text-left">{data?.permanentAddress || '-'}</td></>
                                                )}
                                            </tr>
                                        )
                                        }

                                        <tr className="bg-white divide-x ">
                                            {(!userId || sidebarAccess.some(menu => menu.access === 'profile')) && (
                                                isEditing.OtherInfoEdit ? (<>
                                                    <td className="h-20 bg-white font-semibold pl-32 text-left"><div className="mb-2 ">Emergency Contact Person </div><div>Emergency Contact Number </div></td>
                                                    <td className="h-20 pl-32 text-left">
                                                        <div>
                                                            <div className="mb-1">
                                                                <input className={`w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md p-1.5 ${err.emergencyContactPerson ? 'border-red-500 placeholder-red-700 mt-1 focus:ring-red-500  focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500'} `}
                                                                    type='text'
                                                                    value={data?.emergencyContactPerson}
                                                                    placeholder='Emergency Contact Person'
                                                                    onChange={(e) => {
                                                                        handleChange('emergencyContactPerson', e.target.value)
                                                                    }}
                                                                />
                                                            </div>
                                                            <div>{err.emergencyContactPerson && <b className='text-red-500 text-xs mt-1'>{err.emergencyContactPerson}</b>}</div>
                                                            <div>
                                                                <input className={`w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block p-1.5 ${err.personalContactNumber ? 'border-red-500 placeholder-red-700 mt-1 focus:ring-red-500  focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500'} `}
                                                                    type='text'
                                                                    value={data?.personalContactNumber}
                                                                    placeholder='Emergency Contact Number'
                                                                    onChange={(e) => {
                                                                        handleChange('personalContactNumber', e.target.value)
                                                                    }}
                                                                />
                                                            </div>
                                                            <div>{err.personalContactNumber && <b className='text-red-500 text-xs mt-1'>{err.personalContactNumber}</b>}</div>
                                                        </div>
                                                    </td></>

                                                ) : (<><td className="h-20 bg-white font-semibold pl-32 text-left"><div className="mb-2">Emergency Contact Person </div><div>Emergency Contact Number </div></td>
                                                    <td className="h-20 pl-32 text-left"><div className="mb-2">{data?.emergencyContactPerson || '-'}</div><div>{data?.personalContactNumber || '-'}</div></td></>
                                                ))
                                            }
                                        </tr>
                                        <tr className="bg-white divide-x ">
                                            {(!userId || sidebarAccess.some(menu => menu.access === 'profile')) && (
                                                isEditing.OtherInfoEdit ? (<>
                                                    <td className="h-10 bg-white font-semibold pl-32 text-left">Personal Email Id</td>
                                                    <td className="h-10 pl-32 text-left">
                                                        <div>
                                                            <input className={`w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block p-1.5 ${err.personalContactEmailId ? 'border-red-500 placeholder-red-700 mt-1 focus:ring-red-500  focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500'} `}
                                                                type='text'
                                                                value={data?.personalContactEmailId}
                                                                placeholder='Personal Email Id'
                                                                onChange={(e) => {
                                                                    handleChange('personalContactEmailId', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                        <div>{err.personalContactEmailId && <b className='text-red-500 text-xs mt-1'>{err.personalContactEmailId}</b>}</div>
                                                    </td></>
                                                ) : (<><td className="h-10 bg-white font-semibold pl-32 text-left">Personal Email Id</td>
                                                    <td className="h-10 pl-32 text-left">{data?.personalContactEmailId || '-'}</td></>
                                                ))
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )
                }
                {/* {isSecondFormOpen && (
          <PeopleUser
            close={closeSecondForm}
          />
        )} */}
                {/* {isProfilePhoto && ( */}
                <Modal isOpen={isProfilePhoto} onClose={() => { setisProfilePhoto(false) }}>
                    <PeopProfPhoto setisProfilePhoto={openProfilePhoto} funt={funt} />
                </Modal>
                {/* )} */}
                {isModal && (
                    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                        <div className="fixed inset-0 bg-white bg-opacity-75 transition-opacity"></div>

                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

                                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <h3 className="text-xl font-semibold leading-6 text-slate-800" id="modal-title">Send Reminder Email</h3>
                                                <hr className="my-4 border-gray-400" />
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">We will send a reminder email to this user, asking them to fill in their details. This will help payroll process payments accurately and on time.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <button type="button" onClick={sendReminder} className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-800 sm:ml-3 sm:w-auto">SEND-REMINDER</button>
                                        <button type="button" onClick={closeModel} className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 sm:mt-0 sm:w-auto">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {isGenerateDoc && (
                    <PeopGeneDoc generateDoc={generateDoc} />
                )}
                {isCreateTamplate && (
                    <CreateDocTemplate placeholder={"hello"} createTamplate={createTamplate} />
                )}
                {isopenDocuments && (
                    <DocumentsPage openDocuments={openDocuments} />
                )}

                <Modal isOpen={ResignationOpen} onClose={() => { setResignationOpen(false) }}>
                    <ResignationEmp onClose={setResignationOpen} />
                </Modal>



            </Main>

            <RightBar>
                {isrightBar && (
                    <div>
                        {/* <Button onClick={openProfilePhoto}  >
                            Upload Photo<h1 className="text-xs">upload photo from user</h1>
                        </Button> */}
                        {userId && (sidebarAccess.some(menu => menu.access === 'viewDocument')) &&
                            <Button onClick={openDocuments} >
                                View Documents<h1 className="text-xs">View or upload a document for user</h1>
                            </Button>
                        }
                        {(sidebarAccess.some(menu => menu.access === 'reminderEmail')) &&
                            <Button onClick={openModal}>
                                Send Reminder Email<h1 className="text-xs">(This user has not filled in all their details, give them a nudge)</h1>
                            </Button>
                        }
                        {(sidebarAccess.some(menu => menu.access === 'generateDocuments')) &&
                            <Button onClick={generateDoc}>Generate Document<h1 className="text-xs">Generate relieving letter, bonafide letter,etc for user</h1>
                                {/* <Link to={`/PeopGeneDoc/${data[0].id}`} className="link-style">

                            </Link> */}
                            </Button>
                        }
                        {userId && (
                            <Link to={`/leaveAttendance/${user}`}><Button>View Attendance<h1 className="text-xs">View and approve leave and attendance requests</h1></Button></Link>
                        )}
                        {(sidebarAccess.some(menu => menu.access === 'profile')) &&
                            <Link to={`/reimbursement/${user}`}><Button>View Reimbursements Loan and Advance Salary</Button>
                            </Link>
                        }
                        {/* <Button>View Payslips<h1 className="text-xs">view past payslip</h1></Button>
                        <Button>View Tax Deductions<h1 className="text-xs">See Details of employee's Tax Deduction</h1></Button>
                        <Button>View Reimbursements<h1 className="text-xs">View the status of past reimburesment requests, or add manully</h1></Button>
                        <Button>View Documents<h1 className="text-xs">View or upload a document for user</h1></Button>
                        <Button>View Notes<h1 className="text-xs">View or edit miscellaneous note for user</h1></Button>
                        <Button>View Journey<h1 className="text-xs">See user Journey at Edulab</h1></Button> */}
                    </div>
                )}
                {(sidebarAccess.some(menu => menu.access === 'generateDocuments') && isGenerateDoc && !isrightBar) &&
                    <Button onClick={createTamplate}>Add template<h1 className="text-xs">Create your own for pdf template</h1>
                        {/* <Link to={`/PeopGeneDoc/${data[0].id}`} className="link-style">

                            </Link> */}
                    </Button>
                }
            </RightBar>
        </Page>

    )
}

export default PeopleDetails