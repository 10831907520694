import React, { useEffect, useState } from "react";

import { Fullpage, Page } from '../../../components/Sidebar';
import moment from "moment";
import { ReportsApi } from "../../../services/reports";
import { AiOutlineEye } from "react-icons/ai";
import Pagination from "../../../components/Pagination";


interface Filter {
    viewValue: string,
    value: string
}

interface SearchCriteria {
    type: string;
    fromDate: string;
    toDate: string;
    status: string
}

export default function ReimbursementReportPage({ sidebar }: any) {
    const { getReimbursementData, ExportExcelSheet } = ReportsApi();

    const typeFilter: Filter[] = [{ viewValue: 'Travel', value: 'Travel' },
    { viewValue: 'Hotel', value: 'Hotel' }, { viewValue: 'Food', value: 'Food' },
    { viewValue: 'Fuel', value: 'Fuel' },
    { viewValue: 'Telephone', value: 'Telephone' },
    { viewValue: 'Medical', value: 'Medical' }];
    const currentYear = moment().year();
    const currentMonth = moment().month();
    const currentMonthDay = moment().daysInMonth().toString();
    const initalValues: SearchCriteria = {
        type: 'NULL',
        fromDate: moment([currentYear, currentMonth]).format('YYYY-MM-DD'),
        toDate: moment([currentYear, currentMonth, currentMonthDay]).format('YYYY-MM-DD'),
        status: 'NULL'
    };
    const [filterData, setFilterData] = useState<SearchCriteria>(initalValues)
    const [ReimbursementData, setReimbursementData] = useState([])
    const [currentItems, setCurrentItems] = useState<SearchCriteria[]>([]);

    // const [modalOpen, setModalOpen] = useState(false);
    // const [selectedFile, setSelectedFile] = useState({ file: '', fileUrl: '' });

    useEffect(() => {
        search();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // const handleView = (file: string, fileUrl: string, index: number) => {
    //     setSelectedFile({ file, fileUrl });

    //     // Open the modal
    //     setModalOpen(true);
    // };
    // const closeModal = () => {
    //     // Close the modal
    //     setModalOpen(false);
    // };
    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        setFilterData({ ...filterData, [name]: value });
    };

    const handleSelectStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        setFilterData({ ...filterData, [name]: value });
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFilterData({ ...filterData, [name]: value });
    };

    const search = async () => {
        const response = await getReimbursementData(filterData);
        setReimbursementData(response.data.data);
    }

    const downloadExcel = async () => {
        const response = await ExportExcelSheet(ReimbursementData, 'ReimbursementReport');

        const blob = new Blob([response?.data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'ReimbursementData.xlsx';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }

    return (
        <Page>
            <Fullpage close={sidebar}>
                <div className="w-4/5 min-h-svh mt-5 mb-5 bg-white rounded-xl shadow-lg">
                    <div className="w-full text-center p-6 m-6">
                        <p className="text-center font-bold text-2xl">REIMBURSEMENT REPORT</p>
                    </div>


                    <div className=" text-left shadow-md sm:rounded-lg p-3 m-5">
                        <div className="flex items-center justify-center space-x-10">
                            <div className="grid col-span-1 text-xl font-medium text-slate-800 dark:text-black">
                                <label className="text-left font-bold ml-1" htmlFor="type">Type:</label>
                                <select className="p-2 my-3 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" id="type" name="type" value={filterData.type} onChange={handleSelectChange}>
                                    <option value="">Select Type</option>
                                    {typeFilter.map((item: any) => (
                                        <option key={item.value} value={item.value}>
                                            {item.viewValue}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="grid col-span-1 text-xl font-medium text-slate-800 dark:text-black">
                                <label className="text-left font-bold ml-1" htmlFor="status" >Status:</label>
                                <select className="p-2 my-3 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" id="status" name="status" value={filterData.status} onChange={handleSelectStatusChange}>
                                    <option value="">Select Type</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Rejected">Rejected</option>
                                </select>
                            </div>

                            <div className="grid col-span-1 text-xl font-medium text-slate-800 dark:text-black">
                                <label className="text-left font-bold ml-1" htmlFor="fromDate">From Date:</label>
                                <input className="p-2 my-3 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" type="date" id="fromDate" name="fromDate" value={filterData.fromDate} onChange={handleInputChange} />

                            </div>
                            <div className="grid col-span-1 text-xl font-medium text-slate-800 dark:text-black">
                                <label className="text-left font-bold ml-1" htmlFor="toDate">To Date:</label>
                                <input className="p-2 my-3 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" type="date" id="toDate" name="toDate" value={filterData.toDate} onChange={handleInputChange} />

                            </div>
                            <div className="grid col-span-1 text-xl font-medium text-slate-800 dark:text-white">
                                <button className="p-3  my-3 mt-9 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={search} >Apply Filter</button>
                            </div>
                        </div>
                    </div>
                    <div className="text-right mt-10 mr-5">
                        <button className="p-3 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 mr-2 mt-5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={downloadExcel} >Export Excel</button>
                    </div>
                    <div className='overflow-auto px-5 py-3'>
                        <table className="table-fixed w-full rounded-md shadow-md bg-green-800 overflow-hidden">
                            <thead className=" dark:border-gray-400">
                                <tr>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-bold text-green-100 dark:text-gray-100 uppercase tracking-wider">Employee Id</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-bold text-green-100 dark:text-gray-100 uppercase tracking-wider">Date</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-bold text-green-100 dark:text-gray-100 uppercase tracking-wider">Name</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-bold text-green-100 dark:text-gray-100 uppercase tracking-wider">Reason</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-bold text-green-100 dark:text-gray-100 uppercase tracking-wider">Amount</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-bold text-green-100 dark:text-gray-100 uppercase tracking-wider">Type</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-bold text-green-100 dark:text-gray-100 uppercase tracking-wider">Document</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-bold text-green-100 dark:text-gray-100 uppercase tracking-wider">Status</th>
                                </tr>
                            </thead>
                            <tbody className="bg-gray-100 divide-y divide-gray-300 text-center">
                                {
                                    currentItems.length > 0 ? (
                                        currentItems.map((data: any, index: number) => (
                                            <tr key={index}>
                                                <td className="text-center  p-2">R-{data.userId}</td>
                                                <td className="text-center  p-2">{data.expenseDate}</td>
                                                <td className="text-center  p-2">{data.name}</td>
                                                <td className="text-center  p-2">{data.description}</td>
                                                <td className="text-center  p-2">{data.amount}</td>
                                                <td className="text-center  p-2">{data.type}</td>
                                                {/* <td className="text-center  p-2"><FaEye className='fill-red-600 ...' onClick={() => handleView(data?.file, data?.fileUrl, index)} /></td> */}
                                                <td className="text-center  p-2">
                                                    <div className="flex justify-center">
                                                        {data?.fileUrl ? (
                                                            <a href={data?.fileUrl} target="-blank" rel="noopener noreferrer">
                                                                <AiOutlineEye />
                                                            </a>
                                                        ) : null}
                                                    </div>
                                                </td>
                                                <td className="text-center  p-2">{data.status}</td>
                                            </tr>
                                        ))) : (
                                        <tr>
                                            <td colSpan={8} className="text-center  p-2" >No data available</td>
                                        </tr>
                                    )}


                            </tbody>
                        </table>
                        <Pagination isValue={ReimbursementData} setCurrentItems={setCurrentItems} />
                    </div>
                    {/* <div className=" shadow-md sm:rounded-lg w-full  p-3 m-2">
                        <div>
                            <button className="p-3 m-6 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={downloadExcel} >Export Excel</button>
                        </div>
                    </div> */}
                    {/* {modalOpen && (
                    <div className="fixed inset-0 z-50 overflow-hidden flex items-center justify-center">
                        <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
                        <div className="relative z-50 bg-transparent">
                            <div className="relative flex flex-col items-center mx-auto mt-10 p-6 bg-gray-100 rounded-lg shadow-xl">
                                {selectedFile.fileUrl && (
                                    <img src={selectedFile.fileUrl} alt={`Uploaded File`} className="max-w-lg" />
                                )}
                                <div className="flex justify-center mt-6">
                                    <button
                                        type="button"
                                        onClick={() => closeModal()}
                                        className="px-4 py-2 bg-gray-200 text-gray-800 font-semibold rounded-md hover:bg-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )} */}
                </div>
            </Fullpage>
            
        </Page>
    );
}
