import { useEffect, useState } from "react";
import { format } from 'date-fns';
import { CompanyApi } from "../../services/company";
import { Main, Page, RightBar } from "../../components/Sidebar";
import Button from "../../components/Button";
import Modal from "../../components/modal";
import CompanyDetails from "./component/companyDetails";
import DepartmentDetails from "./component/departmentDetails";
import { FaPenAlt, FaSave } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { toast } from "react-toastify";
// import RoleDetails from "./component/roleDetails";
// import AddRole from "../Designation/Components/addRole";
import AddLeaveType from './component/AddLeaveType';
import Holiday from "./component/Holiday";
import { LeaveAttendanceApi } from "../../services/LeaveAttendance";
import SalaryStructures from "./component/Payroll";
import { SalaryStructureApi } from "../../services/salaryStructure";
import Pagination from "../../components/Pagination";
import CustomLoader from '../../components/loader';

interface RoleAccess {
    access: string;
}


interface User {
    user: string;
    sidebar: boolean;
    sidebarAccess: RoleAccess[];
}

interface Department {
    id: number;
    departmentName: string;
    shift: string;
    startTime: string;
    endTime: string;
}

interface Role {
    id: number;
    roleName: string;
    paymentDetails: boolean;
    attendanceLeave: boolean;
    documents: boolean;
    reimbursement: boolean;
    payroll: boolean;
    paySlip: boolean;
    setting: boolean;
    runPayroll: boolean;
    report: boolean;
    leaveTaken: boolean;
    applyLeave: boolean;
    approvals: boolean;
    machineSetup: boolean;
    generateDocuments: boolean;
    reminderEmail: boolean;
    people: boolean;
    profile: boolean;
    performance: boolean;
    addUser: boolean;
    addManyUser: boolean;
    viewDocument: boolean;
    viewAttendance: boolean;
    applyReimbursement: boolean;
    applyReimbursementLoan: boolean;
    applyAdvanceSalary: boolean;
    viewReimbursement: boolean;
    editCompanyDetails: boolean;
    addRole: boolean;
    addDepartment: boolean;
    addLeave: boolean;
    addHoliday: boolean;
    salaryStructure: boolean;
    finance: boolean;
    request: boolean;
    designation: boolean
}

interface CompanyData {
    id: number,
    companyName: string,
    companyEmail: string,
    companyAddress: string,
    companyContactNumber: string,
    companyLogo: string,
    departments: Department[];
    role: Role[];
    roleMenusExists: boolean;
}
interface initialValues {
    id: number;
    leaveType: string;
    defaultValue: number;
    status: boolean;
    // monthlyIncrement: number;
    leaveMaxValue: number;
}

interface initialValuess {
    id: number;
    leaveType: string;
    defaultValue: string;
    monthlyIncrement: string;
    leaveMaxValue: string;
}

interface HolidayEntry {
    id: number
    day: string;
    date: string;
    reason: string;
}

interface SalaryStructure {
    id: number;
    salaryComponent: string;
    percentageAmount: number;
    // percentage: string;
    // taxable: string;
}


export default function SettingPage({ user, sidebar, sidebarAccess }: User) {
    const { getCompanyDetails, saveDepartmentDetails, getDwonloadExcel, postUploadExcel } = CompanyApi();
    const { updateLeave, updateHoliday, getAddLeave, fetchHoliday, deleteHolidayid } = LeaveAttendanceApi()
    const { getSalaryStruct, updateSalaryStruct, deleteSalaryStruct, deleteDepartment } = SalaryStructureApi();
    // const {  fetchHoliday} = LeaveAttendanceApi();
    const [company, setCompany] = useState<CompanyData>();
    const [addLeaves, setAddLeave] = useState<initialValues[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [btnDisable, setBtnDisable] = useState(false);
    // const [holiday, setHoliday] = useState<HolidayEntry[]>([]);
    // const [isEditing, setIsEditing] = useState(false);

    const [salData, setSalData] = useState<initialValues>({ id: NaN, leaveType: '', defaultValue: 0, leaveMaxValue: 0, status: true });
    const [holyData, setHolyData] = useState<HolidayEntry>({ id: NaN, day: '', date: '', reason: '' })
    const [salarryData, setSalarryData] = useState<SalaryStructure>({ id: NaN, salaryComponent: "", percentageAmount: 0 });
    const [leaveEdit, setLeaveEdit] = useState(false);
    const [holidayEdit, setHolidayEdit] = useState(false);
    const [departmentEdit, setDepartmentEdit] = useState(false);
    // const [roleEdit, setRoleEdit] = useState(false);
    const [companyDetails, setCompanyDetails] = useState(false);
    const [departmentDetails, setDepartmentDetails] = useState(false);
    const [isLeaveOpen, setOpenLeave] = useState(false);
    const [isSalaryStructure, setSalaryStructure] = useState(false);
    const [isAddholiday, setHoliday] = useState<HolidayEntry[]>([]);
    const [isSalary, setSalaryData] = useState<SalaryStructure[]>([]);
    const [salaryEdit, setSalaryEdit] = useState(false);
    // const [role, setRole] = useState(false);
    const [departmentData, setDepartmentData] = useState<Department>({ id: NaN, departmentName: '', shift: '', startTime: '', endTime: '' });
    // const [roleData, setRoleData] = useState<Role>({
    //     id: 0, roleName: '', paymentDetails: false, attendanceLeave: false,
    //     documents: false, reimbursement: false, payroll: false, paySlip:false, designation:false, setting: false, runPayroll: false, report: false,
    //     leaveTaken: false, applyLeave: false, approvals: false, machineSetup: false, generateDocuments: false,
    //     reminderEmail: false, people: false, profile: false, performance: false, addUser: false, finance: false,request:false,
    //      addManyUser: false, viewDocument: false, viewAttendance: false, applyReimbursement: false, applyReimbursementLoan: false, 
    //      applyAdvanceSalary: false, viewReimbursement: false, editCompanyDetails: false, addRole: false, addDepartment: false,
    //       addLeave: false, addHoliday: false, salaryStructure: false
    //     });
    const [validationErrors, setValidationErrors] = useState<Partial<Department>>({});
    const [validationErrorss, setValidationErrorss] = useState<Partial<initialValuess>>({});
    const [validationErrorsss, setValidationErrorsss] = useState<Partial<HolidayEntry>>({});
    const [validationErrorssss, setValidationErrorssss] = useState<Partial<SalaryStructure>>({});
    const [currentItems, setCurrentItems] = useState<HolidayEntry[]>([]);
    const [loading, setLoading] = useState(false);

    const getCompanyData = async () => {
        const response = await getCompanyDetails();
        setCompany(response.data.data);
    };
    const addLeave = async () => {
        const response = await getAddLeave();
        setAddLeave(response.data.data)
    }

    // const getHoliday: () => Promise<HolidayEntry[]> = async () => {
    //     // Some asynchronous code that returns a HolidayEntry[]
    //     // ...
    //   }

    async function fetchData() {
        const data = await fetchHoliday();
        setHoliday(data);
        // return data
    }

    async function fetchSalary() {
        const data = await getSalaryStruct();
        setSalaryData(data.data.data);
    }


    useEffect(() => {

        if (user === 'SuperAdmin') {
            getCompanyData();
            addLeave();
            fetchData();
            fetchSalary();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, sidebar,]);
    // roleEdit
    // , role


    const handleEditDept = (deptData: Department) => {
        setDepartmentEdit(true);
        setDepartmentData(deptData);
    };

    // const handleEditRole = (roleData: Role) => {
    //     setRoleEdit(true);
    //     setRoleData(roleData);
    // };

    const handleEditLeave = (addLeave: initialValues) => {
        setLeaveEdit(true);
        setSalData(addLeave);
    };

    const handleEditholiday = (addHoliday: HolidayEntry) => {
        setHolidayEdit(true);
        setHolyData(addHoliday);
    }

    const handleEditSalary = (addSalary: SalaryStructure) => {
        setSalaryEdit(true);
        setSalarryData(addSalary)
    }

    async function handleDelete(id: any) {
        let data = await deleteHolidayid(id);

        if (data.status === 200) {
            toast.success(data.message);
            updatedData()
        }
        if (data.status === 403) {
            toast.error(data.message);
        }

    }

    async function handleSalaryDelete(id: number) {
        let response = await deleteSalaryStruct(id);
        if (response.data.status === 200) {
            toast.success(response.data.message);
            fetchSalary();
        }
        else {
            toast.error(response.data.message)
        }

    }

    async function handleDepartMentDelete(id: number) {
        let response = await deleteDepartment(id);
        if (response.data.status === 200) {
            toast.success(response.data.message);
            getCompanyData();
        } else {
            toast.error(response.data.message)
        }
    }

    async function updatedData() {
        try {
            const data = await fetchHoliday();
            // const formattedHolidays = data.map((holiday: HolidayEntry) => ({
            //     ...holiday,
            //     date: format(new Date(holiday.date), 'dd/MM/yyyy'),
            // }));
            setHoliday(data);
        } catch (error) {
            console.error('Error fetching holiday data:', error);
        }
    }

    const validateDepartments = (): boolean => {
        const errors: Partial<Department> = {};

        //validation rules here
        if (departmentData?.departmentName === '') {
            errors.departmentName = 'Department name is required';
        }

        if (departmentData?.shift === '') {
            errors.shift = 'Shift is required';
        }

        if (departmentData?.startTime === '') {
            errors.startTime = 'Start time is required';
        }

        if (departmentData?.endTime === '') {
            errors.endTime = 'End time is required';
        }

        // Update the validation errors state
        setValidationErrors(errors);

        // Return true if there are no validation errors
        return Object.keys(errors).length === 0;
    };

    const validateLeaves = (): boolean => {
        const errors: Partial<initialValuess> = {};

        //validation rules here
        if (salData?.leaveType === '') {
            errors.leaveType = 'Leave Type is required';
        }

        if (salData?.defaultValue === undefined || salData?.defaultValue === null) {
            errors.defaultValue = 'Default leave is required';
        }

        // if (salData?.monthlyIncrement === undefined || salData?.monthlyIncrement === null) {
        //     errors.monthlyIncrement = 'Monthaly Increment is required';
        // }

        if (salData?.leaveMaxValue === undefined || salData?.leaveMaxValue === null) {
            errors.leaveMaxValue = 'Max Value is required';
        }

        // Update the validation errors state
        setValidationErrorss(errors);

        // Return true if there are no validation errors
        return Object.keys(errors).length === 0;
    };

    const validateHoly = (): boolean => {
        const errors: Partial<HolidayEntry> = {};

        //validation rules here
        if (holyData?.day === '') {
            errors.day = 'Day is required';
        }

        if (holyData?.date === '') {
            errors.date = 'Date is required';
        }

        if (holyData?.reason === '') {
            errors.reason = 'Holiday is required';
        }

        // Update the validation errors state
        setValidationErrorsss(errors);

        // Return true if there are no validation errors
        return Object.keys(errors).length === 0;
    };

    const validateSalary = (): boolean => {
        const errors: Partial<SalaryStructure> = {};

        //validation rules here
        if (salarryData?.salaryComponent === '') {
            errors.salaryComponent = 'Salary is required';
        }

        if (salarryData?.percentageAmount === 0) {
            errors.percentageAmount = NaN;
        }

        // Update the validation errors state
        setValidationErrorssss(errors);

        // Return true if there are no validation errors
        return Object.keys(errors).length === 0;
    };


    const handleSaveDept = async () => {
        if (validateDepartments()) {
            try {
                const response = await saveDepartmentDetails(departmentData);
                if (response.data.status === 200) {
                    toast.success(response.data.message);
                    setDepartmentEdit(false);
                    getCompanyData();
                }

            } catch (error) {
                // Handle any errors
                console.error('Error:', error);
            }
        }
    }


    const handleSaveLeave = async () => {
        if (validateLeaves()) {
            try {
                const response = await updateLeave(salData);
                if (response.data.status === 200) {
                    toast.success(response.data.message);
                    setLeaveEdit(false);
                    addLeave();
                }
                else if (response.data.status === 500) {
                    toast.error(response.data.message);
                }

            } catch (error) {
                // Handle any errors
                console.error('Error:', error);
            }
        }
    }
    // handleSaveholy

    const handleSaveholy = async () => {
        if (validateHoly()) {
            try {
                const response = await updateHoliday(holyData);
                if (response.data.status === 200) {
                    toast.success(response.data.message);
                    setHolidayEdit(false);
                    fetchData();
                }

            } catch (error) {
                // Handle any errors
                console.error('Error:', error);
            }
        }
    }

    const handleSalary = async () => {
        if (validateSalary()) {
            try {
                const response = await updateSalaryStruct(salarryData);
                if (response.data.status === 200) {
                    toast.success(response.data.message);
                    setSalaryEdit(false);
                    fetchSalary();
                } else if (response.data.status === 400) {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error', error)
            }
        }
    }

    const downloadExcel = async () => {
        try {
            const blobData = await getDwonloadExcel(); // Ensure this function is defined and returns the correct data
            const blob = new Blob([blobData], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Department Data.xlsx';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a); // Remove the <a> element after download
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading Department data: ", error);
        }
    };

    const uploadDeptExcel = async (event: any) => {
        const fileInput = event.target;
        const file = fileInput.files?.[0];

        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            setLoading(true);

            try {
                const response = await postUploadExcel(formData);
                if (response && response.data.status) {
                    response.data.status.forEach((errorObj: any) => {
                        if (errorObj.status === 200) {
                            toast.success(errorObj.message);
                        } else {
                            toast.error(errorObj.message);
                        }
                    });
                } else {
                    toast.error('An error occurred while submitting the form.');
                }
            } catch (error) {
                toast.error('An error occurred while submitting the form.');
                console.error('Error:', error);
            } finally {
                setTimeout(() => {
                    getCompanyData();
                    setLoading(false);
                }, 3000);
                // Reset the file input field so that the same file can be uploaded again
                fileInput.value = '';
            }
        }
    };

    return (
        <Page>
            <Main close={sidebar}>

                <div style={{ marginRight: '20px' }}>
                    <img src={company?.companyLogo} alt="" className="w-96 h-50 text-center"></img>
                    <span style={{ fontSize: "24px", fontWeight: "bold" }}>{company?.companyName}</span><br />
                    <span style={{ fontSize: "20px", fontWeight: "bold" }}>{company?.companyAddress}</span><br />
                    <span style={{ fontSize: "16px", fontWeight: "bold" }}>Email Address : {company?.companyEmail}</span><br />
                    <span style={{ fontSize: "16px", fontWeight: "bold" }}>Contact  : {company?.companyContactNumber}</span><br />
                </div>
                <div className="w-full">
                    <div className='shadow-md sm:rounded-lg  p-6 m-6'>
                        {loading && (
                            <CustomLoader size={150} colors={"green"} />
                        )}
                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>Department Details</span>
                        <div className="flex justify-end space-x-4">
                            <button onClick={downloadExcel} className="block px-4 py-2 rounded-lg bg-green-700  hover:bg-green-800 border-green-700 text-white">
                                Download Excel
                            </button>
                            <label htmlFor="file" className="block px-4 py-2 rounded-lg bg-green-700  hover:bg-green-800 border-green-700 text-white">
                                Upload Department
                                <input type="file" id="file" name="file" accept=".xlsx" className="hidden" onChange={uploadDeptExcel} />
                            </label>
                        </div>
                        <div className="overflow-y-auto max-h-80 mt-6">
                            <table border={1} className="table w-full bg-gray-100">
                                <thead className="bg-gradient-to-r bg-green-800 sticky top-0 z-10 overflow-hidden text-ellipsis">
                                    <tr>
                                        {/* ID Column */}
                                        <th className="py-3 px-6 border border-gray-300 text-white">ID</th>
                                        <th className="py-3 px-6 border border-gray-300 text-white">Name</th>
                                        <th className="py-3 px-6 border border-gray-300 text-white">Action</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white font-medium divide-y-2 max-h-64 overflow-y-auto">
                                    {company?.departments.map((data, index) => (
                                        <tr className='bg-white' key={index}> {/* Use index as key */}
                                            {departmentEdit && data.id === departmentData.id ? (
                                                <>
                                                    {/* Display index as ID */}
                                                    <td className='text-center text-black p-2'>{index + 1}</td>
                                                    <td className='text-center text-black p-0.5'>
                                                        <input
                                                            type="text"
                                                            value={departmentData.departmentName}
                                                            placeholder="Example: Sale"
                                                            onChange={(e) => setDepartmentData({ ...departmentData, departmentName: e.target.value })}
                                                            className="border border-gray-300 rounded-md px-8 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                        />

                                                        {validationErrors.departmentName && (
                                                            <span className="error" style={{ color: "red" }}>{validationErrors.departmentName}</span>
                                                        )}
                                                    </td>
                                                    <td className='text-black p-1 px-6 py-3'>
                                                        <div className="grid place-items-center">
                                                            <FaSave className='fill-red-600' onClick={() => handleSaveDept()} />
                                                        </div>
                                                    </td>
                                                </>
                                            ) : (
                                                <>
                                                    {/* Display index as ID */}
                                                    <td className='text-center text-black p-2'>{index + 1}</td>
                                                    <td className='text-center text-black p-2'>{data.departmentName}</td>
                                                    <td className='text-center text-black p-2'>
                                                        <div className="flex justify-center space-x-4">
                                                            <FaPenAlt className='fill-red-600 cursor-pointer' onClick={() => handleEditDept(data)} />
                                                            <AiOutlineDelete className='fill-red-600 cursor-pointer' onClick={() => handleDepartMentDelete(data.id)} />
                                                        </div>
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br />
                    <div className='shadow-md sm:rounded-lg  p-6 m-6'>
                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>Types of Leaves</span>
                        <div className="overflow-y-auto max-h-80 mt-6">
                            <table border={1} className="table w-full bg-gray-100" >
                                <thead className="bg-gradient-to-r bg-green-800 sticky top-0 z-10 overflow-hidden text-ellipsis">
                                    <tr>
                                        <th className="py-3 px-6 border border-gray-300 text-white">TYPE</th>
                                        <th className="py-3 px-6 border border-gray-300 text-white">DEFAULT LEAVE</th>
                                        {/* <th className="py-3 px-6 border border-gray-300 text-white">MONTHLY INCREMENT</th> */}
                                        <th className="py-3 px-6 border border-gray-300 text-white">MAX VALUE</th>
                                        <th className="py-3 px-6 border border-gray-300 text-white">ACTION</th>
                                    </tr>


                                </thead>
                                <tbody className="bg-white font-medium divide-y-2 max-h-64 overflow-y-auto">
                                    {
                                        addLeaves
                                            .filter(task => task.status === true).map((task) => (
                                                <tr key={task.id}>
                                                    {leaveEdit && task.id === salData.id ? (
                                                        <>
                                                            <td className=' text-center text-black p-0.5'>
                                                                <input type="text" value={salData.leaveType} placeholder="Example : Sale"
                                                                    onChange={(e) => setSalData({ ...salData, leaveType: e.target.value })} />

                                                                {validationErrorss.leaveType && (<span className="error" style={{ color: "red" }}>{validationErrorss.leaveType}</span>)}
                                                            </td>
                                                            <td className=' text-center text-black p-0.5'>
                                                                <input
                                                                    placeholder="Enter Default Value"
                                                                    type="text"
                                                                    pattern="[0-9]*"
                                                                    inputMode="numeric"
                                                                    value={salData.defaultValue === 0 ? "" : salData.defaultValue}
                                                                    onChange={(e) => {
                                                                        const value = e.target.value;
                                                                        const isValid = /^\d*\.?\d*$/.test(value); // Validate input as numeric
                                                                        if (isValid) {
                                                                            setSalData({ ...salData, defaultValue: parseFloat(e.target.value) });
                                                                        }
                                                                    }}
                                                                    className="px-4 py-2.5 mr-2 mb-2 h-9 m-1 rounded-lg"
                                                                    style={{ appearance: 'textfield', MozAppearance: 'textfield', WebkitAppearance: 'textfield', paddingRight: '20px' }}
                                                                /> <br />
                                                                {validationErrorss.defaultValue && (<span className="error" style={{ color: "red" }}>{validationErrorss.defaultValue}</span>)}
                                                            </td>
                                                            {/* <td className=' text-center text-black p-0.5'>
                                                    <input type="number" value={salData.monthlyIncrement} placeholder="Example : 10:30"
                                                        onChange={(e) => setSalData({ ...salData, monthlyIncrement: parseFloat(e.target.value) })} />
                                                    {validationErrorss.monthlyIncrement && (<span className="error" style={{ color: "red" }}>{validationErrorss.monthlyIncrement}</span>)}
                                                </td> */}
                                                            <td className=' text-center text-black p-0.5'>
                                                                <input
                                                                    placeholder="Enter Maximum Value"
                                                                    type="text"
                                                                    pattern="[0-9]*"
                                                                    inputMode="numeric"
                                                                    value={salData.leaveMaxValue === 0 ? "" : salData.leaveMaxValue}
                                                                    onChange={(e) => {
                                                                        const value = e.target.value;
                                                                        const isValid = /^\d*\.?\d*$/.test(value); // Validate input as numeric
                                                                        if (isValid) {
                                                                            // Parse the input value as a float and set it in the state
                                                                            setSalData({ ...salData, leaveMaxValue: parseFloat(value) });
                                                                        }
                                                                    }}
                                                                    className="px-4 py-2.5 mr-2 mb-2 h-9 m-1 rounded-lg"
                                                                    style={{ appearance: 'textfield', MozAppearance: 'textfield', WebkitAppearance: 'textfield', paddingRight: '20px' }}
                                                                />

                                                                {validationErrorss.leaveMaxValue && (<span className="error" style={{ color: "red" }}>{validationErrorss.leaveMaxValue}</span>)}
                                                            </td>
                                                            <td className=' text-center text-black p-1'>
                                                                <div className="grid place-items-center">
                                                                    <FaSave className='fill-red-600 ...' onClick={() => handleSaveLeave()} />
                                                                </div>
                                                            </td>
                                                        </>

                                                    ) : (

                                                        <>
                                                            <td className='text-center text-black p-2'>{task.leaveType}</td>
                                                            <td className='text-center text-black p-2'>{task.defaultValue}</td>
                                                            {/* <td className='text-center text-black p-2'>{task.monthlyIncrement}</td> */}
                                                            <td className='text-center text-black p-2'>{task.leaveMaxValue}</td>
                                                            <td className='text-center text-black p-2'>
                                                                <div className="grid place-items-center">
                                                                    <FaPenAlt className='fill-red-600 ...' onClick={() => handleEditLeave(task)} />
                                                                </div>
                                                            </td>
                                                        </>
                                                    )}
                                                </tr>
                                            ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br />
                    <div className='shadow-md sm:rounded-lg  p-6 m-6'>
                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>Holiday types</span>
                        <div className="overflow-y-auto max-h-80 mt-6">
                            <table border={1} className="table w-full bg-gray-100" >
                                <thead className="bg-gradient-to-r bg-green-800 sticky top-0 z-10 overflow-hidden text-ellipsis">
                                    <tr>
                                        <th className="p-2 px-2 border border-gray-300 text-white">DAY</th>
                                        <th className="py-3 px-6 border border-gray-300 text-white">DATE</th>
                                        <th className="py-3 px-6 border border-gray-300 text-white">HOLIDAY</th>
                                        <th className="py-3 px-4 border border-gray-300 text-white">ACTION</th>
                                    </tr>

                                </thead>
                                <tbody className="bg-white font-medium divide-y-2 max-h-64 overflow-y-auto">
                                    {currentItems.map((holiday) => (
                                        <tr key={holiday.id}>
                                            {holidayEdit && holiday.id === holyData.id ? (
                                                <>
                                                    <td className="p-2 w-36">
                                                        <input type="text" value={holyData.day} placeholder="Example : Sale" className=' text-center text-black w-36'
                                                            onChange={(e) => setHolyData({ ...holyData, day: e.target.value })} />
                                                        {validationErrorsss.day && (<span className="error" style={{ color: "red" }}>{validationErrorsss.day}</span>)}
                                                    </td>
                                                    <td className='p-2 w-36'>
                                                        <input type="date" value={holyData.date} placeholder="Example : Day" className='text-center text-black w-32'
                                                            onChange={(e) => setHolyData({ ...holyData, date: e.target.value })} /><br />
                                                        {validationErrorsss.date && (<span className="error" style={{ color: "red" }}>{validationErrorsss.date}</span>)}
                                                    </td>
                                                    <td className='p-2 w-36'>
                                                        <input type="text" value={holyData.reason} placeholder="Example : 10:30" className='text-center text-black w-36'
                                                            onChange={(e) => setHolyData({ ...holyData, reason: e.target.value })} />
                                                        {validationErrorsss.reason && (<span className="error" style={{ color: "red" }}>{validationErrorsss.reason}</span>)}
                                                    </td>

                                                    <td className='p-2 w-12'>
                                                        <div className="text-center text-black grid place-items-center p-2">
                                                            <FaSave className='fill-red-600 ...' onClick={() => handleSaveholy()} />
                                                        </div>
                                                    </td>
                                                    <td className=" text-center text-black w-12 p-2 ">
                                                        {/* <AiOutlineDelete className='fill-red-600 ...' onClick={() => handleDelete()} /> */}
                                                    </td>
                                                </>

                                            ) : (
                                                <>
                                                    <td className='text-left text-black p-2 px-10 w-40'>{holiday.day}</td>
                                                    <td className='text-center text-black p-2 w-40'>{format(new Date(holiday.date), 'dd/MM/yyyy')}</td>
                                                    <td className='text-left text-black p-2 px-6 w-40'>{holiday.reason}</td>
                                                    <td className='text-center text-black p-2 w-12'>
                                                        <div className="flex justify-center space-x-4"> {/* Use flex to arrange items side by side */}
                                                            <FaPenAlt className='fill-red-600 cursor-pointer' onClick={() => handleEditholiday(holiday)} />
                                                            <AiOutlineDelete className='fill-red-600 cursor-pointer' onClick={() => handleDelete(holiday.id)} />
                                                        </div>
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination isValue={isAddholiday} setCurrentItems={setCurrentItems} />
                    </div>
                    <div className='shadow-md sm:rounded-lg  p-6 m-6'>
                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>Salary Category</span>
                        <div className="overflow-y-auto max-h-80 mt-6">
                            <table border={1} className="table w-full bg-gray-100" >
                                <thead className="bg-gradient-to-r bg-green-800 sticky top-0 z-10 overflow-hidden text-ellipsis">
                                    <tr>
                                        <th className="py-3 px-6 border border-gray-300 text-white">Salary Category</th>
                                        <th className="py-3 px-6 border border-gray-300 text-white">Percentage Of Amount</th>
                                        <th className="py-3 px-4 border border-gray-300 text-white">ACTION</th>
                                        {/* <th className="py-3 px-4 border border-gray-300 text-white">ACTION</th> */}
                                    </tr>

                                </thead>
                                <tbody className="bg-white font-medium divide-y-2 max-h-64 overflow-y-auto">
                                    {isSalary.map((salary) => (
                                        <tr key={salary.id}>
                                            {salaryEdit && salary.id === salarryData.id ? (
                                                <>

                                                    <td className=' text-center text-black p-0.5'>
                                                        <input type="text" value={salarryData.salaryComponent} placeholder="Example : Sale"
                                                            onChange={(e) => setSalarryData({ ...salarryData, salaryComponent: e.target.value })} />
                                                        {validationErrorssss.salaryComponent && (<span className="error" style={{ color: "red" }}>{validationErrorssss.salaryComponent}</span>)}
                                                    </td>
                                                    <td className=' text-center text-black p-0.5'>
                                                        <input type="text" value={salarryData.percentageAmount} placeholder="Example : Day"
                                                            onChange={(e) => setSalarryData({ ...salarryData, percentageAmount: parseInt(e.target.value) || 0 })} /><br />
                                                        {validationErrorssss.percentageAmount && (<span className="error" style={{ color: "red" }}>{validationErrorssss.percentageAmount}</span>)}
                                                    </td>
                                                    <td className=' text-center text-black p-1'>
                                                        <div className="grid place-items-center">
                                                            <FaSave className='fill-red-600 ...' onClick={() => handleSalary()} />
                                                        </div>
                                                    </td>
                                                </>
                                            ) : (
                                                <>
                                                    <td className='text-center text-black p-2'>{salary.salaryComponent}</td>
                                                    <td className='text-center text-black p-2'>{salary.percentageAmount}</td>

                                                    <td className='text-center text-black p-2'>
                                                        <div className="flex justify-center space-x-4"> {/* Use flex to arrange items side by side */}
                                                            <FaPenAlt className='fill-red-600 cursor-pointer' onClick={() => handleEditSalary(salary)} />
                                                            <AiOutlineDelete className='fill-red-600 cursor-pointer' onClick={() => handleSalaryDelete(salary.id)} />
                                                        </div>
                                                    </td>
                                                    {/* <td className='text-center text-black p-2'>
                                                    <div className="grid place-items-center">
                                                    </div>
                                                </td> */}

                                                </>
                                            )}


                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Main>
            <Modal isOpen={companyDetails} onClose={() => { setCompanyDetails(false) }}>
                <CompanyDetails user={user} sidebar={sidebar} setCompanyDetails={setCompanyDetails} getCompanyData={getCompanyData} />
            </Modal>
            <Modal isOpen={departmentDetails} onClose={() => { setDepartmentDetails(false) }}>
                <DepartmentDetails getCompanyData={getCompanyData} user={user} sidebar={sidebar} setDepartmentDetails={setDepartmentDetails} />
            </Modal>
            <Modal isOpen={isLeaveOpen} onClose={() => { setOpenLeave(false) }}>
                <AddLeaveType addLeave={addLeave} setOpenLeave={setOpenLeave} />
            </Modal>
            <Modal isOpen={isSalaryStructure} onClose={() => { setSalaryStructure(false) }}>
                <SalaryStructures fetchSalary={fetchSalary} setSalaryStructure={setSalaryStructure} />
            </Modal>
            <Modal isOpen={isModalOpen} onClose={() => { setIsModalOpen(false) }}>
                <Holiday setIsModalOpen={setIsModalOpen} fetchData={fetchData} />
            </Modal>
            {/* <Modal isOpen={role} onClose={() => { setRole(false) }}>
                <AddRole user={user} sidebar={sidebar} setRole={setRole} />
            </Modal> */}
            {/* <Modal isOpen={roleEdit} onClose={() => { setRoleEdit(false) }}>
                <RoleDetails roleData={roleData} setRoleEdit={setRoleEdit} />
            </Modal> */}
            <RightBar>
                <div>
                    <Button onClick={() => { setCompanyDetails(true) }} >
                        Edit Company Details
                    </Button>

                    <Button onClick={() => { setDepartmentDetails(true) }} >
                        Add Department
                    </Button>

                    {/* <Button onClick={() => { setRole(true) }} >
                        Add Role
                    </Button> */}

                    <Button onClick={() => setOpenLeave(true)} >
                        Add Leave
                    </Button>

                    <Button onClick={() => { setIsModalOpen(true) }} >Add Holiday</Button>

                    <Button onClick={() => setSalaryStructure(true)} >
                        Salary Structure
                    </Button>

                </div>

            </RightBar>
        </Page>

    )
}

