import React, { useEffect, useState } from 'react';
import { PayableSalaryApi } from '../../services/payableSalary';
import { Main, Page, RightBar } from '../../components/Sidebar';
// import EditPayableSalaryModal from './modals/editPayableSalaryModal';
// import Modal from '../../components/modal';
import ConfirmButton from '../../components/confirmationBox';
import Pagination from '../../components/Pagination';

export interface PayrollData {
    id: number;
    employeeCode: string;
    basicpay: number;
    allowance: string;
    deductions: string;
    reimbursement: number;
    remarks: string;
    grosspay: number;
    userId?: any;
    monthYear: string;
    finalizeUsers?: boolean;
    advanceSalEMI: number;
    insurance: number;
    netPay: number;
    loanEMI: number;
    tds: number;
    pf: number;
    pt: number;
    totalAttendanceDays: number;
}

export default function RunPayroll({ user, sidebar, sidebarAccess }: any) {

    const { getPayableSalary, postFinalizeDate, postPayrollData } = PayableSalaryApi();
    const [saldata, getSalData] = useState<PayrollData[]>([]);
    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const [userId, setUserId] = useState<any>();
    // const [rowId, setRowId] = useState<any>();
    const [monthAndYear, setMonthAndYear] = useState<any>();
    const [selectedDate, setSelectedDate] = useState({ monthDate: "" });
    const [currentItems, setCurrentItems] = useState<PayrollData[]>([]);
    const [isEditing, setEditing] = useState(false);
    const [editedData, setEditedData] = useState<PayrollData>({
        id: NaN,
        employeeCode: "",
        basicpay: 0,
        allowance: "",
        deductions: "",
        reimbursement: 0,
        remarks: "",
        grosspay: 0,
        monthYear: "",
        advanceSalEMI: 0,
        insurance: 0,
        netPay: 0,
        loanEMI: 0,
        tds: 0,
        pf: 0,
        pt: 0,
        totalAttendanceDays: 0
    });

    const getData = async (selectedMonth: string | null = null) => {
        const response = await getPayableSalary(selectedMonth ? selectedMonth : selectedDate.monthDate); // this call when select handleSelectChange
        if (response.data.status === 200) {
            getSalData(response.data.data.payableSalaryData);
            setMonthAndYear(response.data.data.monthYearData);
        }
    }

    useEffect(() => {
        getData(selectedDate.monthDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate.monthDate]);

    const handleSelectChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedDate(prevState => ({ ...prevState, monthDate: e.target.value }));
    };

    const handleSaveDate = async (finalizeUsers: boolean) => {
        const response = await postFinalizeDate(selectedDate.monthDate, finalizeUsers); //this call when click on buttonn 
        if (response.data.status === 200) {
            getSalData(response.data.data);
        }
    }

    const handleEditClick = (payrollData: PayrollData) => {
        setEditing(true);
        // setIsModalOpen(true);
        setEditedData(payrollData);
        // setRowId(id);
    };

    const handleSaveClick = async () => {
        // Implement the logic to save the edited data
        setEditing(false);
        const response = await postPayrollData(editedData);
        if (response.data.status === 200) {
            getData();
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof PayrollData) => {
        if (editedData) {
            setEditedData({ ...editedData, [field]: e.target.value });
        }
    };

    return (
        <Page>
            <Main close={sidebar} >
                <div className="w-full p-4 min-h-svh mb-5 bg-white mt-5 rounded-xl shadow-lg" >
                    <p className='text-left text-slate-800 font-bold text-2xl mb-4'>Run Payroll</p>
                    <hr className="my-4 border-t-2 border-gray-300" />
                    <div className=' overflow-y-auto shadow-md sm:rounded-lg w-7/6'>
                        <table border={1} className="table-auto w-full">
                            <thead className="text-white bg-green-800 font-semibold">
                                <tr>
                                    <th className="text-center px-4 sm:px-6 py-3 ">Employee Code</th>
                                    <th className="text-center px-4 sm:px-6 py-3 ">Working Days</th>
                                    <th className="text-center px-4 sm:px-6 py-3 ">Monthly CTC</th>
                                    <th className="text-center px-4 sm:px-6 py-3 ">PT</th>
                                    <th className="text-center px-4 sm:px-6 py-3 ">PF</th>
                                    <th className="text-center px-4 sm:px-6 py-3 ">Gross Pay</th>
                                    <th className="text-center px-4 sm:px-6 py-3 ">TDS</th>
                                    <th className="text-center px-4 sm:px-6 py-3 ">Loan EMI</th>
                                    <th className="text-center px-4 sm:px-6 py-3 ">Advance Salary EMI</th>
                                    {/* <th className="text-center px-4 sm:px-6 py-3 ">Additional Bonuses</th> */}
                                    {/* <th className="text-center px-4 sm:px-6 py-3 ">Additional Deduction</th> */}
                                    <th className="text-center px-4 sm:px-6 py-3 ">Additional Reimbursements</th>
                                    <th className="text-center px-4 sm:px-6 py-3 ">Net Payable</th>
                                    <th className="text-center px-4 sm:px-6 py-3 ">Action</th>
                                </tr>
                            </thead>
                            <tbody className="bg-gray-100 font-semibold divide-y-2 max-h-64 overflow-y-auto">
                                {currentItems.length > 0 ? (
                                    currentItems && currentItems.map((data: PayrollData) => (
                                        <tr key={data.id} className='bg-gray-100'>
                                            {isEditing && editedData?.id === data.id ? (
                                                <>
                                                    <td className='text-center text-black p-2'>
                                                        <span className="text-black"> {editedData.employeeCode}</span>
                                                    </td>
                                                    <td className='text-center text-black p-2'>
                                                        <input
                                                            type="text"
                                                            pattern="[0-9]*"
                                                            inputMode="numeric"
                                                            value={editedData.totalAttendanceDays}
                                                            onChange={(e) => handleInputChange(e, 'totalAttendanceDays')}
                                                        />
                                                    </td>
                                                    <td className='text-center text-black p-2'>
                                                        <input
                                                            type="text"
                                                            pattern="[0-9]*"
                                                            inputMode="numeric"
                                                            value={editedData.basicpay}
                                                            onChange={(e) => handleInputChange(e, 'basicpay')}
                                                        />
                                                    </td>
                                                    <td className='text-center text-black p-2'>
                                                        {/* <input
                                                            type="text"
                                                            pattern="[0-9]*"
                                                            inputMode="numeric"
                                                            value={editedData.pt}
                                                            onChange={(e) => handleInputChange(e, 'pt')}
                                                        /> */}
                                                        <span className="text-black"> {editedData.pt}</span>
                                                    </td>
                                                    <td className='text-center text-black p-2'>
                                                        <input
                                                            type="text"
                                                            pattern="[0-9]*"
                                                            inputMode="numeric"
                                                            value={editedData.pf}
                                                            onChange={(e) => handleInputChange(e, 'pf')}
                                                        />
                                                    </td>
                                                    <td className='text-center text-black p-2'>
                                                        <input
                                                            type="text"
                                                            pattern="[0-9]*"
                                                            inputMode="numeric"
                                                            value={editedData.grosspay}
                                                            onChange={(e) => handleInputChange(e, 'grosspay')}
                                                        />
                                                    </td>
                                                    <td className='text-center text-black p-2'>
                                                        <input
                                                            type="text"
                                                            pattern="[0-9]*"
                                                            inputMode="numeric"
                                                            value={editedData.tds}
                                                            onChange={(e) => handleInputChange(e, 'tds')}
                                                        />
                                                    </td>
                                                    <td className='text-center text-black p-2'>
                                                        <input
                                                            type="text"
                                                            pattern="[0-9]*"
                                                            inputMode="numeric"
                                                            value={editedData.loanEMI}
                                                            onChange={(e) => handleInputChange(e, 'loanEMI')}
                                                        />
                                                    </td>
                                                    <td className='text-center text-black p-2'>
                                                        <input
                                                            type="text"
                                                            pattern="[0-9]*"
                                                            inputMode="numeric"
                                                            value={editedData.advanceSalEMI}
                                                            onChange={(e) => handleInputChange(e, 'advanceSalEMI')}
                                                        />
                                                    </td>
                                                    {/* <td className='text-center text-black p-2'>
                                                        <input
                                                            type="text"
                                                            pattern="[0-9]*"
                                                            inputMode="numeric"
                                                            value={editedData.allowance}
                                                            onChange={(e) => handleInputChange(e, 'allowance')}
                                                        />
                                                    </td>
                                                    <td className='text-center text-black p-2'>
                                                        <input
                                                            type="text"
                                                            pattern="[0-9]*"
                                                            inputMode="numeric"
                                                            value={editedData.deductions}
                                                            onChange={(e) => handleInputChange(e, 'deductions')}
                                                        />
                                                    </td> */}
                                                    <td className='text-center text-black p-2'>
                                                        <input
                                                            type="text"
                                                            pattern="[0-9]*"
                                                            inputMode="numeric"
                                                            value={editedData.reimbursement}
                                                            onChange={(e) => handleInputChange(e, 'reimbursement')}
                                                        />
                                                    </td>
                                                    <td className='text-center text-black p-2'>
                                                        <input
                                                            type="text"
                                                            pattern="[0-9]*"
                                                            inputMode="numeric"
                                                            value={editedData.netPay}
                                                            onChange={(e) => handleInputChange(e, 'netPay')}
                                                        />
                                                    </td>
                                                    <td className='text-center text-black p-2'>
                                                        <button
                                                            className='bg-blue-500 hover:bg-blue-800 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2'
                                                            onClick={() => handleSaveClick()}
                                                        >
                                                            Save
                                                        </button>
                                                    </td>
                                                </>
                                            ) : (
                                                <>
                                                    <td className='text-center text-black p-2'>{data.employeeCode}</td>
                                                    <td className='text-center text-black p-2'>{data.totalAttendanceDays}</td>
                                                    <td className='text-center text-black p-2'>{data.basicpay}</td>
                                                    <td className='text-center text-black p-2'>{data.pt}</td>
                                                    <td className='text-center text-black p-2'>{data.pf}</td>
                                                    <td className='text-center text-black p-2'>{data.grosspay}</td>
                                                    <td className='text-center text-black p-2'>{data.tds}</td>
                                                    <td className='text-center text-black p-2'>{data.loanEMI}</td>
                                                    <td className='text-center text-black p-2'>{data.advanceSalEMI}</td>
                                                    {/* <td className='text-center text-black p-2'>{data.allowance}</td>
                                                    <td className='text-center text-black p-2'>{data.deductions}</td> */}
                                                    <td className='text-center text-black p-2'>{data.reimbursement}</td>
                                                    <td className='text-center text-black p-2'>{data.netPay}</td>
                                                    <td className='text-center text-black p-2'>
                                                        {data.finalizeUsers ? (
                                                            <span className='bg-gray-400 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2'>Final</span>
                                                        ) : (
                                                            <button
                                                                className='bg-green-500 hover:bg-green-800 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2'
                                                                onClick={() => handleEditClick(data)}
                                                            >
                                                                Edit
                                                            </button>
                                                        )}
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td className="text-center border border-gray-400 p-2" colSpan={12}>No data available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <br />
                    <Pagination isValue={saldata} setCurrentItems={setCurrentItems} />
                </div>
                {/* <Modal isOpen={isModalOpen} onClose={() => { setIsModalOpen(false); }}>
                    <EditPayableSalaryModal id={rowId} userId={userId} getMainData={getData} />
                </Modal> */}
            </Main>
            <RightBar>
                <div>
                    <label className="block text-black text-md font-bold mb-2">Select Payroll Month :</label>
                    <select
                        name="date"
                        value={selectedDate?.monthDate}
                        onChange={handleSelectChange}
                        required
                        className="w-full px-3 py-2 border rounded focus:outline-none focus:shadow-outline"
                    >
                        <option value="" disabled>Select Month</option>
                        {monthAndYear && monthAndYear.map((data: any, index: any) => (
                            <option key={index} value={data.monthYear}>
                                {data.monthYear}
                            </option>
                        ))}
                    </select>
                    <br /><br />
                    {selectedDate && selectedDate.monthDate ? (
                        <>
                            {saldata.some(data => data.finalizeUsers) ? ( // Check if any data has finalizeUsers as true
                                <>
                                    <ConfirmButton onConfirm={() => { handleSaveDate(false) }} message="Your payroll already has been finalized. If you make changes now, then finalization will need to be done again.">
                                        <h1 className="text-lg">Make Changes In Payroll</h1>
                                    </ConfirmButton><br />
                                    <ConfirmButton onConfirm={() => { handleSaveDate(true) }} message="By finalizing the payroll, you are freezing your payroll data with all the current additions, deductions and reimbursements.">
                                        <h1 className="text-lg">Finalize Payroll</h1>
                                    </ConfirmButton>
                                </>

                            ) : (
                                <ConfirmButton onConfirm={() => { handleSaveDate(true) }} message="By finalizing the payroll, you are freezing your payroll data with all the current additions, deductions and reimbursements.">
                                    <h1 className="text-lg">Process Payroll</h1>
                                </ConfirmButton> // Display ConfirmButton otherwise
                            )}
                        </>
                    ) : (
                        <h1 className="text-white font-bold text-md p-4 rounded-lg bg-[#321FDB]">Please select a month to proceed.</h1>
                    )}
                </div>
            </RightBar>
        </Page>
    );
}