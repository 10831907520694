import React, { useEffect, useRef, useState } from "react";

import { Fullpage, Page } from '../../../components/Sidebar';
import { ProfileApi } from "../../../services/Profile";
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";

interface ResponseType {
    name: string,
    userId: number,
    employeeCode: string,
    approvedby: string,
    dateOfResignation: string,
    remarks: string,
    lastWorkingDay: string,
    reasonForResignation: string
}

interface FormData {
    name: string;
    email: string;
    isTeacher: string;
    dateOfResignation: string;
    lastWorkingDay: string;
    remarks: string
    reasonForResignation: string;
    comment: string;
}

interface errFormData {
    dateOfResignation: string,
    lastWorkingDay: string,
    remarks: string
}

// const validationSchema = Yup.object().shape({
//     dateOfResignation: Yup.string().required('Date of Resignation is required'),
//     lastWorkingDay: Yup.string().required('Date of last working day is required'),
//     remarks: Yup.string(),
// });

const initial: errFormData = { dateOfResignation: "", lastWorkingDay: "", remarks: "" }


export default function ResignationReportPage({ sidebar }: any) {
    const { getAllResignation, getResignationByEmpCode, getAprovedResignation } = ProfileApi();
    const run = useRef(false);
    const [data, setdata] = useState<ResponseType[]>([]);
    const [aprovedData, setAprovedData] = useState<ResponseType[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [err, setErr] = useState<errFormData>(initial);
    const [validation, setValidation] = useState(false);
    const [formData, setFormData] = useState<FormData>({ name: "", email: "", lastWorkingDay: "", isTeacher: "", comment: "", dateOfResignation: "", reasonForResignation: "", remarks: "" });
    const [modal, setModal] = useState(false)
    const [currentItems, setCurrentItems] = useState<ResponseType[]>([]);

    // Define function to handle radio button change
    const handleRadioChange = (employeeCode: string) => {
        setSelectedUserId(employeeCode);
    };
    useEffect(() => {
        if (run.current) return;
        run.current = true;
        Resignation();
        AprovedData();
    })

    const Resignation = async () => {
        const data = await getAllResignation();
        setdata(data)
    }

    const AprovedData = async () => {
        const Aproved = await getAprovedResignation();
        setAprovedData(Aproved)
    }

    const EmployeData = async (selectedUserId: string | null) => {
        if (selectedUserId !== null) {
            const data = await getResignationByEmpCode(selectedUserId);
            setFormData(data)
        }
    }

    const openModal = () => {
        EmployeData(selectedUserId);
        setModal(true);
    }

    const handleChange = async (field: string, value: string) => {
        try {
            setErr((prevErrors) => ({
                ...prevErrors,
                [field]: '',
            }));

            setFormData((data) => ({
                ...data,
                [field]: value,
            }));

            // await validationSchema.validate({ ...formData, [field]: value }, { abortEarly: false });

        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors = error.inner.reduce((acc, { path, message }) => ({
                    ...acc,
                    [path as keyof errFormData]: message,
                }), {} as Partial<errFormData>);
                if (validation === true) {
                    setErr(prevErrors => ({ ...prevErrors, ...errors }));
                }
            }
        }
    };

    const updateResignationData = async (e: { preventDefault: () => void; }, status: string) => {
        try {
            e.preventDefault();
            // await validationSchema.validate(formData, { abortEarly: false });
            // if (JSON.stringify(err) === JSON.stringify(initial)) {
            //     const updated = { ...formData, status: status }
            //     // const data = await updateResignation(updated);
            //     // if (data.status === 200) {
            //     //     toast.success(data.message);
            //     //     Resignation();
            //     //     AprovedData();
            //     //     setModal(false);

            //     // }
            // }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors = error.inner.reduce((acc, { path, message }) => ({
                    ...acc,
                    [path as keyof errFormData]: message,
                }), {} as Partial<errFormData>);

                setErr(prevErrors => ({ ...prevErrors, ...errors }));
                setValidation(true);
            }
        }

    };

    return (
        <Page>
            <Fullpage close={sidebar} >
                <>
                    <div className="w-5/6 bg-white rounded-lg mt-5 p-5 shadow-lg">
                        <div className="bg-gray-100 p-5">
                            <div >
                                <p className='text-left'>Report|Resignation</p>
                            </div>
                            <div >
                                <p className='mb-5 text-left text-3xl'>Pending Resignations</p>
                            </div>
                            <div>
                                {data.length > 0 ? (
                                    <div>
                                        <table className=" transition-transform duration-200 min-w-max w-full">
                                            <thead className="border-b-2 border-solid border-gray-300">
                                                <tr>
                                                    <th scope="col" className="p-4 font-semibold  transition-colors duration-200 hover:text-teal-400 focus:text-teal-400">

                                                    </th>
                                                    <th scope="col" className="p-4 font-semibold  transition-colors duration-200 hover:text-teal-400 focus:text-teal-400">
                                                        Emp Code
                                                    </th>
                                                    <th scope="col" className="p-4 font-semibold  transition-colors duration-200 hover:text-teal-400 focus:text-teal-400">
                                                        Name
                                                    </th>

                                                    <th scope="col" className="p-4 font-semibold  transition-colors duration-200 hover:text-teal-400 focus:text-teal-400">
                                                        Resignation Date
                                                    </th>
                                                    <th scope="col" className="p-4 font-semibold  transition-colors duration-200 hover:text-teal-400 focus:text-teal-400">
                                                        Last Working Day
                                                    </th>
                                                    <th scope="col" className="p-4 font-semibold  transition-colors duration-200 hover:text-teal-400 focus:text-teal-400">
                                                        Reason
                                                    </th>
                                                    <th scope="col" className="p-4 font-semibold  transition-colors duration-200 hover:text-teal-400 focus:text-teal-400">
                                                        Remarks
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((data, index) => (
                                                    <tr className={`border-b-2 border-solid border-opacity-50 transition-background-color duration-200 hover:bg-opacity-25 hover:border-opacity-100 ${index % 6 === 0
                                                        ? 'border-teal-200 hover:bg-teal-50'
                                                        : index % 6 === 1
                                                            ? 'border-cyan-200 hover:bg-cyan-50'
                                                            : index % 6 === 2
                                                                ? 'border-red-200 hover:bg-red-50'
                                                                : index % 6 === 3
                                                                    ? 'border-orange-200 hover:bg-orange-50'
                                                                    : index % 6 === 4
                                                                        ? 'border-yellow-200 hover:bg-yellow-50'
                                                                        : 'border-cyan-200 hover:bg-cyan-50'
                                                        }`}
                                                    >
                                                        <td className="px-3 py-2 max-w-xs">
                                                            <input type="radio" name="selectedUser" value={data.employeeCode} onChange={() => handleRadioChange(data.employeeCode)} />
                                                        </td>
                                                        <td className="px-3 py-2 max-w-xs">{data.employeeCode}</td>
                                                        <td className="px-3 py-2 max-w-xs">{data.name}</td>
                                                        <td className="px-3 py-2 max-w-xs">{data.dateOfResignation}</td>
                                                        <td className="px-3 py-2 max-w-xs">{data.lastWorkingDay}</td>
                                                        <td className="px-3 py-2 max-w-xs">{data.reasonForResignation}</td>
                                                        <td className="px-3 py-2 max-w-xs">{data.remarks}</td>
                                                        {/* <ToastContainer /> */}
                                                    </tr>
                                                ))}                            </tbody>

                                        </table>
                                        <br />
                                        <div className=" mb-5 text-left">
                                            <button type="button" onClick={openModal} className="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Review Resignation</button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="mb-5 text-gray-800 font-semibold text-lg ">
                                        <h1>You have no pending resignations.</h1>
                                    </div>
                                )}
                                {modal && (
                                    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"></div>

                                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

                                                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">

                                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                            <h3 className="text-xl font-semibold leading-6 text-gray-900" id="modal-title">Update Resignation Request</h3>
                                                            <hr className="my-4 border-gray-400" />

                                                            <label className="block mb-2 text-lg text-left  text-black-400">
                                                                Date of Submitting Resignation
                                                            </label>
                                                            <input
                                                                type="date"
                                                                name="dateOfResignation"
                                                                value={formData.dateOfResignation}
                                                                onChange={(e) => {
                                                                    handleChange('dateOfResignation', e.target.value)
                                                                }}
                                                                className="w-full p-1 mb-1 border border-gray-300 "
                                                            />
                                                            {err.dateOfResignation && <div className='text-red-500 text-left text-xs '>{err.dateOfResignation}</div>}
                                                            <br />
                                                            <br />
                                                            <label className="block mb-2 text-lg text-left  text-black-400">
                                                                Last Working Day
                                                            </label>
                                                            <input
                                                                type="date"
                                                                name="lastWorkingDay"
                                                                value={formData.lastWorkingDay}
                                                                onChange={(e) => {
                                                                    handleChange('lastWorkingDay', e.target.value)
                                                                }}
                                                                className="w-full p-1 mb-1 border border-gray-300 "
                                                            />
                                                            {err.lastWorkingDay && <div className='text-red-500 text-left text-xs '>{err.lastWorkingDay}</div>}
                                                            <br />
                                                            <br />
                                                            <label className="block mb-2 text-lg text-left  text-black-400">
                                                                Remarks
                                                            </label>
                                                            <textarea
                                                                name="remarks"
                                                                value={formData.remarks}
                                                                onChange={(e) => {
                                                                    handleChange('remarks', e.target.value)
                                                                }}
                                                                className="w-full p-1 mb-1 border border-gray-300 "
                                                            ></textarea>
                                                            {err.remarks && <div className='text-red-500 text-left text-xs '>{err.remarks}</div>}
                                                        </div>
                                                        <br />
                                                        <div className="flex justify-between">
                                                            {/* <div className="">
                                                            <button type="button" className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-800 sm:ml-3 sm:w-auto">UPDATE</button>
                                                        </div> */}
                                                            <div className="mr-2 ">
                                                                <button type="button" className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-800 sm:ml-3 sm:w-auto" onClick={(e) => { updateResignationData(e, "Approved") }} >APPROVE</button>
                                                                <button type="button" className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 sm:mt-0 sm:w-auto" onClick={(e) => { updateResignationData(e, "Rejected") }}>REJECT</button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )}
                            </div>
                        </div>

                        <div className="bg-gray-100 p-5">
                            <div >
                                <p className='text-left text-3xl'>Approved Resignations</p>
                            </div>
                            <div>
                                <table className=" transition-transform duration-200 min-w-max w-full bg-white">
                                    <thead className="border-b-2 border-solid border-gray-300">
                                        <tr>
                                            <th scope="col" className="p-4 font-semibold  transition-colors duration-200 hover:text-teal-400 focus:text-teal-400">

                                            </th>
                                            <th scope="col" className="p-4 font-semibold  transition-colors duration-200 hover:text-teal-400 focus:text-teal-400">
                                                Emp Code
                                            </th>
                                            <th scope="col" className="p-4 font-semibold  transition-colors duration-200 hover:text-teal-400 focus:text-teal-400">
                                                Name
                                            </th>
                                            <th scope="col" className="p-4 font-semibold  transition-colors duration-200 hover:text-teal-400 focus:text-teal-400">
                                                Last Working Day
                                            </th>
                                            <th scope="col" className="p-4 font-semibold  transition-colors duration-200 hover:text-teal-400 focus:text-teal-400">
                                                Reason
                                            </th>
                                            <th scope="col" className="p-4 font-semibold  transition-colors duration-200 hover:text-teal-400 focus:text-teal-400">
                                                Remarks
                                            </th>
                                            <th scope="col" className="p-4 font-semibold  transition-colors duration-200 hover:text-teal-400 focus:text-teal-400">
                                                Approved By
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentItems.map((data, index) => (
                                            <tr className={`border-b-2 border-solid border-opacity-50 transition-background-color duration-200 hover:bg-opacity-25 hover:border-opacity-100 ${index % 6 === 0
                                                ? 'border-teal-200 hover:bg-teal-50'
                                                : index % 6 === 1
                                                    ? 'border-cyan-200 hover:bg-cyan-50'
                                                    : index % 6 === 2
                                                        ? 'border-red-200 hover:bg-red-50'
                                                        : index % 6 === 3
                                                            ? 'border-orange-200 hover:bg-orange-50'
                                                            : index % 6 === 4
                                                                ? 'border-yellow-200 hover:bg-yellow-50'
                                                                : 'border-cyan-200 hover:bg-cyan-50'
                                                }`}
                                            >
                                                <td className="px-3 py-2 max-w-xs">
                                                    <input type="radio" name="selectedUser" value={data.employeeCode} onChange={() => handleRadioChange(data.employeeCode)} />
                                                </td>
                                                <td className="px-3 py-2 max-w-xs">{data.employeeCode}</td>
                                                <td className="px-3 py-2 max-w-xs">{data.name}</td>
                                                <td className="px-3 py-2 max-w-xs">{data.lastWorkingDay}</td>
                                                <td className="px-3 py-2 max-w-xs">{data.reasonForResignation}</td>
                                                <td className="px-3 py-2 max-w-xs">{data.remarks}</td>
                                                <td className="px-3 py-2 max-w-xs">{data.approvedby}</td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                                <br />
                                <div className="text-left">
                                    <Link to="/resignations/fnfsettlement" className="link-style">
                                        <button type="button" className="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
                                            Process Final Settlement
                                        </button>
                                    </Link>
                                </div>
                                <Pagination isValue={aprovedData} setCurrentItems={setCurrentItems} />

                            </div>
                        </div>
                    </div>
                </>
            </Fullpage>

        </Page>
    );
}
