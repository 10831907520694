
import { Fullpage, Page } from '../../components/Sidebar';
import { UsersApi } from '../../services/users';
import { useEffect, useState } from 'react';
import { FaCheckToSlot } from "react-icons/fa6";
import { GoTab } from "react-icons/go";
import TabAccess from './Components/TabAccess';
import ButtonAccess from './Components/ButtonAccess';
import Modal from '../../components/modal';
import AddRole from './Components/addRole';
import Pagination from '../../components/Pagination';
import { GrFormUpload } from "react-icons/gr";
import { toast } from 'react-toastify';
import CustomLoader from '../../components/loader';

export default function Designation({ user, sidebar }: any) {
    const { getAllJobRole, templateDownload, uploadExcelFile, downloadExitData } = UsersApi();
    const [isdesignation, setDesignation] = useState<any>([]);
    const [currentItems, setCurrentItems] = useState<any>([]);
    const [isDesignationData, setDesignationData] = useState(true);
    const [isOpenDesignation, setOpenDesignation] = useState(false);
    const [isTabAccess, setTabAccess] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isAddRole, setAddRole] = useState(true);
    const [designationId, setSelectedDesignationId] = useState<string>("");
    const [designation, setisDesignation] = useState<string>("")
    const [role, setRole] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filterInput, setFilterInput] = useState({ designation: '' });
    const [filterData, setFilterData] = useState<[]>([]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        filteredData(filterInput.designation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isdesignation, filterInput.designation]);


    const fetchData = async () => {
        const designationData = await getAllJobRole();
        setDesignation(designationData.data.data);
    };


    const handleClick = (designationId: string, designation: string) => {
        setDesignationData(false);
        setOpenDesignation(true);
        setTabAccess(false);
        setAddRole(false);
        setSelectedDesignationId(designationId);
        setisDesignation(designation)
    };

    const openTab = (designationId: string, designation: string) => {
        setDesignationData(false);
        setOpenDesignation(false);
        setTabAccess(true);
        setAddRole(false);
        setSelectedDesignationId(designationId);
        setisDesignation(designation)
    };


    const closeButtonAccess = () => {
        setDesignationData(true);
        setOpenDesignation(false);
        setAddRole(true);
    }

    const closeTabAccess = () => {
        setDesignationData(true);
        setOpenDesignation(false);
        setTabAccess(false);
        setAddRole(true);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handledownload = async () => {
        try {
            const blobData = await templateDownload();
            const blob = new Blob([blobData], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'designation_excel.xlsx';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading Excel file:', error);
        }
    };

    const ExitDataDownload = async () => {
        try {
            const blobData = await downloadExitData();
            const blob = new Blob([blobData], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'ExistingData.xlsx';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a); // Remove the <a> element after download
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading Excel file:', error);
        }
    };


    const handleFileChange = async (event: any) => {
        const file = event.target.files?.[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            setLoading(true);
            try {
                const res = await uploadExcelFile(formData);
                if (res && res.data.status === 200) {
                    toast.success(res.data.message);
                } else if (res.data && res.data.status === 409 && res.data.message) {
                    res.message.forEach((errorObj: any) => {
                        toast.error(errorObj.message);
                    });
                } else {
                    toast.error('An error occurred while submitting the form.');
                }
            } catch (error) {
                toast.error('An error occurred while submitting the form.');
                console.error('Error:', error);
            } finally {
                setTimeout(() => {
                    setLoading(false);
                    fetchData();
                }, 3000);

            }
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFilterInput({ ...filterInput, [name]: value });
    };

    const filteredData = (designation: string) => {
        const temp = isdesignation.filter((item: { designation: string; }) => item.designation?.toLowerCase().includes(designation?.toLowerCase()));
        setFilterData(temp);
    };

    
    return (
        <Page>
            <Fullpage close={sidebar}>
                {/* <h1 className='text-2xl mb-4 mr-96'>Role Access</h1> */}
                {isDesignationData && (
                    <div className="p-5 m-5 bg-gray-100 rounded-md shadow-lg mr-32">
                        <div >
                            <p className='text-center text-slate-800 p-3 font-bold text-3xl'>Designation Access</p>
                        </div>
                        <div className='text-left mb-2 flex flex-wrap'>
                            <button onClick={() => { setRole(true) }} className="text-white bg-green-600 hover:bg-green-900 focus:outline-none focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 border border-green-700 dark:bg-green-900 dark:border-green-900 dark:hover:bg-green-800">Add Designation</button>
                            <input
                                    type="text"
                                    name="designation"
                                    value={filterInput.designation}
                                    onChange={handleInputChange}
                                    placeholder="Search......."
                                    className="w-full md:w-96 h-10 border border-black px-10 py-2 text-left rounded-2xl"
                                />
                            <div className='ml-auto relative inline-block'>
                                <button onClick={toggleDropdown} className="text-white bg-green-600 hover:bg-green-900 focus:outline-none focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 border border-green-700 dark:bg-green-900 dark:border-green-900 dark:hover:bg-green-800">Dropdown Button</button>
                                {isOpen && (
                                    <div className="z-10 absolute  mt-2 w-46 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700">
                                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                                            <li>
                                                <button onClick={ExitDataDownload} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Download Existing Data</button>
                                            </li>
                                            <li>
                                                <button onClick={handledownload} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Template Download</button>
                                            </li>

                                        </ul>
                                    </div>
                                )}
                                {/* <button onClick={ExitDataDownload} className="text-white bg-green-600 hover:bg-green-900 focus:outline-none focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 border border-green-700 dark:bg-green-900 dark:border-green-900 dark:hover:bg-green-800">Download Existing Data</button>
                                <button onClick={handledownload} className="text-white bg-green-600 focus:outline-none focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 border border-green-700 hover:bg-green-900 dark:bg-green-900 dark:border-green-900 dark:hover:bg-green-800">Template Download</button>  */}
                                {/* <button onClick={handleUpload} className="text-white bg-green-600 focus:outline-none focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 border border-green-700 hover:bg-green-900 dark:bg-green-900 dark:border-green-900 dark:hover:bg-green-800">Import All Designation</button> */}
                                <label htmlFor="file" className="inline-block w-40 h-10 bg-green-600 px-5 py-1.5 me-2 rounded-lg cursor-pointer border border-green-700 hover:bg-green-900 dark:bg-green-900 dark:border-green-900 dark:hover:bg-green-800">
                                    <GrFormUpload className="inline-block ml-10 text-3xl text-white" />
                                    <input type="file" id="file" name="file" accept=".xlsx" className="hidden" onChange={handleFileChange} />
                                </label>
                            </div>
                        </div>
                        <table className=" table-fixed w-full  rounded-md shadow-lg bg-green-800 overflow-hidden">
                        {loading && (
                            <CustomLoader size={150} colors={"green"} />
                        )}
                            <thead className=" border-gray-100 text-green-100">
                                <tr>
                                    <th scope="col" className="px-6 py-4">
                                        Sr. No.
                                    </th>
                                    <th scope="col" className="px-6 py-4">
                                        Role Name
                                    </th>
                                    <th scope="col" className="px-6 py-4">
                                        Button
                                    </th>
                                    <th scope="col" className="px-6 py-4">
                                        Tab
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((data: any, index: any) => (
                                    <tr className="bg-white border-b  dark:border-gray-700">
                                        <td className="px-4 py-2 ">
                                            {index + 1}
                                        </td>
                                        <td className="px-4 py-2">
                                            {data.designation}
                                        </td>
                                        <td className="px-4 py-2 text-center" onClick={() => handleClick(data.id, data.designation)}>
                                            <div className="inline-block">
                                                <FaCheckToSlot className='text-blue-600' />
                                            </div>

                                        </td>
                                        <td className="px-4 py-2 text-center" onClick={() => openTab(data.id, data.designation)}>
                                            <div className="inline-block">
                                                <GoTab className='text-blue-600' />
                                            </div>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Pagination isValue={filterData} setCurrentItems={setCurrentItems} />
                    </div>
                )}
                {isOpenDesignation && (
                    <ButtonAccess designationId={designationId} designation={designation} close={closeButtonAccess} />
                )}
                {isTabAccess && (
                    <TabAccess designationId={designationId} designation={designation} close={closeTabAccess} />
                )}
            </Fullpage>
            <Modal isOpen={role} onClose={() => { setRole(false) }}>
                <AddRole user={user} sidebar={sidebar} setRole={setRole} />
            </Modal>
        </Page>
    )
}
