import { useEffect, useState } from "react";
import { CompanyApi } from "../../../services/company";
import { IoArrowBackOutline } from "react-icons/io5";
import { toast } from "react-toastify";


export default function ButtonAccess({ designationId, designation, close }: any) {
    const { addRoleData, getButton, getRoleMenus } = CompanyApi();
    const [Button, setButton] = useState<any>([]);
    // const [formData, setFormData] = useState<FormData>({});
    const [formData, setFormData] = useState<string[]>([]);

    useEffect(() => {
        fetchButton();
        RoleButton(designationId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const RoleButton = async (designationId: string) => {
        try {
            const Menus = await getRoleMenus(designationId);
            const menuData = Menus.data.data.button;
            if (menuData !== null) {
                setFormData(menuData);
            } else {
                // Handle the case where menuData is null
                console.error("menuData is null");
            }
        } catch (error) {
            // Handle error
        }
    };

    const fetchButton = async () => {
        const Buttons = await getButton();
        setButton(Buttons.data.data);
    }

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const response = await addRoleData(formData, designationId);
            if (response.status === 200) {
                toast.success('Role added successfully');
                close();
            } else {
                toast.error('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            toast.error('Error in submitting role. Please try again later.');
        }
    };

    const toggleCheckbox = (event: React.ChangeEvent<HTMLInputElement>, buttonValue: string) => {
        const { checked } = event.target;
        setFormData(prevFormData => checked ? [...prevFormData, buttonValue] : prevFormData.filter(value => value !== buttonValue));
    };


    return (
        <>
            <div className="p-5 m-5 bg-gray-100 rounded-md shadow-lg mr-32">
                <form onSubmit={handleSubmit}>
                    <div className="flex justify-between">
                        <div>
                            <h1 className="ml-10 font-bold text-3xl">{designation}</h1>
                        </div>
                        <div className="text-blue-500 font-bold text-4xl mr-20">
                            <IoArrowBackOutline onClick={close} />
                        </div>
                    </div>
                    <br/>
                    {/* bg-white border-b  dark:border-gray-700 */}
                    <table className="w-full table-fixed rounded-md shadow-lg bg-green-800 overflow-hidden">
                        <thead className="border-gray-100 text-green-100">
                            <tr >
                                <th className="py-1">Sr.No.</th>
                                <th className="py-2">Button</th>
                                <th className="py-2">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {Button.filter((data: any) => data.buttonValue && data.buttonName !== null)
                                .map((data: any, index: any) => (
                                    <tr key={index} className="bg-white border-b dark:border-gray-700">
                                        <td className="px-4 py-2">{data.id}</td>
                                        <td className="px-4 py-2 text-left">{data.buttonName}</td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                id={data.buttonValue}
                                                name={data.buttonValue}
                                                onChange={(e) => toggleCheckbox(e, data.buttonValue)}
                                                // checked={formData[data.buttonValue] === data.buttonValue}
                                                checked={formData.includes(data.buttonValue)}
                                                className="form-checkbox h-4 w-4"
                                            />
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    <button
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-4 mt-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                        type="submit"
                    >
                        Submit
                    </button>
                </form>
            </div>

        </>
    )
}