/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { LeaveAttendanceApi } from "../../../services/LeaveAttendance";
import EditAttendanceModalPage from '../Modals/EditAttendanceModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface AttendanceTableProps {
  calendarDates: any,
  checkInData: any,
  today: any,
  selectedEmployeeMain: any,
  getEmployeesData: any,
  setUserAttendanceData: (data: any) => void,
  setAttendanceDataOfMonth: (data: any) => void,
  userData: any,
  isOwnAttendance: boolean,
  leaveRequestData: any,
  setBalancedLeave: any
  // sidebarAccess: RoleAccess[];
  userid: string | undefined
}
// interface RoleAccess {
//   access: string;
// }
interface LeaveType {
  id: number;
  leaveType: string;
  defaultValue: number;
  leaveMaxValue: number;
  createdAt: string;
  updatedAt: string;
}


const AttendanceTable = ({ calendarDates, checkInData, today, userData, getEmployeesData, selectedEmployeeMain, isOwnAttendance, setUserAttendanceData, setAttendanceDataOfMonth, leaveRequestData, userid, setBalancedLeave }: AttendanceTableProps) => {
  const [editDate, seteditDate] = useState(null);
  const [deleteStatus, setDeleteStatus] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [getattendanceData, setAttendanceData] = useState<any>([]);
  const { getMonthData, getAttendanceRequest, setApprovedAttendance, setRejectedAttendance, getAddLeave } = LeaveAttendanceApi();
  const [getAttendanceRequests, setAttendanceRequests] = useState<any>([]);
  const [leave, setleaves] = useState<LeaveType[]>([]);


  useEffect(() => {
    if (userData.id !== undefined && !isOwnAttendance) {
      fetchAttendanceRequest();
    }
  }, [calendarDates, checkInData, getEmployeesData, selectedEmployeeMain]);

  useEffect(() => {
    if (userData.id !== undefined) {
      getFullMonthTableData();
    }
  }, [selectedEmployeeMain, checkInData, userData.id, getAttendanceRequests, leaveRequestData]);

  useEffect(() => {
    getLeaveType()
  }, [])

  const toggleEditModal = (date: any, status: string) => {
    seteditDate(date);
    setIsEditModalOpen(!isEditModalOpen);
    setDeleteStatus(status);
  };

  const isSunday = (date: any) => {
    return new Date(date).getDay() === 0; // Sunday
  };

  const getFullMonthTableData = async () => {
    if (selectedEmployeeMain !== null) {
      const data = await getMonthData(selectedEmployeeMain, today);
      const monthDataResponse = data.data.data;
      setAttendanceData(monthDataResponse);
      setAttendanceDataOfMonth(monthDataResponse);
    }
  }

  const getLeaveType = async () => {
    const response = await getAddLeave();
    setleaves(response.data.data);
  };

  const updateAttendanceDataOnDelete = (date: any) => {
    if (moment(today).format('YYYY-MM-DD') === date) {
      setUserAttendanceData({
        checkInTime: null,
        checkOutTime: null,
      });
    }
  };

  const fetchAttendanceRequest = async () => {
    try {
      const response = await getAttendanceRequest(userData.id);
      const attendanceRequests = response.data;
      let filteredAttendanceItems = [];
      if (attendanceRequests.length > 0) {
        filteredAttendanceItems = attendanceRequests.filter((item: any) => (
          item.checkIn !== null && item.checkIn !== '' &&
          item.checkOut !== null && item.checkOut !== ''
        ));
      }
      setAttendanceRequests(filteredAttendanceItems);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  }

  const handleAttendanceApprove = async (userId: string, date: string) => {
    const approvedResponse = await setApprovedAttendance(userId, date);
    if (approvedResponse.data.status === 200) {
      toast.success(approvedResponse.data.message);
      fetchAttendanceRequest();
    }
  }

  const handleAttendanceReject = async (userId: string, date: string) => {
    const rejectedResponse = await setRejectedAttendance(userId, date);
    if (rejectedResponse.data.status === 200) {
      toast.success(rejectedResponse.data.message);
      fetchAttendanceRequest();
    }
  }

  return (
    <div className='w-full p-4 bg-white mt-5 rounded-xl shadow-lg'>
      {/* {(sidebarAccess.some(menu => menu.access === 'attendace')) && */}
      <div>
        <div className="text-left ">
          <h2 className="text-left font-bold text-2xl mb-4">Attendance Requests</h2>
          <div className=''>
            <table className=" w-full table-fixed bg-green-800 dark:divide-gray-400">
              <thead className=" dark:border-gray-400">
                <tr>
                  <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-100 uppercase tracking-wider">User Id</th>
                  <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-100 uppercase tracking-wider">Username</th>
                  <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-100 uppercase tracking-wider">CheckIn Time</th>
                  <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-100 uppercase tracking-wider">CheckOut Time</th>
                  <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-100 uppercase tracking-wider">Status</th>
                  <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-100 uppercase tracking-wider">Date</th>
                  <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-100 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 text-center">
                {getAttendanceRequests.length > 0 ? (
                  getAttendanceRequests
                    .map((item: any, index: any) => (
                      <tr key={index}>
                        <td className="text-center border border-gray-400 p-2">{item.userId}</td>
                        <td className="text-center border border-gray-400 p-2">{userData.firstName + " " + userData.lastName}</td>
                        <td className="text-center border border-gray-400 p-2">
                          {item.checkIn ? item.checkIn : '-NA-'}
                        </td>
                        <td className="text-center border border-gray-400 p-2">
                          {item.checkOut ? item.checkOut : '-NA-'}
                        </td>
                        <td className="text-center border border-gray-400 p-2">
                          <span
                            className="relative inline-block px-3 py-1 font-semibold text-orange-900 leading-tight"
                          >
                            <span
                              aria-hidden
                              className="absolute inset-0 bg-orange-200 rounded-full"
                            ></span>
                            <span className="relative">
                              {item.status}
                            </span>
                          </span>
                        </td>
                        <td className="text-center border border-gray-400 p-2">
                          {moment(item.date).format('YYYY-MM-DD')}
                        </td>
                        <td className="text-center border border-gray-400">
                          {item.status === 'Pending' && (
                            <div className="">
                              <button
                                onClick={() => handleAttendanceApprove(item.userId, item.date)}
                                className="bg-blue-600 hover:bg-blue-800 text-sm text-white px-3 py-2 m-1 rounded mt-3 w-20"
                              >
                                Approve
                              </button>
                              <button
                                onClick={() => handleAttendanceReject(item.userId, item.date)}
                                className="bg-red-600 hover:bg-red-800 text-sm text-white px-3 py-2 m-1 rounded mb-3 w-20"
                              >
                                Reject
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td className="text-center border border-gray-400 p-2" colSpan={7}>
                      No request data available.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

          </div>

        </div>
        <br />
      </div>
      {/* } */}
      {/* Table of attendances */}
      <div>
        <p className="font-bold mb-5 text-left text-slate-800 text-2xl">Attendance</p>
        <div className="overflow-auto">
          <table className="  min-w-full rounded-lg overflow-hidden divide-y bg-green-800 dark:divide-gray-400">
            <thead>
              <tr>
                <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-100 uppercase tracking-wider">
                  Date
                </th>
                <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-100 uppercase tracking-wider">
                  Status
                </th>
                <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-100 uppercase tracking-wider">
                  Check In
                </th>
                <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-100 uppercase tracking-wider">
                  Check Out
                </th>
                <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-100 uppercase tracking-wider">
                  Duration
                </th>
                <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-100 uppercase tracking-wider">
                  Reason
                </th>
                {userid &&
                  <th className="text-center px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-100 uppercase tracking-wider">
                    Edit
                  </th>
                }
              </tr>
            </thead>
            <tbody className="bg-gray-100 divide-y divide-gray-300 text-center">
              {calendarDates?.map((item: any, index: any) => {
                const attendanceDataItem = getattendanceData.find(
                  (data: any) => data.date === moment(item.date).format("YYYY-MM-DD")
                );

                const checkIn = attendanceDataItem?.checkIn || "-NA-";
                const checkOut = attendanceDataItem?.checkOut || "-NA-";
                const duration = attendanceDataItem?.duration || "-NA-";
                const status = attendanceDataItem?.status || "-NA-";
                const remarks = attendanceDataItem?.remarks || "-NA-";
                const isWeekend = isSunday(item.date);
                const isHolidayOrLeave = item.holidaydetails || status === "Absent"

                return (
                  <tr key={index}>
                    <td className="px-4 sm:px-6 py-2 sm:py-3 whitespace-no-wrap text-center">
                      {moment(item.date).format("YYYY-MM-DD")}
                    </td>
                    <td className={`px-4 sm:px-6 py-2 sm:py-3 whitespace-no-wrap text-center`}>
                      <span
                        className={`relative inline-block px-3 py-1 font-semibold leading-tight ${isWeekend || isHolidayOrLeave
                          ? "text-red-900 font-bold"
                          : status === "Present"
                            ? "text-green-900 font-bold"
                            : status === "Pending"
                              ? "text-orange-900 font-bold"
                              : leave.some(leaveType => leaveType.leaveType === status)
                                ? "text-[#412575]" :
                                status === "-NA-"
                                  ? "text-black-900 font-normal"
                                  : ""
                          }`}
                      >
                        <span
                          aria-hidden
                          className={`absolute inset-0 rounded-full ${isWeekend || isHolidayOrLeave
                            ? "bg-red-200"
                            : status === "Present"
                              ? "bg-green-200"
                              : status === "Pending"
                                ? "bg-orange-200 font-bold"
                                : leave.some(leaveType => leaveType.leaveType === status)
                                  ? "bg-[#d2b9ff]" : ""
                            }`}
                        ></span>
                        <span className="relative">
                          {isWeekend
                            ? "Weekend"
                            : item.holidaydetails
                              ? `Holiday (${item.holidaydetails})`
                              : status}
                        </span>
                      </span>
                    </td>
                    <td className="px-4 sm:px-6 py-2 sm:py-3 whitespace-no-wrap text-center">
                      {checkIn}
                    </td>
                    <td className="px-4 sm:px-6 py-2 sm:py-3 whitespace-no-wrap text-center">
                      {checkOut}
                    </td>
                    <td className="px-4 sm:px-6 py-2 sm:py-3 whitespace-no-wrap text-center">
                      {duration}
                    </td>
                    <td>
                      {remarks}
                    </td>
                    {userid &&
                      <td className="px-4 sm:px-6 py-2 sm:py-3 whitespace-no-wrap">
                        <button
                          onClick={() => toggleEditModal(moment(item.date).format("YYYY-MM-DD"), status)}
                          className="p-2.5 bg-gray-500 rounded-xl hover:rounded-3xl hover:bg-gray-600 transition-all duration-300 text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                            />
                          </svg>
                        </button>
                      </td>
                    }
                  </tr>
                );
              })}
            </tbody>

          </table>
        </div>
      </div>

      <EditAttendanceModalPage editDate={editDate} deleteStatus={deleteStatus} isEditModalOpen={isEditModalOpen} toggleEditModal={toggleEditModal} userId={selectedEmployeeMain} getFullMonthTableDataHandle={() => { getFullMonthTableData(); fetchAttendanceRequest(); setBalancedLeave(editDate) }} onDeleteAttendance={updateAttendanceDataOnDelete} />
    </div >
  )
}

export default AttendanceTable