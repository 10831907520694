import React, { Dispatch, SetStateAction, useRef } from 'react';
import { useState, useEffect } from 'react';
// import { FaTrashAlt } from 'react-icons/fa'
import { LeaveAttendanceApi } from "../../../services/LeaveAttendance";
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface HolidayEntry {
    id: number,
    day: string,
    date: string,
    reason: string,
    state: boolean,
}

interface props { setIsModalOpen: Dispatch<SetStateAction<boolean>>; fetchData: () => Promise<void>; }


export default function Holiday(props: props) {
    const run = useRef(false)
    const { addNewHoliday, getOptionalHolidays, saveOptionalHoliday } = LeaveAttendanceApi();
    const [obj1, setObj] = useState<HolidayEntry>({ id: NaN, day: '', date: '', reason: '', state: false });
    const [rows, setRows] = useState<HolidayEntry[]>([]);
    const [selectAll, setSelectAll] = useState(false);


    useEffect(() => {
        if (run.current) return;
        run.current = true
        updatedData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    async function addNew() {
        if (obj1.day !== '' && obj1.date !== '' && obj1.reason !== '') {
            setObj({ id: NaN, day: '', date: '', reason: '', state: false });
            let data = await addNewHoliday(obj1);
            if (data.status === 200) {
                toast.success(data.message);
                props.setIsModalOpen(false);
                props.fetchData();

            }
        }
    }

    async function updatedData() {
        try {
            const data = await getOptionalHolidays();
            setRows(data);
        } catch (error) {
            console.error('Error fetching holiday data:', error);
        }
    }
    const handleSelectAll = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);

        // Update the state of all checkboxes in the rows
        const updatedRows = rows.map(row => ({ ...row, state: newSelectAll }));
        setRows(updatedRows);
    };

    const toggleCheckbox = (index: number) => {
        const updatedRows = [...rows];
        updatedRows[index] = { ...updatedRows[index], state: !updatedRows[index]?.state };
        setRows(updatedRows);

        const allSelected = updatedRows.every(row => row.state);
        setSelectAll(allSelected);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        try {
            const response = await saveOptionalHoliday(rows);
            props.setIsModalOpen(false);
            props.fetchData();
            if (response.status === 200) {
                toast.success(response.message);
            }
        } catch (error) {
            // Handle any errors
            console.error('Error:', error);

        };
    }

    return (
        <div className='bg-white shadow-md rounded-lg '>
            <div className='flex justify-center'>
                <div className='bg-sky-100 flex w-4/5 rounded-lg mt-6'>
                    <div className='self-center'>
                        <input type="text" className='bg-sky-100 w-full text-center focus:outline-none text-gray-700   p-2.5' placeholder="Day"
                            value={obj1.day} onChange={(e) => setObj({ ...obj1, day: e.target.value })} />
                    </div>

                    <div className='self-center'>
                        <input type="date" className='bg-sky-100 w-full text-center focus:outline-none text-gray-700  p-2.5' placeholder="Date"
                            value={obj1.date} onChange={(e) => setObj({ ...obj1, date: e.target.value })}
                            max="9999-12-31" />
                    </div>

                    <div className='self-center'>
                        <input type="text" className='bg-sky-100 w-full text-center focus:outline-none text-gray-700 p-2.5' placeholder="Holiday"
                            value={obj1.reason} onChange={(e) => setObj({ ...obj1, reason: e.target.value })} />
                    </div>

                    <div className='self-center '>
                        <button onClick={addNew} className=' text-white bg-sky-500 hover:bg-sky-600 hover:border-sky-900 focus:ring-2 focus:outline-none focus:ring-sky-300 active:bg-sky-900 rounded-md text-sm px-5 py-2.5 m-2 '>Save</button>
                    </div>

                </div>
            </div>
            <br />
            <form onSubmit={handleSubmit} >
                <div className=" overflow-x-auto max-h-[400px] overflow-y-scroll max-w-3xl rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase sticky top-0 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="p-4">
                                    <div className="flex items-center">
                                        <input checked={selectAll} type="checkbox" onChange={() => handleSelectAll()} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label className="sr-only">checkbox</label>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Day
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Holiday
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, index) => (
                                <tr className="bg-white border-b  dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td className="w-4 p-4">
                                        <div className="flex items-center">
                                            <input checked={row?.state} type="checkbox" onChange={() => toggleCheckbox(index)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label className="sr-only">checkbox</label>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4">
                                        {row.day}
                                    </td>
                                    <td className="px-6 py-4">
                                        {format(new Date(row.date), 'dd/MM/yyyy')}
                                    </td>
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {row.reason}
                                    </th>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-center bg-white mt-4 rounded-md">
                    <button type="submit" className="text-white bg-blue-600  hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Submit</button>
                    <button onClick={() => props.setIsModalOpen(false)} type="button" className="focus:outline-none text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">close</button>
                </div>
            </form>
        </div>
    )
}