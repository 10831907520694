import { useEffect, useRef, useState } from "react";
import { UsersApi } from "../../../services/users";
import { useParams } from "react-router-dom";
import * as Yup from 'yup';
import { toast } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa";

interface respponse {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    joiningDate: string;
    designation: string;
    department: string;
    managerName: string;
    companyName: string;
    companyAddress: string;
    currentDate: string;
    annualSalary: string;
    HR_Name: string;
    HR_Designation: string;
    Acceptance_Last_Date: string;
    Document_Type: string;
    location: string;
}
interface errDocFormate {
    Document_Type: string;
    firstName: string;
    lastName: string;
    email: string;
    joiningDate: string;
    designation: string;
    department: string;
    managerName: string;
    companyName: string;
    companyAddress: string;
    annualSalary: string;
    HR_Name: string;
    HR_Designation: string;
    Acceptance_Last_Date: string;
    currentDate: string;
    location: string


}
interface generateDocs {
    generateDoc?: () => void,
}
const initial: errDocFormate = { Document_Type: "", firstName: '', lastName: '', email: '', joiningDate: '', designation: '', department: '', managerName: '', companyName: '', companyAddress: '', annualSalary: "", HR_Name: "", HR_Designation: "", Acceptance_Last_Date: "", currentDate: "", location: "" }

const validationSchema = Yup.object().shape({
    Document_Type: Yup.string().required('Document Type Required'),
    firstName: Yup.string().matches(/^[A-Za-z\s]+$/, 'Name Must Contain Only Letters'),
    designation: Yup.string().matches(/^[A-Za-z\s]+$/, 'Designation Must Contain Only Letters'),
    annualSalary: Yup.string().matches(/^\d+$/, 'Annual Salary must be numeric').required("Annual Salary Required!!"),
    companyAddress: Yup.string().required('Company Address is required'),
    // managerName: Yup.string().matches(/^[A-Za-z\s]+$/, 'Manager name required'),
    managerName: Yup.string().when('Document_Type', {
        is: (value: string) => value !== 'Bonafide_Letter',
        then(schema) {
            return schema.matches(/^[A-Za-z\s]+$/, 'Manager name required').required("Manager name required!!");
        },
        otherwise: (schema) => schema.notRequired()
    }),
    joiningDate: Yup.string().required('Employee Joining Date'),
    Acceptance_Last_Date: Yup.date().when('Document_Type', {
        is: (value: string) => value === "Appraisal_Letter",
        then(schema) {
            return schema.required("Acceptance Last Date Required")
        },
        otherwise: (schema) => schema.notRequired()
    }),
    location: Yup.string().when('Document_Type', {
        is: (value: string) => value === 'Address_Proof',
        then(schema) {
            return schema.required("'Employee's Current Address Required !!");
        },
        otherwise: (schema) => schema.notRequired()

    }),
    // HR_Name: Yup.string().matches(/^[A-Za-z\s]+$/, 'Name Must Contain Only Letters').required("HR Name Required!!"),
    HR_Name: Yup.string().when('Document_Type', {
        is: (value: string) => value !== 'Bonafide_Letter',
        then(schema) {
            return schema.matches(/^[A-Za-z\s]+$/, 'Name Must Contain Only Letters').required("HR Name Required!!");
        },
        otherwise: (schema) => schema.notRequired()
    }),
    HR_Designation: Yup.string().when('Document_Type', {
        is: (value: string) => value !== 'Offer_Letter' && value !== 'Bonafide_Letter',
        then(schema) {
            return schema.matches(/^[A-Za-z\s]+$/, 'Name Must Contain Only Letters').required("HR Designation Required !!");
        },
        otherwise: (schema) => schema.notRequired()
    }),
    currentDate: Yup.string().required('Current Date Required'),
});
function PeopGeneDoc(props: generateDocs) {
    // const [employeeData, setEmployeeData] = useState({
    //     firstName: '',
    //     // Other employee data fields
    // });
    const { userId } = useParams<{ userId: string }>()
    const user = parseInt(userId);
    const { getUserDocData, generateDoc, generateDocEmail } = UsersApi();
    const [dataDoc, setDocData] = useState<respponse>({ id: NaN, firstName: '', lastName: '', email: '', joiningDate: '', designation: '', department: '', managerName: '', companyName: '', companyAddress: '', currentDate: "", annualSalary: "", HR_Name: "", HR_Designation: "", Acceptance_Last_Date: "", Document_Type: "", location: "" });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [err, setErr] = useState<errDocFormate>(initial);
    const [validation, setValidation] = useState(false);
    const run = useRef(false);
    useEffect(() => {
        if (run.current) return;
        run.current = true;
        funt();
    });
    const funt = async () => {
        const data = await getUserDocData(user);
        setDocData({ ...data, currentDate: new Date().toISOString().split('T')[0] })
    }
    const handleChange = async (field: string, value: string) => {
        try {
            setErr((prevErrors) => ({
                ...prevErrors,
                [field]: '',
            }));

            setDocData((data) => ({
                ...data,
                [field]: value,
            }));

            await validationSchema.validate({ ...dataDoc, [field]: value }, { abortEarly: false });

        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors = error.inner.reduce((acc, { path, message }) => ({
                    ...acc,
                    [path as keyof errDocFormate]: message,
                }), {} as Partial<errDocFormate>);
                if (validation === true) {
                    setErr(prevErrors => ({ ...prevErrors, ...errors }));
                }
            }
        }
    };
    const genDoc = async () => {
        try {
            var blobData
            await validationSchema.validate(dataDoc, { abortEarly: false });
            // if (JSON.stringify(err) === JSON.stringify(initial)) {
            const promise = generateDoc(dataDoc);
            setTimeout(() => {
                toast.promise(promise, {
                    pending: 'Loading......',
                    success: 'Downloading...... ',
                    error: 'Something wrong !!'
                });
            }, 100);
            blobData = await promise
            if (blobData.status === 200) {
                const blob = new Blob([blobData.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = dataDoc.Document_Type + '.pdf';
                link.click();
            }
            // }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors = error.inner.reduce((acc, { path, message }) => ({
                    ...acc,
                    [path as keyof errDocFormate]: message,
                }), {} as Partial<errDocFormate>);

                setErr(prevErrors => ({ ...prevErrors, ...errors }));
                setValidation(true);
            }
        }

    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const genDocEmail = async () => {
        try {
            await validationSchema.validate(dataDoc, { abortEarly: false });
            if (JSON.stringify(err) === JSON.stringify(initial)) {
                const response = await generateDocEmail(dataDoc);
                // blobData = await generateDoc(dataDoc);

                if (response.data.status === 200) {
                    toast.success(response.data.message);
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors = error.inner.reduce((acc, { path, message }) => ({
                    ...acc,
                    [path as keyof errDocFormate]: message,
                }), {} as Partial<errDocFormate>);

                setErr(prevErrors => ({ ...prevErrors, ...errors }));
                setValidation(true);
            }
        }

    };



    return (
        <div className="w-full bg-white p-5 mt-5 mb-5 rounded-lg shadow-lg" >
            <div  >
                <div className='text-left text-3xl font-semibold'>Generate Letter
                    <FaArrowLeft className='fill-blue-500 font-bold float-right' onClick={() => props.generateDoc?.()}
                        data-tooltip-id={'my-tooltip'} data-tooltip-content="PeopleDetails" />
                </div> <br />
                
                <div className='text-left' >
                    <label htmlFor="select" className="block mb-2 text-lg font-semibold text-gray-90 text-black">Document Type*</label>
                    <select className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5"
                        value={dataDoc?.Document_Type}
                        onChange={(e) => {
                            handleChange('Document_Type', e.target.value)
                        }}>
                        <option value='' disabled>Choose A Document Type</option>
                        <option value="Offer_Letter">Offer Letter</option>
                        <option value="Appointment_Letter">Appointment Letter</option>
                        <option value="Appraisal_Letter">Appraisal Letter</option>
                        <option value="Bonafide_Letter">Bonafide Letter</option>
                        {/* <option value="Address_Proof">Address Proof</option> */}
                        <option value="Relieving_Letter">Relieving Letter</option>
                    </select>
                    {err.Document_Type && <b className='text-red-500 text-xs mt-1'>{err.Document_Type}</b>}
                    
                    <hr className="w-150 h-1 mx-auto my-2 border-0 rounded md:my-2 bg-black"></hr>

                    <div>
                        <div>
                            {/* <p>If you are generating a letter htmlFor an employee, please select an employee</p> */}

                            {/* <input
                                className='bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-40 text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"'
                                type="text"
                                value={dataDoc?.firstName}
                                placeholder=" "

                            /> */}
                        </div>
                        
                        {/* <div className="w-3/5 h-25 p-4 resize-none bg-white border-2 border-gray-300  focus:outline-none focus:border-blue-500 overflow-auto" contentEditable="false">
                            Generate Offer Letter htmlFor Prathamesh by entering the required data below. You can modify the default template by selecting the option on the right sidebar.
                        </div> */}
                        
                        <section className="bg-gray-100 max-w-5xl px-6 dark:bg-slate-50 rounded-md shadow-lg">
                            <div className="py-2 px-2  lg:py-5">
                                <div className="grid gap-6 sm:grid-cols-2 ">
                                    <div className="w-full">
                                        <label className="block mb-2 text-sm font-medium text-gray-90 text-black">Employee First Name :</label>
                                        <input className="bg-slate-50 border border-gray-300  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                            placeholder="Employee Name"
                                            type="text" value={dataDoc?.firstName}
                                            disabled
                                            onChange={(e) => {
                                                handleChange('firstName', e.target.value)
                                            }} />
                                        {err.firstName && <b className='text-red-500 text-xs mt-1'>{err.firstName}</b>}
                                    </div>
                                    <div className="w-full">
                                        <label className="block mb-2 text-sm font-medium text-gray-90 text-black">Employee Last Name :</label>
                                        <input className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                            placeholder="Employee Name"
                                            disabled
                                            type="text" value={dataDoc?.lastName}
                                            onChange={(e) => {
                                                handleChange('firstName', e.target.value)
                                            }} />
                                        {err.lastName && <b className='text-red-500 text-xs mt-1'>{err.lastName}</b>}
                                    </div>
                                    <div className="w-full">
                                        <label className="block mb-2 text-sm font-medium text-gray-90 text-black">Company Name :</label>
                                        <input className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                            placeholder="Company Name"
                                            disabled
                                            type="text" value={dataDoc?.companyName}
                                            onChange={(e) => {
                                                handleChange('companyName', e.target.value)
                                            }} />
                                        {err.companyName && <b className='text-red-500 text-xs mt-1'>{err.companyName}</b>}
                                    </div>
                                    {dataDoc.Document_Type !== "Bonafide_Letter" && (
                                        <div className="w-full">
                                            <label className="block mb-2 text-sm font-medium text-gray-90 text-black">Company Address :</label>
                                            <input className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                type="text" value={dataDoc?.companyAddress}
                                                disabled
                                                placeholder="Company Address"
                                                onChange={(e) => {
                                                    handleChange('companyAddress', e.target.value)
                                                }} />
                                            {err.companyAddress && <b className='text-red-500 text-xs mt-1'>{err.companyAddress}</b>}
                                        </div>
                                    )}
                                    <div className="w-full">
                                        <label className="block mb-2 text-sm font-medium text-gray-90 text-black">Current Date :</label>
                                        <input className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                            type="date" value={dataDoc.currentDate}
                                            placeholder="Current_Date"
                                            onChange={(e) => {
                                                handleChange('currentDate', e.target.value)
                                            }}
                                        />
                                        {err.currentDate && <b className='text-red-500 text-xs mt-1'>{err.currentDate}</b>}
                                    </div>

                                    <div className="w-full">
                                        {dataDoc?.Document_Type === 'Offer_Letter' ? (
                                            <label className="block mb-2 text-sm font-medium text-gray-90 text-black">Designation :</label>
                                        ) : (
                                            <label className="block mb-2 text-sm font-medium text-gray-90 text-black">Employee Designation :</label>
                                        )}
                                        <input className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                            placeholder="Job_Title"
                                            type="text" value={dataDoc?.designation}
                                            disabled
                                            onChange={(e) => {
                                                handleChange('designation', e.target.value)
                                            }} />
                                        {err.designation && <b className='text-red-500 text-xs mt-1'>{err.designation}</b>}
                                    </div>
                                    {dataDoc?.Document_Type === 'Address_Proof' && (
                                        <div className="w-full">

                                            <label className="block mb-2 text-sm font-medium text-gray-90 text-black">Employee Current Address :</label>
                                            <input className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                placeholder="Job_Title"
                                                type="text" value={dataDoc?.location}
                                                onChange={(e) => {
                                                    handleChange('location', e.target.value)
                                                }} />
                                            {err.location && <b className='text-red-500 text-xs mt-1'>{err.location}</b>}

                                        </div>
                                    )}
                                    {dataDoc.Document_Type !== "Bonafide_Letter" && (
                                        <div className="w-full">
                                            <label className="block mb-2 text-sm font-medium text-gray-90 text-black">Annual CTC :</label>
                                            <input className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                placeholder="Annual_CTC"
                                                type="text"
                                                disabled
                                                value={dataDoc?.annualSalary}
                                                onChange={(e) => {
                                                    handleChange('annualSalary', e.target.value)
                                                }}
                                            />
                                            {err.annualSalary && <b className='text-red-500 text-xs mt-1'>{err.annualSalary}</b>}
                                        </div>
                                    )}
                                    {dataDoc.Document_Type !== "Bonafide_Letter" && (
                                        <div className="w-full">
                                            <label className="block mb-2 text-sm font-medium text-gray-90 text-black">Manager Name :</label>
                                            <input className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                placeholder="Manager_Name"
                                                type="text" value={dataDoc?.managerName}
                                                disabled
                                                onChange={(e) => {
                                                    handleChange('managerName', e.target.value)
                                                }} />
                                            {err.managerName && <b className='text-red-500 text-xs mt-1'>{err.managerName}</b>}
                                        </div>
                                    )}
                                    <div className="w-full">
                                        <label className="block mb-2 text-sm font-medium text-gray-90 text-black">Employee Joining Date :</label>
                                        <input className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                            placeholder="Employee_Joining_Date"
                                            type="date" value={dataDoc?.joiningDate}
                                            disabled={dataDoc?.Document_Type !== "Offer_Letter"}
                                            onChange={(e) => {
                                                handleChange('date', e.target.value)
                                            }} />
                                        {err.joiningDate && <b className='text-red-500 text-xs mt-1'>{err.joiningDate}</b>}
                                    </div>
                                    {(dataDoc?.Document_Type === 'Appraisal_Letter') && (
                                        <div className="w-full">
                                            <label className="block mb-2 text-sm font-medium text-gray-90 text-black">Appraisal Date :</label>
                                            <input className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                placeholder="Acceptance_Last_Date"
                                                type="date" value={dataDoc?.Acceptance_Last_Date}
                                                onChange={(e) => {
                                                    handleChange('Acceptance_Last_Date', e.target.value)
                                                }} />
                                            {err.Acceptance_Last_Date && <b className='text-red-500 text-xs mt-1'>{err.Acceptance_Last_Date}</b>}
                                        </div>
                                    )}
                                    {/* {(dataDoc?.Document_Type === 'Offer_Letter' || dataDoc?.Document_Type === 'Appraisal_Letter') && (
                                        <div className="w-full">
                                            {dataDoc?.Document_Type === 'Offer_Letter' ? (
                                                <label className="block mb-2 text-sm font-medium text-gray-90 text-black">Acceptance Last Date :</label>
                                            ) : (
                                                <label className="block mb-2 text-sm font-medium text-gray-90 text-black">Appraisal Date :</label>
                                            )}
                                            <input className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                placeholder="Acceptance_Last_Date"
                                                type="date" value={dataDoc?.Acceptance_Last_Date}
                                                onChange={(e) => {
                                                    handleChange('Acceptance_Last_Date', e.target.value)
                                                }} />
                                            {err.Acceptance_Last_Date && <b className='text-red-500 text-xs mt-1'>{err.Acceptance_Last_Date}</b>}
                                        </div>
                                    )} */}
                                    {dataDoc.Document_Type !== "Bonafide_Letter" && (
                                        <div className="w-full">
                                            <label className="block mb-2 text-sm font-medium text-gray-90 text-black">HR Name :</label>
                                            <input className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                placeholder="HR Name"
                                                type="text"
                                                value={dataDoc.HR_Name}
                                                onChange={(e) => {
                                                    handleChange('HR_Name', e.target.value)
                                                }} />
                                            {err.HR_Name && <b className='text-red-500 text-xs mt-1'>{err.HR_Name}</b>}
                                        </div>
                                    )}
                                    {(dataDoc?.Document_Type !== 'Offer_Letter' && dataDoc?.Document_Type !== "Bonafide_Letter") && (
                                        <div className="w-full">
                                            <label className="block mb-2 text-sm font-medium text-gray-90 text-black">HR Designation: </label>
                                            <input className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                placeholder="HR Designation"
                                                type="text"
                                                value={dataDoc.HR_Designation}
                                                onChange={(e) => {
                                                    handleChange('HR_Designation', e.target.value)
                                                }} />
                                            {err.HR_Designation && <b className='text-red-500 text-xs mt-1'>{err.HR_Designation}</b>}
                                        </div>
                                    )}

                                </div>
                            </div>
                        </section>
                    </div>

                    
                    {/* <div className="flex items-center">
                        <input id="link-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                        <label htmlFor="link-checkbox" className="ms-2 text-sm font-medium  dark:text-gray-300"> Include XPayroll generated salary structure from annual CTC</label>
                    </div> */}
                    <div>
                        <button onClick={genDoc} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">GENERATE LETTER</button>
                        {/* <button type="button" onClick={genDocEmail} className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium  rounded-lg group bg-gradient-to-br from-teal-300 to-lime-300 group-hover:from-teal-300 group-hover:to-lime-30 text-black dark:hover: focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800">
                            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                SEND LETTER VIA EMAIL
                            </span>
                        </button> */}
                    </div>

                </div>
            </div>
        </div>

    )

}

export default PeopGeneDoc