import React, { useState, useEffect, useRef } from 'react';
import Calendar from './Components/Calender';
import Modal from '../../components/modal';
import { LeaveAttendanceApi } from '../../services/LeaveAttendance';
import AttendancePage from './Components/Attendance';
import moment from 'moment';
import AttendanceTable from './Components/AttendanceTable';
import FingerPrintSetup from './Components/FingerPrintSetup';
import Button from '../../components/Button';
// import AddLeaveType from './Components/AddLeaveType';
import ApplyLeave from './Modals/ApplyLeave';
import ViewReqPendLeave from './Components/ViewReqPendLeave';
import { Main, Page, RightBar } from '../../components/Sidebar';
import { useParams } from 'react-router-dom';
import RemainingLeave from './Components/RemainingLeave';


interface HolidayEntry {
  id: number
  day: string;
  date: string;
  reason: string;
}


interface RoleAccess {
  access: string;
}

interface HolidayProps {
  user: string;
  sidebar: boolean;
  sidebarAccess: RoleAccess[];
}

export default function LeaveAttendencePage({ user, sidebar, sidebarAccess }: HolidayProps) {
  const normalFormatDate = moment().format('YYYY-MM-DD');
  const [checkInData, setCheckInData] = useState<any>([]);
  const [userAttendanceData, setUserAttendanceData] = useState<{
    checkInTime: any | null;
    checkOutTime: any | null;
  }>({
    checkInTime: null,
    checkOutTime: null,
  });
  const run = useRef(false)
  const [holiday, setHoliday] = useState<HolidayEntry[]>([]);
  const [calendarDates, setCalendarDates] = useState<any[]>([]);
  const [attendanceDataofMonth, setAttendanceDataOfMonth] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  // const [isLeaveOpen, setOpenLeave] = useState(false);
  const [isApplyLeaveOpen, setApplyOpenLeave] = useState(false);
  const [getEmployeesData, setEmployeesDetails] = useState<any>([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const { error, checkData, getCheckInCheckOutData, fetchHoliday, getEmployeesDetails, getUserData } = LeaveAttendanceApi();
  const [todayDate, setTodayDate] = useState<Date>(new Date());
  const [OpenRemainingLeave, setOpenRemainingLeave] = useState(false);
  const [userData, setUserData] = useState<any>([]);
  const [leaveRequestData, setleaveRequestData] = useState<any>([]);
  const [leaveRender, setLeaveRender] = useState<any>([]);
  const [BalancedLeave, setBalancedLeave] = useState<any>([]);
  const { userid } = useParams<{ userid: string }>();
  const [isOwnAttendance, setIsOwnAttendance] = useState(true);
  useEffect(() => {
    if (run.current) return;
    run.current = true
    fetchData();
    getUserDetails();
    if (userid) {
      setIsOwnAttendance(!isOwnAttendance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holiday, checkInData]);

  useEffect(() => {
    if (userData.id !== undefined) {
      setSelectedEmployee(userData.id);
      const handleGetEmployeesDetails = async () => {
        const responseEmpDetails = await getEmployeesDetails(userData.id);
        if (responseEmpDetails.data.status === 200) {
          setEmployeesDetails(responseEmpDetails.data.data);
        }
      }

      handleGetEmployeesDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, userData.id]);

  const getUserDetails = async () => {
    const userFullData = await getUserData(userid);
    if (userFullData.data.data.id) {
      setUserData(userFullData.data.data);
      getCheckInCheckOutData(userFullData.data.data.id, normalFormatDate);
    }
  };
  async function fetchData() {
    const data = await fetchHoliday();
    setHoliday(data);
    return data
  }



  if (error) {
  }
  return (
    <Page >
      <Main close={sidebar}>
        <div className='p-4 bg-gray-50 rounded-xl shadow-lg mt-5 mb-5'>
          <p className="text-left font-bold text-3xl text-slate-800 m-4">Leave and Attendance</p>
          <AttendancePage userid={userid} userData={userData} userRole={user} checkData={checkData} setCheckInData={setCheckInData} checkInData={checkInData} calendarDates={calendarDates} userAttendanceData={userAttendanceData} setUserAttendanceData={setUserAttendanceData} />
          <Calendar holidays={holiday} setCalendarDates={setCalendarDates} attendanceData={attendanceDataofMonth} setTodayDate={setTodayDate} leaveRequestData={leaveRequestData} />
          {/* <Modal isOpen={isLeaveOpen} onClose={() => { setOpenLeave(false) }}>
          <AddLeaveType />
        </Modal> */}
          <Modal isOpen={isApplyLeaveOpen} onClose={() => { setApplyOpenLeave(false) }}>
            <ApplyLeave setApplyOpenLeave={setApplyOpenLeave} setBalancedLeave={setBalancedLeave} setleaveRequestData={setLeaveRender} />
          </Modal>
          <Modal isOpen={OpenRemainingLeave} onClose={() => { setOpenRemainingLeave(false) }}>
            <RemainingLeave />
          </Modal>
          <ViewReqPendLeave userRole={user} userData={userData} sidebarAccess={sidebarAccess} isOwnAttendance={isOwnAttendance} leaveRequestData={leaveRequestData} setleaveRequestData={setleaveRequestData} BalancedLeave={BalancedLeave} leaveRender={leaveRender} setLeaveRender={setLeaveRender} />
          <AttendanceTable userid={userid} calendarDates={calendarDates} getEmployeesData={getEmployeesData} checkInData={checkInData} today={todayDate} userData={userData} setUserAttendanceData={setUserAttendanceData} setAttendanceDataOfMonth={setAttendanceDataOfMonth} selectedEmployeeMain={selectedEmployee} isOwnAttendance={isOwnAttendance} leaveRequestData={leaveRender} setBalancedLeave={setBalancedLeave} />
          <Modal isOpen={isModalOpen1} onClose={() => { setIsModalOpen1(false); setBtnDisable(false) }}>
            <FingerPrintSetup />
          </Modal>
        </div>
      </Main>
      <RightBar>
        <div className=''>
          {(sidebarAccess.some(menu => menu.access === 'leaveTaken')) &&
            <div>
              {/* <div className=' flex justify-center'>
                <Button disabled={btnDisable} onClick={() => setOpenLeave(true)} >
                  Add Leave
                </Button>
              </div> */}


            </div>

          }
          {/* {(sidebarAccess.some(menu => menu.access === 'machineSetup')) &&
            <div className='py-2'>
              <Button disabled={btnDisable} onClick={() => { setIsModalOpen1(true); setBtnDisable(true) }} >
                Setup machine
              </Button>
            </div>
          } */}
          <div>
            <div className=' flex justify-center'>
              <Button disabled={btnDisable} onClick={() => { setOpenRemainingLeave(true); }} >
                Leaves Taken
              </Button>
            </div>
            {(sidebarAccess.some(menu => menu.access === 'applyLeave' && userid === undefined)) &&
              <div className=' flex justify-center'>
                <Button disabled={btnDisable} onClick={() => { setApplyOpenLeave(true) }} >
                  Apply Leave
                </Button>
              </div>
            }
          </div>
        </div>
      </RightBar>
    </Page>
  );
}