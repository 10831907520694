import { useEffect, useState } from "react";
import { CompanyApi } from "../../services/company";
import { Main, Page } from "../../components/Sidebar";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import PeopProfPhoto from "../People/Components/PeopProfPhoto";
import Modal from "../../components/modal";
import { UsersApi } from "../../services/users";
import { ReimbursementApi } from "../../services/Reimbursement";
import ConfirmButton from "../../components/confirmationBox";
import CustomLoader from "../../components/loader";

interface RoleAccess {
    access: string;
}

interface User {
    user: string;
    sidebar: boolean;
    sidebarAccess: RoleAccess[];
}



interface Department {
    name: string;
    shift: string;
    startTime: string;
    endTime: string;
}

interface Role {
    name: string
}

interface CompanyData {
    id: number,
    companyName: string,
    companyEmail: string,
    companyAddress: string,
    companyContactNumber: string,
    companyLogo: string,
    departments: Department[];
    role: Role[];
    roleMenusExists: boolean;
}

interface TaxFormState {
    userId: null;
    id: number;
    panCardNo: string;
    accountNo: number;
    confirmBankAccountNumber: number;
    ifscCode: string;
    beneficiaryName: string;
    paymentMode: string;
    error: string | null;
}

interface users {
    id: number;
    natureOfEmployment: string,
    firstName: string,
    lastName: string,
    gender: string,
    dateOfBirth: string,
    email: string,
    mobile: number | undefined,
    countryCode: number | undefined,
    annualSalary: number,
    joiningDate: string,
    designation: string,
    department: string,
    isTeacher: boolean | undefined,
    location: string,
    residentIndia: string,
}

export default function DashboardPage({ user, sidebar, sidebarAccess, }: User) {

    const { getCompanyDetails, getBankDetails, getProfileDoc } = CompanyApi();
    const { updateBankDetails, getUserId } = UsersApi();
    const [company, setCompany] = useState<CompanyData>();
    const [userdata, setUserdata] = useState<users>();
    const [OpenModal, setOpenModal] = useState(false);
    const [OpenChequeModal, setOpenChequeModal] = useState(false);
    const { addChequeData } = ReimbursementApi();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [previewImage, setPreviewImage] = useState<string | ArrayBuffer | null>(null);
    const [imageInfo, setImageInfo] = useState<{ resolution: { width: number; height: number }; size: number } | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [getData, setGetData] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [isProfilePhoto, setisProfilePhoto] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [BankData, setBankData] = useState<boolean>();
    const [DocData, setDocData] = useState<boolean>();
    const [showUploadButton, setShowUploadButton] = useState(true);

    const [formState, setFormState] = useState<TaxFormState>({
        userId: null,
        id: NaN,
        panCardNo: '',
        accountNo: NaN,
        confirmBankAccountNumber: NaN,
        ifscCode: '',
        beneficiaryName: '',
        paymentMode: 'creditCard',
        error: null,
    });

    useEffect(() => {
        getCompanyData();
        funt()
        handleCloseModal()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const funt = async () => {
        try {
            const response = await getBankDetails();
            if (response && response.data && response.data.data) {
                setBankData(response.data.data);
            }

            const documentData = await getProfileDoc();
            if (documentData && documentData.data && documentData.data.data) {
                setDocData(documentData.data.data);
            }
        } catch (error) {
            console.error("Error in funt:", error);
        }
    };



    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        let error = "";

        // Validation for PAN and IFSC Code (Alphanumeric)
        if (name === "panCardNo" && !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value)) {
            error = "Please enter a valid PAN card number.";
        }

        // Validation for Account Number Match
        // if (name === "confirmBankAccountNumber" && value !== formState.accountNo) {
        //     error = "Account numbers do not match.";
        // }
        if (
            name === "confirmBankAccountNumber" &&
            (value === null || value === undefined || value.toString() !== formState.accountNo?.toString())
        ) {
            error = "Account numbers do not match.";
        }
        if (name === "ifscCode" && !/^[a-zA-Z0-9]+$/.test(value)) {
            error = "Please enter a valid alphanumeric value for IFSC Code.";
        }
        setFormState((prevState) => ({ ...prevState, [name]: value, error: error }));
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(formState.panCardNo)) {
            setFormState({
                ...formState,
                error: "Please enter a valid PAN card number.",
            });
            return;
        }

        if (!/^[a-zA-Z0-9]+$/.test(formState.ifscCode)) {
            setFormState({
                ...formState,
                error: "Please enter a valid alphanumeric value for IFSC Code.",
            });
            return;
        }


        if (formState.confirmBankAccountNumber !== formState.accountNo) {
            setFormState({
                ...formState,
                error: "Account numbers do not match.",
            });
            return;
        }

        if (formState.error) {
            // Validation failed, do not proceed with the API call
            return;
        }

        // Continue with your existing form submission logic
        const response = await updateBankDetails(formState);
        // Handle the API response as needed
        if (response.status === 200) {
            toast.success(response.data.message);
            // setFormVisible(false)
            setOpenModal(false)
            funt();
        }
    }
    const getCompanyData = async () => {
        if (user !== "SuperAdmin") {
            let userDetails = await getUserId(NaN);
            setUserdata(userDetails);
            let companyDetails = await getCompanyDetails();
            if (companyDetails) setCompany(companyDetails.data.data);
        } else {
            let companyDetails = await getCompanyDetails();
            setCompany(companyDetails.data.data);
        }

    };

    const openProfilePhoto = () => {
        // setOpenDocModal(true)
        setisProfilePhoto(!isProfilePhoto)
    }
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFile(file);

            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result as string);

                const img = new Image();
                img.src = reader.result as string;

                img.onload = () => {
                    const resolution = {
                        width: img.width,
                        height: img.height,
                    };

                    const sizeInBytes = file.size;
                    const sizeInKb = sizeInBytes / 1024;

                    setImageInfo({
                        resolution,
                        size: sizeInKb,
                    });

                    if (resolution.width > resolution.height && resolution.width >= 100 && resolution.width <= 4000 && resolution.height >= 100 && resolution.height <= 2500) {
                        setSelectedFile(file);
                        setPreviewImage(reader.result as string);
                        setError(null); // Clear any previous error
                    } else {
                        // Display error if the image is not horizontal or below resolution threshold
                        setSelectedFile(null);
                        setPreviewImage(null);
                        setError('Please upload an image with a width between 1100 and 1200 and a height between 500 and 600.');
                    }
                };
            };
            reader.readAsDataURL(file);
        }

    }
    const handleUpload = async () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            try {
                setLoading(true);
                const response = await addChequeData(formData);
                if (response.data.status === 200) {
                    setGetData(response.data.data);
                    // setIsOpen(true)
                    setShowUploadButton(false); // Hide upload button after successful response
                }
            } catch (error) {
                console.error('Upload Error:', error);
            }
            finally {
                setLoading(false); // Set loading to false after response is received
            }
        }
    }
    const handleConfirm = async () => {
        const updatedData = { ...getData, userId: null };
        const response = await updateBankDetails(updatedData);
        if (response.data.status === 200) {
            toast.success(response.data.message)
            // setIsOpen(false);
            setOpenChequeModal(false)
            funt();
        }
        else {
            toast.error("Somthing is wrong")
        }
    };

    const handleCloseModal = () => {
        setOpenChequeModal(false);
        setSelectedFile(null); // Reset the uploaded file
        setPreviewImage(null); // Clear preview image
        // setIsOpen(false)
        setShowUploadButton(true)
        setGetData(undefined)
    };

    const handleRedirect = () => {
        console.log("process.env.REACT_APP_Landing_Page_URL ", process.env.REACT_APP_Landing_Page_URL);
        
        window.open(process.env.REACT_APP_Landing_Page_URL, '_blank');
    };

    return (
        <div>
            <Page>
                <div className="ml-[600px]">
                    <button
                        onClick={handleRedirect}
                        className="bg-green-700 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Back to Home Page
                    </button>
                </div>


                <Main close={sidebar}>
                    {user !== 'SuperAdmin' &&
                        <div style={{ marginRight: '400px' }} className="items-center">
                            <div className="flex items-center justify-between w-full">
                                <img
                                    src={company?.companyLogo}
                                    alt="companyLogo"
                                    className="aspect-auto text-center"
                                />
                                {/* <h3 className="ml-auto">lllllllll;;;;</h3> Pushes content to the far right */}
                            </div>

                            {/* Additional content */}
                            <div className="text-left mx-14 my-5">
                                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                                    Employee Name: {userdata?.firstName + ' ' + userdata?.lastName}
                                </span><br />
                                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                                    Email Address: {userdata?.email}
                                </span><br />
                                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                                    Employee Contact: {userdata?.mobile}
                                </span><br />
                            </div>
                        </div>

                    }
                    {user === 'SuperAdmin' &&
                        <div style={{ marginRight: '400px' }} className="items-center">
                            <div className="flex items-center justify-center">
                                <img src={company?.companyLogo} alt="companyLogo" className="aspect-auto text-center" />
                            </div>
                            <span style={{ fontSize: "24px", fontWeight: "bold" }}>{company?.companyName}</span><br />
                            <span style={{ fontSize: "20px", fontWeight: "bold" }}>{company?.companyAddress}</span><br />
                            <div className="text-left mx-14 mb-5">
                                <span style={{ fontSize: "16px", fontWeight: "bold" }}>Email Address : {company?.companyEmail}</span><br />
                                <span style={{ fontSize: "16px", fontWeight: "bold" }}>Contact  : {company?.companyContactNumber}</span><br />
                            </div>
                        </div>
                    }
                    {user !== 'SuperAdmin' &&
                        <div className="mr-6">
                            <div >
                                {/* {!BankData && !OpenModal && (
                                    <button onClick={() => { setOpenModal(true); }}
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-10"
                                    >
                                        <h3>Open Bank Information Form</h3>
                                    </button>
                                )} */}
                            </div>

                            <div>
                                {!isProfilePhoto && !DocData && (
                                    <button onClick={openProfilePhoto}

                                    >
                                        {/* className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" */}

                                        {/* Upload Photo
                                        <h1 className="text-xs">upload photo from user</h1> */}
                                    </button>

                                )}
                            </div>
                            <div >
                                {/* {!BankData && !OpenModal && (
                                    <button onClick={() => { setOpenChequeModal(true); }}
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-10"
                                    >
                                        <h3>Bank Data by cheque</h3>
                                    </button>
                                )} */}
                            </div>
                        </div>
                    }
                    {/* {isProfilePhoto && ( */}

                    <Modal isOpen={isProfilePhoto} onClose={() => {
                        setisProfilePhoto(false);
                    }}>
                        <div className="w-full ml-20 mr-20">
                            <PeopProfPhoto setisProfilePhoto={openProfilePhoto} funt={funt} />
                        </div>
                    </Modal>
                    {/* )} */}



                    {(company?.roleMenusExists === false && user === 'SuperAdmin') &&

                        <Redirect to={{ pathname: '/setting', state: { user: user, sidebar: sidebar, sidebarAccess: sidebarAccess } }} />
                        // <Route path='/setting' component={()=><SettingPage user={user} sidebar={sidebar} />}/>
                    }
                    <Modal isOpen={OpenModal} onClose={() => {
                        setOpenModal(false);
                    }}>
                        {/* {formVisible && ( */}
                        <div className="max-w-full mx-auto h-[650px] overflow-x-scroll  bg-white rounded-md shadow-md">
                            {/* ... (your existing Bank Information Form code) */}
                            <div className="max-w-md mx-auto p-6 bg-white rounded-md shadow-md">
                                <h2 className="text-xl font-bold mb-4">Bank Information Form</h2>

                                <p className="text-red-500 mb-4">
                                    Please be very careful while entering data on this screen. Mistakes will lead to incorrect tax deductions and
                                    filings, and failures in salary transfers/payments.
                                </p>

                                <form onSubmit={handleSubmit}>
                                    {/* PAN Information */}
                                    <div className="mb-4">
                                        <label htmlFor="pan" className="block text-gray-700 font-semibold mb-2">
                                            PAN (Permanent Account Number)
                                        </label>
                                        <input
                                            type="text"
                                            id="panCardNo"
                                            name="panCardNo"
                                            placeholder="Enter PAN"
                                            value={formState.panCardNo}
                                            onChange={handleInputChange}
                                            className="w-full p-2 border rounded focus:outline-none focus:border-blue-500"
                                            minLength={10}
                                            maxLength={10}
                                            required
                                        />
                                    </div>

                                    {/* Bank Account Information */}
                                    <div className="mb-4">
                                        <label htmlFor="bankAccountNumber" className="block text-gray-700 font-semibold mb-2">
                                            Bank Account Number
                                        </label>
                                        <input
                                            type="text"
                                            id="accountNo"
                                            name="accountNo"
                                            placeholder="Your bank account number"
                                            value={formState.accountNo}
                                            onChange={handleInputChange}
                                            className="w-full p-2 border rounded focus:outline-none focus:border-blue-500"
                                            minLength={9}
                                            maxLength={16}
                                            required
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="confirmBankAccountNumber" className="block text-gray-700 font-semibold mb-2">
                                            Confirm Bank Account Number
                                        </label>
                                        <input
                                            type="text"
                                            id="confirmBankAccountNumber"
                                            name="confirmBankAccountNumber"
                                            placeholder="Please enter the bank account number again"
                                            value={formState.confirmBankAccountNumber}
                                            onChange={handleInputChange}
                                            className="w-full p-2 border rounded focus:outline-none focus:border-blue-500"
                                            minLength={9}
                                            maxLength={16}
                                            required
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="bankIFSC" className="block text-gray-700 font-semibold mb-2">
                                            Bank IFSC Code
                                        </label>
                                        <input
                                            type="text"
                                            id="ifscCode"
                                            name="ifscCode"
                                            placeholder="Your bank's IFSC code"
                                            value={formState.ifscCode}
                                            onChange={handleInputChange}
                                            className="w-full p-2 border rounded focus:outline-none focus:border-blue-500"
                                            minLength={11}
                                            maxLength={11}
                                            required
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="beneficiaryName" className="block text-gray-700 font-semibold mb-2">
                                            Beneficiary Name
                                        </label>
                                        <input
                                            type="text"
                                            id="beneficiaryName"
                                            name="beneficiaryName"
                                            placeholder="If the bank account owner is someone other than 'Jitendra', then please enter it here."
                                            value={formState.beneficiaryName}
                                            onChange={handleInputChange}
                                            required
                                            className="w-full p-2 border rounded focus:outline-none focus:border-blue-500"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="paymentMode" className="block text-gray-700 font-semibold mb-2">
                                            Payment Mode
                                        </label>
                                        <select
                                            id="paymentMode"
                                            name="paymentMode"
                                            value={formState.paymentMode}
                                            onChange={handleInputChange}
                                            required
                                            className="w-full p-2 border rounded focus:outline-none focus:border-blue-500"
                                        >
                                            <option value="creditCard">Credit Card</option>
                                            <option value="debitCard">Debit Card</option>
                                            <option value="netBanking">Net Banking</option>
                                        </select>
                                    </div>

                                    {/* Display error message if there is an error */}
                                    {formState.error && <p className="text-red-500 mb-4">{formState.error}</p>}

                                    {/* Continue with more form fields as needed */}

                                    <div className="text-center">
                                        <button
                                            type="submit"
                                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-8"
                                        >
                                            Submit
                                        </button>
                                        <button
                                            type="submit"
                                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                            onClick={() => { setOpenModal(false) }}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* )} */}
                    </Modal >
                    <Modal isOpen={OpenChequeModal} onClose={handleCloseModal}  >
                        <div className="bg-white p-6 rounded shadow-lg w-[800px]">
                            {showUploadButton && (
                                <input type="file" accept="image/*" onChange={handleFileChange} />)}
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                            {previewImage && (
                                <><div className="flex flex-col items-center mb-8">
                                    <div className="flex justify-center items-center">
                                        <img src={previewImage as string} alt="Preview" className="max-w-xs max-h-64 mb-4" />
                                    </div>
                                    {imageInfo && (
                                        <div>
                                            <p>Resolution: {imageInfo.resolution.width} x {imageInfo.resolution.height}</p>
                                            <p>Size: {imageInfo.size.toFixed(2)} KB</p>
                                        </div>
                                    )}
                                </div>

                                </>
                            )}
                            {showUploadButton && (
                                <button onClick={handleUpload} disabled={!selectedFile} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 inline-block">Upload Cheque</button>
                            )}
                            <br />
                            <br />
                            {/* {loading && (
                                <div className="fixed inset-0 flex items-center justify-center z-50">
                                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
                                </div>
                            )} */}
                            {loading && (
                                <CustomLoader size={150} colors={"green"} />
                            )}
                            <div>
                                {getData && (
                                    <ConfirmButton onConfirm={handleConfirm} message={`Account Number: ${getData.accountNo}, IFSC Code: ${getData.ifscCode}, Beneficiary Name: ${getData.beneficiaryName}`}>
                                        <button>Submit</button>
                                    </ConfirmButton>
                                )}
                            </div>
                        </div>
                    </Modal>



                </Main>
                <div>
                </div>
            </Page>
        </div>
    )
}

