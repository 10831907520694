import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { LeaveAttendanceApi } from "../../../services/LeaveAttendance";
import { toast } from "react-toastify";



interface initialValues {
    id: number;
    leaveType: string;
    defaultValue: number;
    // monthlyIncrement: number;
    status: boolean
    leaveMaxValue: number;
}

interface props { addLeave: () => Promise<void>; setOpenLeave: Dispatch<SetStateAction<boolean>>; }

const AddLeaveType = (props: props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [tasks, getSalData] = useState<initialValues[]>([]);
    const [salData, setSalData] = useState<initialValues>({ id: NaN, leaveType: '', defaultValue: 0, leaveMaxValue: 0, status: true });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isEditing, setIsEditing] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { addNewLeave, updateLeave, getAddLeave, addOrRemoveLeave } = LeaveAttendanceApi()
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        const response = await getAddLeave();

        getSalData(response.data.data);
    };

    const handleAddTask = async () => {
        if (salData.leaveType.trim() !== '') {

            const response = await addNewLeave(salData);

            if (response.data.status === 200) {
                toast.success(response.data.message);
                getData();
                setSalData({ id: NaN, leaveType: '', defaultValue: 0, leaveMaxValue: 0, status: true });
                props.addLeave();
                props.setOpenLeave(false);
            }
            else if (response.data.status === 500) {
                toast.error(response.data.message);
            }
        }
    };
    const handleSelectAll = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);

        // Update the state of all checkboxes in the rows
        const updatedLeave = tasks.map(leave => ({ ...leave, status: newSelectAll }));
        getSalData(updatedLeave);
    };
    const toggleCheckbox = (index: number) => {
        const updatedLeave = [...tasks];
        updatedLeave[index] = { ...updatedLeave[index], status: !updatedLeave[index]?.status };
        getSalData(updatedLeave);

        const allSelected = updatedLeave.every(leave => leave.status);
        setSelectAll(allSelected);
    };

    const handleSubmit = async () => {
        try {
            const response = await addOrRemoveLeave(tasks);
            if (response.data.status === 200) {
                toast.success(response.data.message);
                getData();
                props.addLeave();
                props.setOpenLeave(false);
            }
        } catch (error) {
            // Handle any errors
            console.error('Error:', error);

        };
    }    // const handleDeleteTask = async (taskId: number) => {

    //     const response = await deleteLeave(taskId);

    //     if (response.status === 200) {
    //         toast.success(response.data.message);
    //         const updatedTasks = tasks.filter((task) => task.id !== taskId);
    //         getSalData(updatedTasks);
    //     }
    // };

    // const handleEditClick = (task: initialValues) => {
    //     setIsEditing(true);
    //     setSalData(task);
    // };

    // const handleSaveClick = async () => {
    //     setIsEditing(false);
    //     setSalData({ id: NaN, leaveType: '', defaultValue: 0, monthlyIncrement: 0, leaveMaxValue: 0 });

    // if (isNaN(salData.defaultValue) || salData.defaultValue === null ) {
    //     // Show error message or handle the validation error for defaultValue
    //     toast.error("Default value should be a valid number");
    //     return; 
    // }

    // if (isNaN(salData.leaveMaxValue) || salData.leaveMaxValue === null) {
    //     // Show error message or handle the validation error for leaveMaxValue
    //     toast.error("Max value should be a valid number");
    //     return; // Prevent further execution of the function
    // }
    //     const response = await updateLeave(salData);

    //     if (response.status === 200) {
    //         toast.success(response.data.message);
    //         getData();
    //         setSalData({ id: NaN, leaveType: '', defaultValue: 0, monthlyIncrement: 0, leaveMaxValue: 0 });
    //     }
    // };

    return (
        <div>
            <div className='relative shadow-md rounded-lg '>
                <div className="container mx-auto p-4 bg-white ">
                    <h1 className="text-md font-bold text-xl">Types of Leaves </h1>
                    <div className='relative overflow-y-auto shadow-md sm:rounded-lg w-7/6 p-4 m-4 flex'>
                        <table className='table-auto border-collapse'>
                            <tbody>
                                {!isEditing && (<tr>
                                    <td>
                                        <label><b>Leave Type</b></label><br />
                                        <input
                                            placeholder="e.g Casual Leave"
                                            value={salData.leaveType}
                                            onChange={(e) => setSalData({ ...salData, leaveType: e.target.value })}
                                            className="px-4 py-2.5 mr-2 mb-2 h-9 m-1 rounded-lg"
                                        />
                                    </td>
                                    <td>
                                        <label><b>Default Value</b></label><br />
                                        <input
                                            placeholder="Enter Default Value"
                                            type="number"
                                            pattern="[0-9]*"
                                            inputMode="numeric"
                                            value={salData.defaultValue === 0 ? "" : salData.defaultValue}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const isValid = /^\d*\.?\d*$/.test(value); // Validate input as numeric
                                                if (isValid) {
                                                    setSalData({ ...salData, defaultValue: parseFloat(e.target.value) });
                                                }
                                            }}
                                            className="px-4 py-2.5 mr-2 mb-2 h-9 m-1 rounded-lg"
                                            style={{ appearance: 'textfield', MozAppearance: 'textfield', WebkitAppearance: 'textfield', paddingRight: '20px' }}
                                        />
                                    </td>
                                    {/* <td>
                                        <label><b>Monthly Increment</b></label><br />
                                        <input
                                            placeholder="Monthly Increment"
                                            type='number'
                                            className="px-4 py-2.5 mr-2 mb-2 h-9 m-1 rounded-lg"
                                            value={salData.monthlyIncrement}
                                            onChange={(e) => setSalData({ ...salData, monthlyIncrement: parseFloat(e.target.value) })}
                                        />
                                    </td> */}
                                    <td>
                                        <label><b>Max Value</b></label><br />
                                        <input
                                            placeholder="Enter Maximum Value"
                                            type="number"
                                            pattern="[0-9]*"
                                            inputMode="numeric"
                                            value={salData.leaveMaxValue === 0 ? "" : salData.leaveMaxValue}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const isValid = /^\d*\.?\d*$/.test(value); // Validate input as numeric
                                                if (isValid) {
                                                    setSalData({ ...salData, leaveMaxValue: parseFloat(e.target.value) });
                                                }
                                            }}
                                            className="px-4 py-2.5 mr-2 mb-2 h-9 m-1 rounded-lg"
                                            style={{ appearance: 'textfield', MozAppearance: 'textfield', WebkitAppearance: 'textfield', paddingRight: '20px' }}
                                        />
                                    </td>
                                    <td><br />
                                        <button className=' bg-blue-500 hover:bg-blue-800 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 m-1' onClick={handleAddTask}>Add</button>
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                    <div className='class="relative overflow-x-auto overflow-y-scroll max-h-[400px] shadow-md sm:rounded-lg w-full'>
                        <table border={1} className=" w-full">
                            <thead className="text-black uppercase sticky top-0 bg-gray-50 w-full " >
                                <tr >
                                    <th className="px-6 py-3">
                                        <input checked={selectAll} type="checkbox" onChange={() => handleSelectAll()} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    </th>
                                    <th className="px-6 py-3">Type</th>
                                    <th className="px-6 py-3">Default Leave</th>
                                    {/* <th className="px-6 py-3">Monthly Increment</th> */}
                                    <th className="px-6 py-3">Max Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tasks.map((task, index) => (
                                    <tr key={task.id} className="bg-white border-b  font-medium dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <input checked={task?.status} type="checkbox" onChange={() => toggleCheckbox(index)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <td className=' text-center text-black p-2'>{isEditing && salData.id === task.id ? (
                                            <input
                                                value={salData.leaveType}
                                                onChange={(e) => setSalData({ ...salData, leaveType: e.target.value })}
                                            />
                                        ) : (
                                            task.leaveType
                                        )}</td>
                                        <td className=' text-center text-black p-2'>{isEditing && salData.id === task.id ? (
                                            <input
                                                type='number'
                                                step="0.01"
                                                value={salData.defaultValue}
                                                onChange={(e) => setSalData({ ...salData, defaultValue: parseFloat(e.target.value) })}
                                            />
                                        ) : (
                                            task.defaultValue
                                        )}</td>
                                        {/* <td className=' text-center text-black p-2'>{isEditing && salData.id === task.id ? (
                                            <input
                                                value={salData.monthlyIncrement}
                                                onChange={(e) => setSalData({ ...salData, monthlyIncrement: parseFloat(e.target.value) })}
                                            />
                                        ) : (
                                            task.monthlyIncrement
                                        )}</td> */}
                                        <td className=' text-center text-black p-2'>{isEditing && salData.id === task.id ? (
                                            <input
                                                value={salData.leaveMaxValue}
                                                onChange={(e) => setSalData({ ...salData, leaveMaxValue: parseFloat(e.target.value) })}
                                            />
                                        ) : (
                                            task.leaveMaxValue
                                        )}</td>
                                        <td className=' text-center text-black p-2'>
                                            {/* <button className=' bg-green-500 hover:bg-green-800 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2' onClick={handleAddTask} >Submit</button> */}

                                            {/* {isEditing && salData.id === task.id ? (
                                                <button className=' bg-blue-500 hover:bg-blue-800 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2' onClick={handleSaveClick}>Save</button>
                                            ) : (
                                                <>
                                                    <button className=' bg-green-500 hover:bg-green-800 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2' onClick={() => handleEditClick(task)} >Edit</button>
                                                    

                                                </>
                                            )} */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex justify-center bg-white mt-4 rounded-md">
                        <button type="submit" className="text-white bg-blue-600  hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={handleSubmit} >Submit</button>
                        <button onClick={() => props.setOpenLeave(false)} type="button" className="focus:outline-none text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AddLeaveType;
