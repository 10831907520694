import React, { useEffect, useState } from "react";

import { Fullpage, Page } from '../../../components/Sidebar';
import moment from "moment";
import { ReportsApi } from "../../../services/reports";
import '../Reports.css'

interface Filter {
    viewValue: string,
    value: string
}

interface SearchCriteria {
    type: string;
    fromDate: string;
    toDate: string;
}

export default function AttendanceReportPage({ sidebar }: any) {
    const { getAttendanceAndLeaveData, downloadExcelReport } = ReportsApi();
    const typeFilter: Filter[] = [{ viewValue: 'Summary', value: 'summary' },
    { viewValue: 'Date wise Attendance', value: 'dateWiseAttendance' }];
    const currentYear = moment().year();
    const currentMonth = moment().month();
    const currentMonthDay = moment().daysInMonth().toString();
    const initalValues: SearchCriteria = {
        type: 'summary',
        fromDate: moment([currentYear, currentMonth]).format('YYYY-MM-DD'),
        toDate: moment([currentYear, currentMonth, currentMonthDay]).format('YYYY-MM-DD')
    };
    const [leaveAttendanceData, setLeaveAttendanceData] = useState([]);
    const [filterData, setFilterData] = useState<SearchCriteria>(initalValues);

    useEffect(() => {
        search();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        setFilterData({ ...filterData, [name]: value });
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFilterData({ ...filterData, [name]: value });
    };

    const search = async () => {
        const response = await getAttendanceAndLeaveData(filterData);
        setLeaveAttendanceData(response.data.data);
    }

    const downloadExcel = async () => {
        const response = await downloadExcelReport(leaveAttendanceData, 'leaveAttendanceReport');
        const blob = new Blob([response?.data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'leaveAttendanceReport.xlsx';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }

    return (
        <Page>
            <Fullpage close={sidebar}>
                <div className="w-4/5 min-h-svh mt-5 mb-5 bg-white rounded-xl shadow-lg">
                    <div className="w-full text-center p-6 m-6">
                        <p className="text-center font-bold text-2xl">ATTENDANCE & LEAVE REPORT</p>
                    </div>

                    <div className=" text-left shadow-md sm:rounded-lg p-3 m-5">
                        <div className="flex items-center justify-center space-x-10">
                            <div className="grid col-span-1 text-xl font-semibold text-slate-800 dark:text-black">
                                <label className="text-left font-bold ml-1" htmlFor="type">Type:</label>
                                <select className="p-2 my-2 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" id="type" name="type" value={filterData.type} onChange={handleSelectChange}>
                                    <option value="NULL">Select Type</option>
                                    {typeFilter.map((item: any) => (
                                        <option key={item.value} value={item.value}>
                                            {item.viewValue}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="grid col-span-1 text-xl font-semibold text-slate-800 dark:text-black">
                                <label className="text-left font-bold ml-1" htmlFor="fromDate">From Date:</label>
                                <input className="p-2 my-2 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" type="date" id="fromDate" name="fromDate" value={filterData.fromDate} onChange={handleInputChange} />

                            </div>
                            <div className="grid col-span-1 text-xl font-semibold text-slate-800 dark:text-black">
                                <label className="text-left font-bold ml-1" htmlFor="toDate">To Date:</label>
                                <input className="p-2 my-2 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" type="date" id="toDate" name="toDate" value={filterData.toDate} onChange={handleInputChange} />

                            </div>
                            <div className="grid col-span-1 text-xl font-medium text-slate-800 dark:text-white">
                                <button className="p-2 mt-8 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={search} >Search</button>
                            </div>
                        </div>
                    </div>

                    {/* <div className="items-right shadow-md sm:rounded-lg w-full  p-3 m-2">
                    <div className="justify-center text-xl font-medium text-white dark:text-white w-full" >
                        <label className="text-left p-9 m-6" htmlFor="type">Type:</label>
                        <label className="text-left p-9 m-6" htmlFor="fromDate">From Date:</label>
                        <label className="text-left p-9 m-6" htmlFor="toDate">To Date:</label>
                        <label className="text-left p-6 m-6" ></label>
                    </div>
                    <div className="flex items-center justify-center text-xl font-medium text-white dark:text-white">
                        <select className="p-3 m-6 bg-gray-50 border border-gray-300 text-gray-800 text-center  text-sm rounded-lg" id="type" name="type" value={filterData.type} onChange={handleSelectChange}>
                            <option value="">Select Type</option>
                            {typeFilter.map((item: any) => (
                                <option key={item.value} value={item.value}>
                                    {item.viewValue}
                                </option>
                            ))}
                        </select>
                        <input className="p-3 m-6 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" type="date" id="fromDate" name="fromDate" value={filterData.fromDate} onChange={handleInputChange} />
                        <input className="p-3 m-6 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" type="date" id="toDate" name="toDate" value={filterData.toDate} onChange={handleInputChange} />
                        <button className="p-3 m-6 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={search} >Search</button>
                    </div>
                </div> */}
                    <div className="text-right px-5">
                        <button className="p-3 m-6 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={downloadExcel} >Download Excel</button>
                    </div>

                    <div className="mx-5 overflow-auto rounded-md shadow-lg">
                        <table className=" w-full rounded-md shadow-lg bg-gray-200 overflow-hidden" >
                            <thead  >
                                <tr className="bg-green-800 py-3">
                                    {Object.keys(leaveAttendanceData[0] || {}).map((headItem) => (
                                        <th className="text-center min-w-32 px-4 sm:px-6 py-3 bg-green-800  text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-100 uppercase tracking-wider">{headItem}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody >
                                {leaveAttendanceData.map((item) => (
                                    <tr>
                                        {Object.keys(item).map((key) => (

                                            <td>{item[key]}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>


                </div>
            </Fullpage>
            
        </Page>
    );
}
